import React from "react";
import { Box } from "@mui/material";



export default function MainSummary(props) {
    // Use a regular expression to find sentence-ending punctuation and add a line break
    const stringWithLineBreaks = props.text.replace(/([.!?])\s/g, "$1\n\n");
  
    const [more, setMore] = React.useState(false);
  
    React.useEffect(() => {
      // stringWithLineBreaks.split("\n").length > 2 ? setMore(true) :
      setMore(false);
    }, [props.text]);
  
    return (
      <>
        {stringWithLineBreaks.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            <span
              style={{
                display: index == 0 || more ? "inline" : "none",
  
                // fontSize: index == 0 ? "1.4rem" : null
              }}
            >
              {line}
              {index == 0 && !more ? (
                <span
                  style={{
                    display:
                      stringWithLineBreaks.split("\n").length > 1
                        ? "inline"
                        : "none",
                  }}
                  onClick={() => setMore(!more)}
                >
                  ..{" "}
                  <span
                    style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}
                  >
                    more
                  </span>
                </span>
              ) : (
                " "
              )}
              <br />
            </span>
          </React.Fragment>
        ))}
  
        {more ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <span onClick={() => setMore(!more)}>
              <br />
              <span style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}>
                less
              </span>
            </span>
          </Box>
        ) : null}
      </>
    );
  }