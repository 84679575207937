import { Box, Grid, Typography, Slider, Card, createTheme, ThemeProvider, ButtonBase, AppBar, Divider, Skeleton, Tabs, Tab, CardContent, AvatarGroup, Avatar } from '@mui/material';
import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { AutoAwesome, IndeterminateCheckBoxSharp, Person, PersonOutline, PersonPinCircle, PersonTwoTone } from '@mui/icons-material';
import axios from 'axios';
import TikTokComment from './TikTokComment';
import { siftsyTheme } from './siftsytheme';
import { useParams } from 'react-router-dom';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const darkMode = false;
const theme = createTheme(siftsyTheme);

const StyledButton = muiStyled(ButtonBase)(({ theme }) => ({
  borderRadius:30,
  background: `${theme.palette.primary.main}`,
  fontFamily: [
    "Gilroy",
    'sans-serif',
  ].join(','),
  fontSize: '1rem',
  fontWeight : 900,
  // letterSpacing:".5px",
  color: theme.palette.secondary.main,
  padding: "15px 0px",
  width: "100%",
  boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.4)',
}));

const StyledCallout = muiStyled(Card)(({ theme }) => ({
    borderRadius:30,
    background: `${theme.palette.primary.main}`,
    fontFamily: [
      "Gilroy",
      'sans-serif',
    ].join(','),
    fontSize: '1rem',
    fontWeight : 900,
    color: theme.palette.secondary.main,
    padding: "15px 0px",
    width: "fit-content",
    elevation: 0,
}));

const sifterOptions = [
    {label : "Supportive", emoji: "😊"}, 
    {label : "Insightful", emoji: "🤓"}, 
    {label : "Critical", emoji: "😪"}
];

export default function SiftPreview(props) {

  const { siftData } = props;

  const videoId = siftData.id;
  const creatorName = siftData.creatorName;
  const creatorHandle = siftData.creatorHandle;
  const commentData = JSON.parse(siftData.commentData);
  const sift = JSON.parse(siftData.sift);
  const totalComments = siftData.totalComments;

    const [filter, setFilter] = React.useState(0);
    const [posterSrc, setPosterSrc] = React.useState("");
    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    window.addEventListener('resize', () => {
        setPageWidth(window.innerWidth);
    });


    const handleSelectedFilter = (index) => {
      setSelectedGroup(null);
      setFilter(index);

    }

    React.useEffect(() => {

      async function fetchPosterSrc() {

        const poster = await fetchPoster(videoId, creatorName);

        setPosterSrc(poster.url ? poster.url : "");

      }

      fetchPosterSrc();

    }, []);

        
    const highlightSection = React.useRef(null);
    const commentSection = React.useRef(null);
    
    return (
        <div id="popup" style={{
            margin: "0 auto", width: pageWidth > 450 ? 450 : pageWidth, height: "auto",
            overflowX: "hidden"

          }}>
        <ThemeProvider theme={theme}>


            <AppBar position="static" elevation={0} sx={{background: "transparent"}}>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-stretch", alignItems: "center", m:1 }}>
              
                <img src="/siftsy_logo.png" alt="logo" style={{width:80, height:"auto", margin:"5px"}}/>


                <Box sx={{flexGrow:1}}/> 

                </Box>


            </AppBar>



            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", m: 2 }}>

<Card elevation={0} sx={{ borderRadius: "12px", background: "#EEEAFF", p:2, width:"100%" }}>

    <Typography align="center" variant="body1" color="primary">

        Hi <b>{creatorName}!</b> 
            <br/>
        This video has been sifted...

    </Typography>

</Card>

</Box>

          
          
                
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start"}}>

          {siftData ?
          <Box sx={{m:1}}>
            <Typography variant="subtitle1" align="center">{siftData.createdAt ?
                                    `${siftData.totalComments ? siftData.totalComments + " comments sifted " : "Sifted "}${timeAgo(siftData.createdAt)}` : ""}
                                </Typography>
                                </Box>

              : null}
            <ButtonBase onClick={() => window.open(`https://www.tiktok.com/${creatorHandle}/video/${videoId}`, "_blank")}>

            <div style={{position:"relative", width: 250, height: 140, borderRadius:"12px", overflow:"hidden"}}>

            {posterSrc != "" ? <img src={posterSrc} alt="poster" style={{position:"absolute", left:0, top:0, width:250, height:140}}/> : <Skeleton animation="wave" variant="rectangular" width={250} height={140} sx={{borderRadius:"12px"}} />
                    }

              <div style={{position:"absolute", top:0, left: 0, width: 250, height:140, overflow: "hidden", borderRadius:"12px", background:"transparent", backdropFilter: "blur(10px)", 
              WebkitBackdropFilter: "blur(10px)"}}/>
          

                <div style={{position:"absolute", top:0, left: 250/2 - (document.getElementById("thumbnail") ? document.getElementById("thumbnail").clientWidth/2 : 40), width: "fit-content", height: 140, overflow: "hidden", borderRadius:"12px",
              }}>
                {posterSrc != "" ? <img id="thumbnail" src={posterSrc} alt="poster" style={{width:"auto", height:140}}/> : null
                //  <Skeleton animation="wave" variant="rectangular" width={80} height={150} /> 
                    }
                    </div> 

                </div>
                </ButtonBase>
              
              
            </Box>
        
              
            {siftData ? 

              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", mt:2}}>

            {/* <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", p:2}}>
            


             <SifterPicker filter={filter} setFilter={handleSelectedFilter} />

             </Box> */}


              <Typography variant="subtitle2" sx={{color:"#AAAAAA"}} align="center">
                
                {`${['a', 'e', 'i', 'o', 'u'].includes(Object.keys(sift)[filter].charAt(0).toLowerCase()) ? "An" : "A"} ${Object.keys(sift)[filter].toLowerCase()} view of your comments`}
                
                
                
                
                </Typography>
              </Box>

             : null}
   

              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "top", pr:2, pl:2 }}>

        {sift[Object.keys(sift)[filter]] && sift[Object.keys(sift)[filter]].summary ? 
                                <Box sx={{p:1}}>

            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
         <Typography variant="h6" align="left">Summary </Typography>
         <AutoAwesome color="primary" sx={{mt:-1.5, height:12, width:12}}/> 
          </Box>
     
        

                <Card elevation={0} sx={{ borderRadius: "12px", background:"#EEEAFF", p:3, mt:1}}>
                  <Typography variant="body1" align="left">

                    <MainSummary creatorHandle={creatorHandle} videoId={videoId}
                    
                    text={Object.keys(sift).length > 0 ? sift[Object.keys(sift)[filter]].summary : ""} />

                  </Typography>
                </Card>

                </Box>
               : null}


{sift[Object.keys(sift)[filter]] && sift[Object.keys(sift)[filter]].highlights && Array.isArray(sift[Object.keys(sift)[filter]].highlights) ? 
                   
                   <Box sx={{p:1}} ref={highlightSection}>

<Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
         <Typography variant="h6" align="left">Highlights </Typography>
         <AutoAwesome color="primary" sx={{mt:-1.5, height:12, width:12}}/> 
          </Box>
     
     
         {Object.keys(sift).length > 0 ? 

         <Grid container spacing={1} alignItems="left" sx={{mt:1}}>


      {sift[Object.keys(sift)[filter]].highlights.map(highlight => (      
                  <HighlightCard highlight={highlight} highlightSection={highlightSection} />
                      ))}
      

                </Grid>

                :null}


                </Box>


                : null}

          <Box sx={{m:1}}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", mt:1 }} ref={commentSection}>

                                
          <Grid container spacing={1} alignItems="left" sx={{mb:1}}>
          {sift[Object.keys(sift)[filter]].groupings && (typeof sift[Object.keys(sift)[filter]].groupings === "object" && !Array.isArray
          
          (sift[Object.keys(sift)[filter]].groupings)) ? Object.keys(sift[Object.keys(sift)[filter]].groupings).slice(0,3).map((phrase, index) => 

          <CommentGrouping groupPhrase={phrase} commentGroup={sift[Object.keys(sift)[filter]].groupings[phrase]} commentSection={commentSection} commentData={commentData} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}
          />

          ) : null}
          </Grid>

          </Box>
          </Box>

        {/* {sift[Object.keys(sift)[filter]] && sift[Object.keys(sift)[filter]].comments && Array.isArray(sift[Object.keys(sift)[filter]].comments) ? 
                       
                       <Box sx={{m:1}}>
                
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
         <Typography variant="h6" align="left">Selected Comments </Typography>
         <AutoAwesome color="primary" sx={{mt:-1.5, height:12, width:12}}/> 
          </Box>
     

                       
                       <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", mt:1 }} ref={commentSection}>

                       
                       <Grid container spacing={1} alignItems="left" sx={{mb:1}}>
                       {sift[Object.keys(sift)[filter]].groupings && (typeof sift[Object.keys(sift)[filter]].groupings === "object" && !Array.isArray(sift[Object.keys(sift)[filter]].groupings)) ? Object.keys(sift[Object.keys(sift)[filter]].groupings).map((phrase, index) => 

                        <CommentGrouping groupPhrase={phrase} commentGroup={sift[Object.keys(sift)[filter]].groupings[phrase]} commentSection={commentSection} commentData={commentData} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}
                        />

                        ) : null}
                       </Grid>
                       
    
                       
                        {
                        
      
                        (selectedGroup == null && sift[Object.keys(sift)[filter]].comments && Array.isArray(sift[Object.keys(sift)[filter]].comments) ? sift[Object.keys(sift)[filter]].comments : 
                        
                        (sift[Object.keys(sift)[filter]].groupings && (typeof sift[Object.keys(sift)[filter]].groupings === "object" && !Array.isArray(sift[Object.keys(sift)[filter]].groupings)) ? sift[Object.keys(sift)[filter]].groupings[selectedGroup] : [])).map((highlighted) => 

                        
                        <Box sx={{mt:2}}>
                            <TikTokComment
                            comment={commentData.find(comment => comment.commentId === highlighted)}
                            />

                        <Divider variant="middle"/>


                        </Box>
                        )}

                        </Box> 
                        
                        </Box>
                        
                        : null} */}

          
          <Box sx={{m:2}}>
          
                    {siftData ?

                            <Box sx={{ m: 1 }}>
                                <StyledButton onClick={() => window.open(`${process.env.REACT_APP_SIFTSY_URL}/${creatorHandle}/${videoId}`, "_blank")}>
                                    View Full Sift
                                </StyledButton>
                            </Box>
                            : null}
 

            </Box>

        

              </Box>

  


        
          </ThemeProvider>
          </div>
    )
}


function MainSummary(props) {
    // Use a regular expression to find sentence-ending punctuation and add a line break
    const stringWithLineBreaks = props.text.replace(/([.!?])\s/g, "$1\n\n");
  
    const [more, setMore] = React.useState(false);

    const { creatorHandle, videoId } = props;
  
    React.useEffect(() => {
      setMore(false);
    }, [props.text])
  
    return(
      <>
      {stringWithLineBreaks.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span style={{ display: index == 0 || more ? "inline" : "none",
          
          // fontSize: index == 0 ? "1.4rem" : null 
          
          }}>{line}
         {index == 0 && !more ? <span onClick={() => window.open(`${process.env.REACT_APP_SIFTSY_URL}/${creatorHandle}/${videoId}`, "_blank")}
         >.. <span style={{fontWeight:900, color:"#111", cursor:"pointer"}}>more</span></span> : " "}
        <br />
        </span>
      </React.Fragment>
    ))}
    
    {more ? 
    
    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
      <Box sx={{flexGrow:1}}/>
    <span onClick={() => setMore(!more)}><br/><span style={{fontWeight:900, color:"#111",  cursor:"pointer"}}>less</span></span> 
    </Box> : null}
   
    </>
    
    )}

    const SifterPicker = (props) => {
        const {filter, setFilter} = props;
            
        return (
          <div style={{ width: '90%', margin: "0 auto", cursor:"pointer" }}>
      
            <Grid container spacing={2} alignItems="center">
              {sifterOptions.map((sifter, index) => (
                <Grid item xs={4}>
      
                  <Typography color="primary" align="center" onClick={() => setFilter(index)} variant={"subtitle2"}
                    sx={{borderRadius:30, background : filter == index ? "#4828C5" : "#ffffff", color: filter == index ? "#fff" : "#4828C5", padding:"3px 0px", border:"2px solid #4828C5",
                      fontWeight: filter == index ? 900 : 700 }} >
                      
                    {sifter.label}
      
                  </Typography>
      
                </Grid>
              ))}
            </Grid>
      
          </div>
        );
      };
      
      const CustomSlider = muiStyled(Slider)(({ theme }) => ({

        '& .MuiSlider-thumb': {
          height: 34,
          width: 34,
          backgroundColor: 'transparent',
          // border: `3px solid ${theme.palette.primary.main}`,
          // marginTop: -8,
          // marginLeft: -12,
          // '&:focus, &:hover, &$active': {
          //   boxShadow: 'inherit',
          // },
        },
      
        '& .MuiSlider-valueLabel': {
          left: 'calc(-50% + 6px)',
          background:"transparent",
        },
      
        // '& .MuiSlider-track': {
        //   height: 5,
        //   borderRadius: 5,
        // },
      
        '& .MuiSlider-rail': {
          height: 25,
          borderRadius: 30,
          opacity: 1,
          background: 'linear-gradient(to right, #B7FF70, #FFEC3E, #FF7A7A)',
        },
      }));


      function timeAgo(isoString) {
        const date = new Date(isoString).getTime();
        const now = new Date().getTime();
        const timeDifference = now - date;
      
        if (timeDifference < 60000) { // Less than a minute
        //   const seconds = Math.floor(timeDifference / 1000);
        //   return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
          return "just now";
        } else if (timeDifference < 3600000) { // Less than an hour
          const minutes = Math.floor(timeDifference / 60000);
          return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else if (timeDifference < 86400000) { // Less than a day
          const hours = Math.floor(timeDifference / 3600000);
          return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        } else {
          const days = Math.floor(timeDifference / 86400000);
          return `${days} day${days === 1 ? '' : 's'} ago`;
        }
      }
      

      const CardContainer = muiStyled('div')({
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
      });
      

      
    const HighlightCard = ({ highlight, highlightSection }) => {
      const ref = React.useRef(null);
      const [cardWidth, setCardWidth] = React.useState(0);
      const [width, setWidth] = React.useState(0);

      React.useEffect(() => {

        setWidth(highlightSection.current.getBoundingClientRect().width);

        if(ref.current)
        {
          setCardWidth(ref.current.getBoundingClientRect().width);
        }

      }, [ref]);
      
      console.log("cardWidth", cardWidth);
      console.log("width", width);
      console.log("cardWidth * 12/width", Math.ceil(cardWidth * 12/width));

      return ( 
        <Grid item xs={Math.ceil(cardWidth * 12/width)+.4}>

          <Card ref={ref} elevation={0} sx={{ borderRadius: "12px", background: "#EEEAFF", p:1 }}>
            <Typography  align="center" variant="body1" sx={{ fontSize: ".8rem" }}>

              {highlight.trim()}

            </Typography>
          </Card>

                </Grid> 
      );

    };


    const CommentGrouping = ({ groupPhrase, commentGroup, commentSection, commentData, selectedGroup, setSelectedGroup }) => {
      const ref = React.useRef(null);
      const [cardWidth, setCardWidth] = React.useState(0);
      const [width, setWidth] = React.useState(0);

      React.useEffect(() => {

        setWidth(commentSection.current.getBoundingClientRect().width);

        if(ref.current)
        {
          setCardWidth(ref.current.getBoundingClientRect().width);
        }

      }, [ref]);
      
      console.log("cardWidth", cardWidth);
      console.log("width", width);
      console.log("cardWidth * 12/width", Math.ceil(cardWidth * 12/width));

      let selected = selectedGroup === groupPhrase;

      let maxAvatars = 3;

  
      return ( 
        <Grid item xs={Math.ceil(cardWidth * 12/width)+1} >

          <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>

                <ButtonBase onClick={() => {if(selected){setSelectedGroup(null)}else{setSelectedGroup(groupPhrase)}}} sx={{borderRadius:30}}>
                 <Card elevation={0} sx={{borderRadius:30,  background : selected ? "#4828C5" : "#ffffff", color: selected ? "#fff" : "#4828C5", p:.5, border:"2px solid #eeeeee"}} ref={ref}>
                  <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", p:.5}}>

                  <AvatarGroup max={maxAvatars} total={commentGroup.length > maxAvatars ? maxAvatars : commentGroup.length} sx={{
                              '& .MuiAvatar-root': {width: 25, height: 25, fontSize: 10, background:"#F3F2FF", color:"#4828C5" },
                            }}
                          >
                    {commentGroup.slice(0, commentGroup.length > maxAvatars ? maxAvatars : commentGroup.length).map((commentId) => 
                      <DynamicAvatar comment={commentData.find(comment => comment.commentId === commentId)} />
                    
                    )}
                  </AvatarGroup>

                  {commentGroup.length > 3 ? 
                  <Avatar sx={ {marginLeft:"-5px", zIndex:1, width: 25, height: 25, fontSize: 10, background:"#F3F2FF", color:"#4828C5" }}>
                   <b> + {commentGroup.length - maxAvatars} </b>
                  </Avatar>
                  : null}

                   <Typography align="center" variant="body1" sx={{ml:1, color: selected ? "#fff" : "#4828C5",
                    fontSize:".8rem", fontStyle:"italic"}}>

                 "{groupPhrase.trim()}"

                 </Typography>
                 </Box>
                 </Card>
                 </ButtonBase>

                 </Box>
                </Grid> 
      );

    };


function DynamicAvatar(props) {

  const { comment } = props;

  const [avatarSrc, setAvatarSrc] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);

  console.log("comment", comment);

  React.useEffect(() => {

    async function fetchAvatarSrc() {

      const avatar = await fetchAvatar(comment.userlink.replace("/", "").toString());
      if(avatar){
      setAvatarSrc(avatar.url ? avatar.url : "");
      }
      setLoaded(true);

    }

    fetchAvatarSrc();

  }, []);


  return (
    loaded ?
    <Avatar sx={{width:25, height:25, zIndex:10}} alt={comment.username} src={avatarSrc} /> :
    <Avatar>
    <Skeleton variant="circular" width={25} sx={{zIndex:10}} height={25} animation="wave"/>
    </Avatar>
  );

}

  async function fetchAvatar(username){
    let req = {
        videoId: "none",
        username: username,
        imgType:"avatar"};

    return await fetch(`${ENDPOINT}/fetchimg`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
    .then((data) => {
        
          console.log("data", data);
  
          if (data && data.data) {
  
            const response = {
              url: data.data
            };
  
            return response;
}}).catch((err) => console.log(err));
  }


  async function fetchPoster(videoId, username){
    let req = {
        videoId: videoId,
        username: username,
        imgType:"poster"};

    return await fetch(`${ENDPOINT}/fetchimg`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
    .then((data) => {
        
          console.log("data", data);
  
          if (data && data.data) {
  
            const response = {
              url: data.data
            };
  
            return response;
}}).catch((err) => console.log(err));
  }