import { FolderOutlined, GroupOutlined, VideoFileOutlined } from "@mui/icons-material";
import DOMPurify from 'dompurify';

const userLanguage = navigator.language || navigator.userLanguage || 'en-US';
console.log('The language is: ' + userLanguage);

export function estimateViews(likes, comments, shares, saves, platform){
 
  let view1 = (saves ? saves : 0)+(shares ? shares : 0) * 10000;
  let view2 =  (comments ? comments : 0) * 1000;
  let view3 = (likes ? likes : 0) * 100;
  //get average of the three
  let views = Math.round((view1 + view2 + view3) / 3);
  return  platform == "linkedin" ? views : 0;
}

export function formatNumber(number) {
  if (isNaN(number)) {
      return "Invalid number";
  }
  if (number >= 1e9) {
      return (number / 1e9).toFixed(0) + "B";
  } else if (number >= 1e6) {
      if ((number / 1e6) >= 10) {
          return (number / 1e6).toFixed(0) + "M";
      } else {
          return (number / 1e6).toFixed(1) + "M";
      }
  } else if (number >= 1e3) {
      if ((number / 1e3) >= 10) {
          return (number / 1e3).toFixed(0) + "K";
      } else {
          return (number / 1e3).toFixed(1) + "K";
      }
  } else {
      return number.toString();
  }
}
  
  export function formatData(data){ 

    //check if string is a platform
    if (data === "tiktok" || data === "youtube" || data === "instagram" || data === "twitter" || data === "reddit" || data === "facebook" || data === "twitch" || data === "vimeo" || data === "linkedin" || data == "x"){
      return formatPlatform(data);
    }
    //check if string is a UTC date
    else if (new Date(data) !== "Invalid Date" && !isNaN(new Date(data))){
      return UTCtoLocal(data);
    }
    else if (data === "true" || data === "false"){
      return data === "true" ? "Yes" : "No";
    }
    else if (!isNaN(data)){
      return formatNumber(data);
    }
    else{
      return data;
    
    }
  }

  export function formatFullDate(utcDate, includeYear=true){
    let date = new Date(utcDate);
    //format dd/mm
    let options = { day: '2-digit', month: '2-digit', year: includeYear ? '2-digit' : undefined };
    
    // Convert the date to local date string in the format 'dd-mm'
    let localDateString = date.toLocaleDateString(userLanguage, options).replace(/\//g, '/');
    
    return localDateString;
  }
  
  export function formatPlatform(platform){
    switch(platform){
      case "tiktok":
        return "TikTok";
      case "youtube":
        return "YouTube";
      case "instagram":
        return "Instagram";
      case "twitter":
        return "X/Twitter";
      case "x":
        return "X/Twitter";
      case "reddit":
        return "Reddit";
      case "facebook":
        return "Facebook";
      case "twitch":
        return "Twitch";
      case "vimeo":
        return "Vimeo";
      case "linkedin":
        return "LinkedIn";
      default:
        return platform;
    }
  }


export function UTCtoLocal(utcDate){
  let date = new Date(utcDate);
  //format dd/mm
  let options = { day: '2-digit', month: '2-digit' };
  
  // Convert the date to local date string in the format 'dd-mm'
  let localDateString = date.toLocaleDateString(userLanguage, options).replace(/\//g, '/');
  
  return localDateString;
}




export const icons = {
  "team": <GroupOutlined sx={{ height: 20, width: 20, fill: "#999" }} />,
  "group": <FolderOutlined sx={{ height: 20, width: 20, fill: "#999" }} />,
  "post": <VideoFileOutlined sx={{ height: 20, width: 20, fill: "#999" }} />,
  "report": <img src="/siftsy_icon.svg" style={{ height: 18, width: 18, fill: "#999" }} />,
};


export function containsJSInjection(value){
  const injectionPattern = /<script[\s\S]*?>[\s\S]*?<\/script>|javascript:/gi;
  return injectionPattern.test(value);
};

// Function to sanitize input using DOMPurify
export function sanitizeInput(value){
  return DOMPurify.sanitize(value);
};