


const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const SCREENSHOT_ENDPOINT = process.env.REACT_APP_SCREENSHOT_ENDPOINT;


const Functions = {

    fetchUser: (email) => fetchUser(email),
    listSubscriptionsByEmail: (email) => listSubscriptionsByEmail(email),
    listOwnedByEmail: (email) => listOwnedByEmail(email),
    listSiftsByEmail: (email) => listSiftsByEmail(email),
    fetchSift: (videoId, platform) => fetchSift(videoId, platform),
    updateUserSettings: (email, settings) => updateUserSettings(email, settings),
    checkAccount: (username, platform) => checkAccount(username, platform),
    searchCommentsByTopic: (topic, filters, postId, groupIds) => searchCommentsByTopic(topic, filters, postId, groupIds),
    fetchAccountAvatar: (platform, username) => fetchAccountAvatar(platform, username),
    screenShotComments: (html) => screenShotComments(html),
    getTeamData: (id) => getTeamData(id),
    updateTeamData: (teamId, data) => updateTeamData(teamId, data),
    fetchCommentData: (groupId, postId) => fetchCommentData(groupId, postId),
    fetchCommentDataByPostId: (postId) => fetchCommentDataByPostId(postId),
    getUserTeamData: (userId) => getUserTeamData(userId),
    updateGroupName: (groupId, name) => updateGroupName(groupId, name),
    updatePostGroups: (postId, groupIds) => updatePostGroups(postId, groupIds),
    updatePostTags: (postId, teamId, tags) => updatePostTags(postId, teamId, tags),
    updatePostsGroups: (postIds, groupIds) => updatePostsGroups(postIds, groupIds),
    createGroup: (name, teamId, campaignId) => createGroup(name, teamId, campaignId),
    createCampaign: (name, teamId) => createCampaign(name, teamId),
    exportCommentImage: (selectedCommentData) => exportCommentImage(selectedCommentData),
    getTeamUsageData : (teamId, start, end) => getTeamUsageData(teamId, start, end),
    getTeammateData : (teamId) => getTeammateData(teamId),
    linkDrop: (links, teamId, groupIds) => linkDrop(links, teamId, groupIds),
    createTeam: (name) => createTeam(name),
    linkUserTeam: (userId, teamId) => linkUserTeam(userId, teamId),
    createUser: (email, name) => createUser(email, name),
    unlinkUserTeam: (userId, teamId) => unlinkUserTeam(userId, teamId),
    addMemberToTeam: (teamId, email) => addMemberToTeam(teamId, email),
    removeMemberFromTeam: (teamId, userId) => removeMemberFromTeam(teamId, userId),
    getReportData: (postIds) => getReportData(postIds)
};

export default Functions;

async function removeMemberFromTeam(teamId, userId){

    let req = {
        teamId: teamId,
        userId: userId
    };

    return await fetch(`${ENDPOINT}/removememberfromteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data;
        }).catch((err) => console.log(err));
}

async function addMemberToTeam(teamId, email){

    let req = {
        email: email,
        teamId: teamId
    };

    return await fetch(`${ENDPOINT}/addmembertoteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data;
        }).catch((err) => console.log(err));
}

async function createUser(email, name){
        
            let req = {
                email: email,
                name: name
            };
        
            return await fetch(`${ENDPOINT}/createuser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
                .then((data) => {
        
                    console.log("data", data);
        
                    if (data && data.data) {
                        return data.data;
                    }
                }).catch((err) => console.log(err));
        
        }   

async function unlinkUserTeam(userId, teamId){
        
            let req = {
                userId: userId,
                teamId: teamId,
            };
        
            return await fetch(`${ENDPOINT}/unlinkuserteam`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
                .then((data) => {
        
                    console.log("data", data);
        
                    if (data && data.data) {
                        return data.data;
                    }
                }).catch((err) => console.log(err));
        
        }




async function linkUserTeam(userId, teamId){
    


        let req = {
            userId: userId,
            teamId: teamId,
            status: "active",
            role: "admin"
        };
    
        return await fetch(`${ENDPOINT}/linkuserteam`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

    async function getReportData(postIds){

        let req = {
            postIds: postIds
        };
    
        return await fetch(`${ENDPOINT}/getreportdata`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

async function createTeam(name){

    let req = {
        name: name,
    };

    return await fetch(`${ENDPOINT}/createteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function updateGroupName(name, groupId){
    
        let req = {
            groupId: groupId,
            name: name
        };
    
        return await fetch(`${ENDPOINT}/updategroupname`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }


async function linkDrop(links, teamId, groupIds){
            
        let req = {
            links: links,
            teamId: teamId,
            groupIds: groupIds
        };
    
        return await fetch(`${ENDPOINT}/linkdrop`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }




export async function getTeammateData(teamId){
    let req = {
        teamId: teamId
    };

    return await fetch(`${ENDPOINT}/getteammatedata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

export async function getTeamUsageData(teamId, start, end){
    let req = {
        teamId: teamId,
        start: start,
        end: end
    };

    return await fetch(`${ENDPOINT}/getteamusagedata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function exportCommentImage(selectedCommentData){

    let req = {
        comments: selectedCommentData
    };

    return await fetch(`${SCREENSHOT_ENDPOINT}/screenshot`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function createGroup(name, teamId, campaignId){

    let req = {
        name: name,
        teamId: teamId,
        type:"group",
        campaignId: campaignId
    };

    return await fetch(`${ENDPOINT}/creategroup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function createCampaign(name, teamId){

    let req = {
        name: name,
        teamId: teamId,
        type:"campaign"
    };

    return await fetch(`${ENDPOINT}/creategroup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updatePostGroups(postId, groupIds){

    let req = {
        postId: postId,
        groupIds: groupIds
    };

    return await fetch(`${ENDPOINT}/updatepostgroups`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updateTeamData(teamId, data){

    let req = {
        teamId: teamId,
        data: data
    };

    return await fetch(`${ENDPOINT}/updateteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function updatePostTags(postId, teamId, tags){

    let req = {
        postId: postId,
        teamId: teamId,
        tags: tags
    };

    return await fetch(`${ENDPOINT}/updateposttags`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updatePostsGroups(postIds, groupIds){

    let req = {
        postIds: postIds,
        groupIds: groupIds
    };

    return await fetch(`${ENDPOINT}/updatepostsgroups`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function getUserTeamData(userId){

    let req = {
        userId: userId
    };

    return await fetch(`${ENDPOINT}/getuserteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}



async function getTeamData(id){

    let req = {
        id: id
    };

    return await fetch(`${ENDPOINT}/getteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function screenShotComments(html){
    
        let req = {
            html: html
        };
    
        return await fetch(`${ENDPOINT}/screenshot`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }


async function fetchAccountAvatar(platform, username){

    let req = {
        platform: platform,
        username: username,
        imgType: "avatar"
    };

    return await fetch(`${ENDPOINT}/fetchimg`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function fetchCommentData(groupId){

    let req = {
        groupId: groupId,
    };

    return await fetch(`${ENDPOINT}/fetchcommentdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function fetchCommentDataByPostId(postId){

    let req = {
        postId: postId
    };

    return await fetch(`${ENDPOINT}/fetchcommentdatabypostid`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function searchCommentsByTopic(topic, filters, postId, groupIds){
    

    let req = {
        groupIds: groupIds,
        topic: topic,
        postId: postId,
        filters: filters
    };

    return await fetch(`${ENDPOINT}/search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function checkAccount(username, platform){

    let req = {
        username: username,
        platform: platform
    };

    return await fetch(`${ENDPOINT}/checkaccount`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}


async function listSubscriptionsByEmail(email){
    
    console.log("fetching subscriptions for " + email);

    let req = {
        email: email
    };

    return await fetch(`${ENDPOINT}/listsubscriptionsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {
                return data.data;
            }

            else {
                return [];
            }
        }).catch((err) => console.log(err));


}


async function listOwnedByEmail(email){

console.log("fetching subscriptions for " + email);

let req = {
    email: email
};

return await fetch(`${ENDPOINT}/listownedbyemail`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
}).then((res) => res.json())
    .then((data) => {

        console.log("data", data);

        if (data && data.data) {
            return data.data;
        }
        else{
            return [];
        }
    }).catch((err) => console.log(err));


}


    
async function fetchUser(email){

    let req = {
        email: email
    };
  
    return await fetch(`${ENDPOINT}/getuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
    .then((data) => {
          console.log("data", data);
          if (data && data.data) {
            let userData = data.data;
            return userData;
  }}).catch((err) => console.log(err));
  
  }


  async function listSiftsByEmail(email) {

    console.log("fetching sifts...");

    let req = {
        email: email
    };


    return await fetch(`${ENDPOINT}/listsiftsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            console.log("data", data);

            if (data && data.data) {

                const response = {
                    sifts: data.data
                };

                return response;
            }
        }).catch((err) => console.log(err));

}

async function fetchSift(videoId, platform) {

    console.log("fetching sift...");
  
    let req = {
      postId: videoId,
      platform: platform
    };
  
    return await fetch(`${ENDPOINT}/getsift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    }).then((res) => res.json())
      .then((data) => {
  
        console.log("data", data);
  
        if (data && data.data && data.data.sift) {
  
          const response = {
            fetchedSift: JSON.parse(data.data.sift),
            fetchedData: data.data,
            fetchedCommentData: JSON.parse(data.data.commentData),
            totalComments: data.data.totalComments,
          };
  
          return response;
        }
      })
  
      .catch((err) => {
        console.log("err", err);
      });
  
  }
  
  async function updateUserSettings(email, settings){
  
    let req = {
        email: email,
        settings: settings
    };
  
    return await fetch(`${ENDPOINT}/updateuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
  
    .catch((err) => console.log(err));
  
  }
  