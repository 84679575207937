import React, { useState } from "react";
import {
    Autocomplete,
  Box,
  ButtonBase,
  Chip,
  ClickAwayListener,
  FormControl,
  IconButton,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Check, Close } from "@mui/icons-material";
import Functions from "./Functions";

const GroupPicker = (props) => {
    const { deleteable, cellView, postIds, refreshData, softRefresh, teamData } = props;
  
    const [newGroupName, setNewGroupName] = useState("");
    const cellRef = React.useRef(null);
  
    const [cellViewOpen, setCellViewOpen] = useState(false);
    console.log("groups", props.teamData.groups);
    console.log("groupIds", props.data.groupIds);
  
    let groupOptions = props.teamData.groups ? props.teamData.groups : [];
  
    const [selectedGroups, setSelectedGroups] = useState(
     []
    );

    React.useEffect(() => {
        let groups = props.teamData.groups.filter((group) => props.data.groupIds.includes(group.id));
        setSelectedGroups(groups);
    }, [props.data.groupIds]);


    const handleSelectedGroupChange = async (event, newValue) => {
      console.log("newValue", newValue);
      const groups = newValue;

      let added  = groups.filter((group) => !selectedGroups.includes(group));
      let removed = selectedGroups.filter((group) => !groups.includes(group));
      
      console.log("added", added.length);
        console.log("removed", removed.length);
  
        let newGroups = [];


    if (added && added.length > 0) 
    {
        // let groupExists = props.teamData.groups.find((group) => group.id === added[0].id);

        // if (groupExists){
        //     newGroups = [...selectedGroups, ...added];
        // }
        // else{
        //     for (let i = 0; i < added.length; i++){
        //         let newGroup = await Functions.createGroup(added, teamData.id);
        //         newGroups.push(newGroup);
        //     }
        //     newGroups = [...selectedGroups, ...newGroups];
        // }
        let newGroups = [...selectedGroups, ...added];
        setSelectedGroups(newGroups);
        updatePostsGroups(postIds, newGroups.map((group) => group.id));
    }
    else{
        newGroups = selectedGroups.filter((item) => !removed.includes(item));
        setSelectedGroups(newGroups);
     updatePostsGroups(postIds, newGroups.map((group) => group.id));
        
    }
    };

    const updatePostsGroups = async (postIds, groupIds) => {

        console.log("postIds", postIds);
      let update = await Functions.updatePostGroups(postIds[0], groupIds);
      console.log("update post groups: ", update);
        
  
      softRefresh();
    };

    
     

  const autocompleteComponent = (
    <Autocomplete
    //   freeSolo
      multiple
      clearOnEscape={false}
      clearIcon={null}
      fullWidth
      value={selectedGroups}
      onChange={handleSelectedGroupChange}
      options={groupOptions}
      getOptionLabel={(option) => option.name}
      renderTags={(value, getTagProps) =>
        <Box sx={{ marginBottom: 1 }}>

    {        value.map((option, index) => (
            
          <Chip
            sx={{
                backgroundColor: "#f5f5f5",
                color: "#62676a",
                fontWeight: 500,
                fontSize: 12,
                height: 30
            }}
            key={option.id}
            label={option.name}
            deleteIcon={deleteable ? <Close /> : null}
            onDelete={
                deleteable
                  ? () =>
                      setSelectedGroups(
                        selectedGroups.filter((item) => item !== option)
                      )
                  : null
              }
            {...getTagProps({ index })}
          />
        ))}
        </Box>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          sx={{
            border: "0px",
            background: "transparent",
            color: "#999",
            fontWeight: 600,
            fontSize: 14,
          }}
          placeholder=" Select tags or type in a new one"
          onKeyDown={(event) => {
            if (event.key === 'Backspace' && event.target.value.trim() === '') {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />

      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Chip
            sx={{ backgroundColor: selected ? "#f5f5f5" : "white",  
                color: "#62676a",
                fontWeight: 500,
                fontSize: 12,
                height: 30}}
            label={option.name}
            variant="outlined"
            icon={selected ? <Check /> : null}
          />
        </li>
      )}
    />
  );

  return (
  
  <Box sx={{ display: "flex", flexDirection:"row", gap: 1, width:"100%" }} ref={cellRef}>
  {cellView && !cellViewOpen ? (
    <ButtonBase onClick={() => setCellViewOpen(true)}>
      <>
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
            {   props.teamData.groups.find(
                      (group) =>
                        group.id == props.data.groupIds[0]
                    ) &&    props.teamData.groups.find(
                        (group) =>
                          group.id == props.data.groupIds[0]
                      ).name ?
          <Chip
            sx={{ backgroundColor: "#f5f5f5",
                color: "#62676a",
                fontWeight: 500,
                fontSize: 12,
                height: 30 }}
                label={
                 
                      props.teamData.groups.find(
                          (group) =>
                            group.id == props.data.groupIds[0]
                        ).name
                  }
                />

            : 
            <Typography sx={{ fontSize: 12, m: 1, fontWeight: 600, color:"#aaa" }}>
            No Campaigns
            </Typography>
                }
          
        </Box>
        <Typography sx={{ fontSize: 14, m: 1, fontWeight: 600 }}>
          {selectedGroups.length > 1 && `+ ${selectedGroups.length - 1}`}
        </Typography>
      </>
    </ButtonBase>
  ) : (
    <Box sx={{ display: "flex", flexDirection:"row", gap: 1, width:"100%" }}>
        {cellView ? <CustomPopper cellRef={cellRef} setCellViewOpen={setCellViewOpen} cellViewOpen={cellViewOpen}>{autocompleteComponent}</CustomPopper> : autocompleteComponent}
    </Box>
    )}
  </Box>

  );
};

export default GroupPicker;

const CustomPopper = (props) => {
    return (
        <ClickAwayListener onClickAway={() => props.setCellViewOpen(false)}>
        <Popper
        {...props}
        open={props.cellViewOpen}
        anchorEl={props.cellRef && props.cellRef.current ? props.cellRef.current : document.body}
        placement="bottom"
        style={{
          background: 'white',
          minWidth:200
        }}
      >
        <Box sx={{ p: 1, background: 'white', borderRadius: 2, boxShadow: 2, maxWidth:300 }}>
        {props.children}
        </Box>
      </Popper>
      </ClickAwayListener>
    );
  };
