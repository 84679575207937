import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Container,
    List,
    ListItem,
    ListItemText,
    Autocomplete,
    Checkbox,
  } from "@mui/material";
  import React, { useCallback, useState } from "react";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import {
    Add,
    AddAPhotoOutlined,
    AddCardOutlined,
    AddOutlined,
    AddPhotoAlternateOutlined,
    AddToPhotosOutlined,
    ArrowBackIosNewOutlined,
    ArrowForward,
    AutoAwesome,
    BarChart,
    Chat,
    CheckBoxOutlined,
    CheckCircleOutlineOutlined,
    Clear,
    Close,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DeleteForeverOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FilterList,
    FolderOutlined,
    GroupOutlined,
    Info,
    OpenInNew,
    PendingActionsOutlined,
    RedoOutlined,
    RefreshOutlined,
    Remove,
    RemoveCircleOutlineOutlined,
    UndoOutlined,
    UploadFileOutlined,
    VideoFileOutlined,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";
  

  import Functions from "./Functions";
import GroupView from "./GroupView";
import { formatData, formatFullDate, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
import { getTeamUsageData } from "./Functions"
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { sanitizeInput, containsJSInjection } from "./Helpers";
import { set } from "lodash";

export default function ProcessingPostsModal(props) {

    const { open, softRefresh, refreshData, handleClose, type, name, teamData, isMobile, handleGroupChange
    } = props;

    const modalStyle = {
      width: "100%",
      borderRadius: 0, // Adjust the border radius as needed
      backgroundColor: 'white',
      // padding: '20px',
      outline: 'none', // Remove the default outline
      position:"fixed",
      left: "50%",
      bottom:0,
      maxHeight: '80%',
      overflowY: 'auto',
      overflowX: 'hidden',
      transform: "translate(-50%, 0)",
    };
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            // gap: ".5rem",
            display:"flex",
            // flexDirection:"column",
            // justifyContent:"center",          
          }}
        >
          
          <Box sx={{width:"100%", maxWidth:1000, margin:"auto", display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:2, p:4}}>

            <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-end", gap:2}}
                >
  
  <Button onClick={() => softRefresh()} startIcon={<RefreshOutlined/>} variant="outlined"  sx={{borderRadius:20, color:"#666", borderColor:"#666"}}>
        Refresh
        </Button>

                <Button onClick={handleClose} endIcon={<Close/>} >

                  Close

                </Button>

            </Box>


          {teamData && teamData.pendingPosts && teamData.pendingPosts.length > 0 ? (

            <>


<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
    <Box sx={{display:"flex", flexDirection:"column"}}>
<Typography variant="h6" sx={{fontWeight:700, color:"#666" }}>
            Your posts are being processed
            <br/>
            <Typography variant="body2" sx={{fontWeight:400, color:"#666" }}>
                <Info fontSize="small" sx={{mr:1, color:"#666", height:16}}/>
                This typically takes from 10 minutes up to a few hours depending on the posts and platform
            </Typography>   
        </Typography>
        </Box>
        <Box sx={{flexGrow:1}}/>

        </Box>

<br/>

<Accordion elevation={0} defaultExpanded>
<AccordionSummary expandIcon={<ExpandMore />}>
<Box
sx={{
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-stretch",
  flexGrow: 1,
}}
> 


<Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-stretch",
        flexGrow: 1,
        gap:2,
        mb:2,
      }}

    >
        

<Typography variant="body1" sx={{fontWeight:700, color:"#666" }}>
Posts ({teamData.pendingPosts.length})

</Typography>

</Box>

</Box>

</AccordionSummary>

<AccordionDetails>

<Box sx={{display:"flex", flexDirection:"column", gap:2, width:"100%"}}>
{teamData.pendingPosts.map((post) => (
  <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center"}}>
 
    <Box sx={{display:"flex", flexDirection:"column", gap:1, maxWidth:"60%"}}>
    <Link variant="body2" href={post.link} target="_blank" 

     //ellipsis if ismobile and link is too long
     sx={{flexGrow:1, overflow: "hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", maxWidth:isMobile ? 140 : "100%"}}
     >
      {post.link.replace(/(^\w+:|^)\/\//, '').replace("www.", "")}
    </Link>
    </Box>
    <Box sx={{flexGrow:1}}/>
    {post.createdAt && (
      <Typography variant="body2" color="#999">
        Uploaded: {formatFullDate(post.createdAt)}
      </Typography>
    )}
    {post.groupIds && post.groupIds.length > 0 && (
      post.groupIds.map((groupId) => (
            <Chip 
            sx={{ backgroundColor: "#f5f5f5",  
                color: "#62676a",
                fontWeight: 500,
                fontSize: 12,
                height: 30}}
            label={teamData.groups.find((group) => group.id === groupId) && teamData.groups.find((group) => group.id === groupId).name} />
      ))
    )}
  </Box>
))}

</Box>

</AccordionDetails>

</Accordion>

</>
          )

: 


<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:"100%", mt:4, mb:4}}>
<Box sx={{display:"flex", flexDirection:"column", gap:2}}>
<Typography variant="h6" sx={{fontWeight:700, color:"#666" }}>
       <CheckCircleOutlineOutlined sx={{color:"#666", height:24, width:24, mr:1}}/>
       Up to date
        <br/>
        <Typography variant="body2" sx={{fontWeight:400, color:"#666" }}>
            You have no posts processing.
        </Typography>   
    </Typography>
    </Box>
    </Box>
    

}


   </Box>
        </Box>
        
      </Modal>
    );
  }

