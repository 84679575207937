import React, { useState } from "react";
import {
    Box,
    ButtonBase,
    Chip,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    } from "@mui/material";
    import { Add, Check, Close } from "@mui/icons-material";
    import Functions from "./Functions";
    


const SelectChips = (props) => {
    const { deleteable, cellView, postIds, refreshData, softRefresh } = props;
  
    const [newGroupName, setNewGroupName] = useState("");
  
    const [cellViewOpen, setCellViewOpen] = useState(false);
    console.log("groups", props.teamData.groups);
    console.log("groupIds", props.data.groupIds);
  
    let groupOptions = props.teamData.groups ? props.teamData.groups : [];
  
    const [selectedGroups, setSelectedGroups] = useState(
      props.teamData.groups &&
        props.data.groupIds &&
        props.data.groupIds.length > 0
        ? props.teamData.groups.filter((group) =>
            props.data.groupIds.includes(group.id)
          )
        : []
    );
  
    const handleSelectedGroupChange = async (event, newValue) => {
      console.log("newValue", newValue);
      const group = newValue.props.value;
      //if group is not in selectedGroups, add it, else remove it
  
      let groups = selectedGroups;
      if (groups.some((item) => item.id === group.id)) {
        groups = groups.filter((item) => item.id !== group.id);
      } else {
        groups = [...groups, group];
      }
  
      setSelectedGroups(groups);
  
      //update groupIds in post data
  
      console.log("postId", postIds);
      // console.log("groups", groups.map((group) => group.id));
  

      console.log("selectedGroups", selectedGroups);
    };

    const updatePostsGroups = async (postIds) => {

      let groups = selectedGroups;
      let post = await Functions.updatePostsGroups(postIds, [
        ...groups.map((group) => group.id),
      ]);
  
      softRefresh();
    };
  
    return (
      cellView && !cellViewOpen ? (
        <ButtonBase onClick={() => setCellViewOpen(true)} >
          <>
          <Box sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth:105}}>
        <Chip
        sx={{fontSize:10, fontWeight:600}}
        label={
          props.teamData.groups.find(
            (group) =>
              group.id == props.data.groupIds[0]
          )
            ? props.teamData.groups.find(
                (group) =>
                  group.id == props.data.groupIds[0]
              ).name
            : "No Campaign"
        }
      />
      </Box>
  <Typography sx={{fontSize:14, m:1, fontWeight:600}}>
      {selectedGroups.length > 1 && (
         `+ ${selectedGroups.length - 1}`) }

  </Typography>
      </>
      </ButtonBase>
      ) :
  
      <FormControl fullWidth>
        <Select
          multiple
          fullWidth
          value={selectedGroups}
          onChange={handleSelectedGroupChange}
          onClose={() => updatePostsGroups(postIds)}
          variant="standard"
          sx={{
            border: "0px",
            background: "transparent",
            color: "#999",
            fontWeight: 600,
            fontSize: 14,
            // "&:hover": cellView ? { background: "#e9f0ff" } : null,
          }}
          disableUnderline
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: cellView ? "nowrap" : "wrap",
                gap: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                background: "transparent",
                
              }}
            >
              {selected.map((option) => (
                <Chip
                  sx={{fontSize:10, fontWeight:600}}
                  key={option}
                  label={option.name}
                  deleteIcon={deleteable ? <Close /> : null}
                  onDelete={
                    deleteable
                      ? () =>
                          setSelectedGroups(
                            selectedGroups.filter((item) => item !== option)
                          )
                      : null
                  }
                />
              ))}
            </Box>
          )}
        >
          {groupOptions.map((group) => (
            <MenuItem key={group.id} value={group}>
              <Chip
                label={group.name}
                variant="outlined"
                icon={
                  selectedGroups.some(
                    (selectedGroup) => selectedGroup.id === group.id
                  ) ? (
                    <Check />
                  ) : null
                }
              />
            </MenuItem>
          ))}
  
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              p: 1,
            }}
          >
            <Chip
              label={
                <TextField
                  value={newGroupName}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
  
                    setNewGroupName(e.target.value);
                  }}
                  variant="standard"
                  placeholder="New Campaign"
                  InputProps={{ disableUnderline: true }}
                />
              }
            />
            <IconButton
              disabled={newGroupName.length < 2}
              onClick={async () => {
                let group = await Functions.createGroup(
                  newGroupName,
                  props.teamData.id
                );
  
                console.log("group", group);
                setSelectedGroups([...selectedGroups, group]);
                setNewGroupName("");
                refreshData();
              }}
            >
              <Add />
            </IconButton>
          </Box> */}
        </Select>
      </FormControl>
    );
  };

  export default SelectChips;