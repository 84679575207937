import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Chip,
  CircularProgress,
  ButtonGroup,
  AvatarGroup,
  LinearProgress,
} from "@mui/material";
import ThumbnailGallery from "./ThumbnailGallery";
import { formatFullDate, formatNumber, formatData, estimateViews } from "./Helpers";
import MainSummary from "./MainSummary";
import SelectChips from "./SelectChips";
import {
  CalendarTodayOutlined,
  CheckCircleOutlineRounded,
  ContentCopyOutlined,
  Download,
  FavoriteBorderOutlined,
  OpenInNew,
  VisibilityOutlined,
  CommentOutlined,
  SendOutlined,
  ExpandMore,
  Minimize,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Avatar } from "@mui/material";
import { styled as muiStyled } from "@mui/system";
import html2canvas from "html2canvas";
import ContentTagPicker from "./ContentTagPicker";
import GroupPicker from "./GroupPicker";
import { min } from "lodash";

const CircularAvatar = muiStyled(Avatar)(({ theme, minimize }) => ({
  height: minimize ? 30 : 40,
  width: minimize ? 30 : 40,
  marginRight: 10,
  background: theme.palette.primary.main,
}));


export default function ContentMetrics(props) {
  const { selectedPostData, teamData, refreshData, screenshot, isMobile, softRefresh } = props;
  const [engagementRate, setEngagementRate] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [renderImages, setRenderImages] = useState(true);
  const [copied, setCopied] = useState(false);
  const [views, setViews] = useState(null);
  const [likes, setLikes] = useState(null);
  const [comments, setComments] = useState(null);
  const [shares, setShares] = useState(null);
  const [saves, setSaves] = useState(null);
  const [numAccounts, setNumAccounts] = useState(null);
  const metricsScreenshotRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [hover, setHover] = useState(false);
  const [accounts, setAccounts] = useState(null);

  const {minimizeMetrics, setMinimizeMetrics} = props;

  // const images = React.useMemo(() => {
  //   return selectedPostData && selectedPostData.length > 0
  //     ? selectedPostData.slice(0, selectedPostData.length > 20 ? 20 : selectedPostData.length)
  //     .map((post) => post.data.thumbnail)
  //     : null;
  // }, [selectedPostData]);

  React.useEffect(() => {
    document.getElementById("metrics-panel") &&
      document
        .getElementById("metrics-panel")
        .addEventListener("mouseover", () => {
          setHovered(true);
        });

    document.getElementById("metrics-panel") &&
      document
        .getElementById("metrics-panel")
        .addEventListener("mouseout", () => {
          setHovered(false);
        });

    return () => {
      document.getElementById("metrics-panel") &&
        document
          .getElementById("metrics-panel")
          .removeEventListener("mouseover", () => {
            setHovered(true);
          });

      document.getElementById("metrics-panel") &&
        document
          .getElementById("metrics-panel")
          .removeEventListener("mouseout", () => {
            setHovered(false);
          });
    };
  }, []);

  React.useEffect(() => {
    if (selectedPostData && selectedPostData.length > 0) {
      let posts = selectedPostData.filter(
        (post) =>
          post.status == "complete"
      );

      const views = formatNumber(
        posts
          .map((post) => post.data.engagement.views > 0 ? post.data.engagement.views : 
          estimateViews(post.data.engagement.likes, post.data.engagement.comments, post.data.engagement.shares, post.data.engagement.saves, post.data.platform))
          .reduce((a, b) => a + b, 0)
      );
      const likes = formatNumber(
        posts
          .map((post) => post.data.engagement.likes)
          .reduce((a, b) => a + b, 0)
      );
      const comments = formatNumber(
        posts
          .map((post) => post.data.engagement.comments)
          .reduce((a, b) => a + b, 0)
      );
      const shares = formatNumber(
        posts
          .map((post) => post.data.engagement.shares)
          .reduce((a, b) => a + b, 0)
      );

      const saves = formatNumber(
        posts
          .map((post) => post.data.engagement.saves)
          .reduce((a, b) => a + b, 0)
      );
      setViews(views);
      setLikes(likes);
      setComments(comments);
      setShares(shares);
      setSaves(saves);

      const engagementRate =
        posts.filter((post) => post.data.engagement.views > 0 || post.data.platform == "linkedin")
          .map(
            (post) =>
              (((post.data.engagement.likes ? post.data.engagement.likes : 0) +
                (post.data.engagement.comments ? post.data.engagement.comments : 0) +
                (post.data.engagement.shares ? post.data.engagement.shares : 0) + (post.data.engagement.saves ? post.data.engagement.saves : 0)) *
                100) /
              (post.data.engagement.views > 0 ? post.data.engagement.views : estimateViews(post.data.engagement.likes, post.data.engagement.comments, post.data.engagement.shares, post.data.engagement.saves, post.data.platform))
          )
          .reduce((a, b) => a + b, 0) / posts.length;

      setEngagementRate(engagementRate.toFixed(1));
    }

    const accounts = selectedPostData
      .map((post) => post.data.username.replace("@", ""))
      .filter((value, index, self) => self.indexOf(value) === index);

    setAccounts(accounts);

    setNumAccounts(accounts.length);

  }, [selectedPostData]);

  const handleExportMetrics = async () => {
    //delay .5s
    setExporting(true);
    setRenderImages(false);
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (metricsScreenshotRef.current) {
      // metricsScreenshotRef.current.style.display = "flex";
      // deep copy metricsRef.current element

      const style = document.createElement('style');
      document.head.appendChild(style);
      style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');
      
      let canvas = await html2canvas(metricsScreenshotRef.current, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: null,
        scale: 4,
        letterRendering: true,
        imageTimeout: 5000,
      });

      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `siftsy_metrics.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      style.remove();

      // metricsScreenshotRef.current.style.display = "none";
    }

    setExporting(false);
    setRenderImages(true);
  };

  const handleCopyMetrics = async () => {
    let metrics = "";

    if (selectedPostData && selectedPostData.length > 0) {
      
      const sortedPosts = selectedPostData
    .filter((post) => post.data.postedAt)
    .sort((a, b) => new Date(a.data.postedAt) - new Date(b.data.postedAt));
  const startPost = sortedPosts[0];
  const endPost = sortedPosts[sortedPosts.length - 1];

    let startDate = formatFullDate(startPost.data.postedAt, true);
    let endDate = formatFullDate(endPost.data.postedAt, true);

      metrics = `Posts: ${selectedPostData.length}\nAccounts: ${numAccounts}\nStart: ${startDate}\nEnd: ${endDate}\nEngagement Rate: ${engagementRate}%\nViews: ${views}\nLikes: ${likes}\nComments: ${comments}\nShares: ${shares}`;
    }

    navigator.clipboard.writeText(metrics);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  

  return (
    <>
    <Box
      id={"metrics-panel"}
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #DDD",
        borderRadius: 3,
        p:minimizeMetrics ? 0 : 3,
        pr:minimizeMetrics ? 2: 3,
        pl:minimizeMetrics ? 2 : 3,
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "20px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
          borderRadius: 20,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#ddd",
          border: "5px solid #f7f8fa",
          borderRadius: 20,
        },
      }}
      // ref={metricsRef}
      ref={metricsScreenshotRef}

    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          position: "relative",
        }}
      >
                {/* { !screenshot && hovered && !exporting && (
          <ButtonGroup
            size="small"
            sx={{
              background: "#f7f7f7",
              position: "absolute",
              top: 10,
              right: 0,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "5px 10px",
              gap: ".5rem",
              borderRadius: 30,
              zIndex: 999,
            }}
            id="metrics-size"
          >
        
          </ButtonGroup>
        )} */}

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ height: 10 }} />

        { !screenshot && hovered && !exporting && (
          <ButtonGroup
            size="small"
            sx={{
              background: "#f7f7f7",
              position: "absolute",
              top: 10,
              right: 0,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "5px 10px",
              gap: ".5rem",
              borderRadius: 30,
              zIndex: 999,
            }}
            id="metrics-actions"
          > 
             {/* <Tooltip title="Minimize" placement="top">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setMinimizeMetrics(!minimizeMetrics)} 
          >
            {minimizeMetrics ? (
              <FullscreenOutlined />
            ) : (
              <FullscreenExitOutlined />
            )}
          </IconButton>
        </Tooltip> */}
            <Tooltip title="Copy Metrics" placement="top">
              <IconButton
                size="small"
                color="primary"
                onClick={handleCopyMetrics}
              >
                {copied ? (
                  <CheckCircleOutlineRounded
                    color="success"
                    sx={{ width: 20, height: 20 }}
                  />
                ) : (
                  <ContentCopyOutlined sx={{ width: 20, height: 20 }} />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title="Download As Image" placement="top">
              <IconButton
                color="primary"
                size="small"
                onClick={handleExportMetrics}
              >
                {exporting ? (
                  <CircularProgress size={20} />
                ) : (
                  <Download sx={{ width: 20, height: 20 }} />
                )}
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={minimizeMetrics ? 6 : 12}>
          <Box sx={{ display: "flex", flexDirection: "row", gap:2, alignItems:"center", justifyContent:"flex-start" }}>
            {selectedPostData &&
            selectedPostData.length > 0 &&
            selectedPostData[0].id ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: minimizeMetrics ? "row" : "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1em",
                  width: "100%",
                }}
              >
                {selectedPostData.length == 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                      // alignItems: "center",
                      // background:"#ff0000"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "top",
                        width: "100%",
                        mb: 1,
                        justifyContent: "center",
                        alignContent: "center",
                        minWidth:200
                      }}
                    >
                      {selectedPostData[0].data.userAvatar ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // mt: 2,
                          }}
                        >
                          <CircularAvatar
                            minimize={minimizeMetrics}
                            src={selectedPostData[0].data.userAvatar}
                          />
                        </Box>
                      ) : null}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >

                        <Box sx={{ display: "flex", flexDirection: "row", gap:0.5, p:1 }}>
                          <Typography variant={minimizeMetrics ? "body2" : "body1"} fontWeight={700} sx={{maxWidth:180, whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}
                           align="left">
                            {selectedPostData[0].data.creatorName} 
                          </Typography>
                          <Chip label={formatData(selectedPostData[0].data.platform)} size="small" sx={{fontSize:10, fontWeight:600, backgroundColor:"#eeeaff"}}/>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "top",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography
                              variant={minimizeMetrics ? "body2" : "body1"}
                              align="left"
                            >
                              @
                              {selectedPostData[0].data.username.replace(
                                "@",
                                ""
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      
                      </Box>
                    </Box>
                  </Box>
                )}
    {!minimizeMetrics && renderImages && 
                <ThumbnailGallery
                  multiple={selectedPostData.length > 1}
                  // images={images}
                  selectedPostData={selectedPostData}
                  numAccounts={numAccounts}
                  numPosts={selectedPostData.length}
                />
    }

                {!minimizeMetrics && selectedPostData.length == 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                      width:"100%"
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems:"center", justifyContent:"center" }}>
<Typography
              variant="body1"
              align="left"
              fontWeight={700}
            
            >
              About This Post
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
                      {!exporting && (
            <Button
                      variant="outlined"
                      sx={{
                        borderRadius: 12,
                        position: "relative",
                        //   width: "fit-content",
                        margin: "auto",
                        padding: "2px 12px",
                        fontSize: 12,
                      }}
                      size="smalls"
                      endIcon={<OpenInNew sx={{ height: 16, width: 16 }} />}
                      color="primary"
                      onClick={() =>
                        window.open(selectedPostData[0].link, "_blank")
                      }
                    >
                      Link
                    </Button>
                      )}

                    </Box>

            <Typography variant="body2" align="left">
              <MainSummary
                text={
                  selectedPostData[0].data.summary
                    ? selectedPostData[0].data.summary
                    : selectedPostData[0].data.description
                }
              />
            </Typography>

            <StartEndDates selectedPostData={selectedPostData} minimizeMetrics={minimizeMetrics}/>

                  </Box>

                ) : <>
                
             
                  {renderImages ? 
                     <Box
                     sx={{
                       display: "flex",
                       flexDirection: "row",
                       alignItems: "center",
                       justifyContent: "center",
                     }}
                   >
    {numAccounts > 1 && ( 
                  <Grid container spacing={minimizeMetrics ? 1 : 6} sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                 
                    <Grid item xs={minimizeMetrics ? 12 : 6} sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                  <AvatarGroup
                  sx={{transform: "scale(.7)"}}
                    max={4}
                    spacing={"small"}
                    total={numAccounts}
                    variant="circular"
                    renderSurplus={(surplus) => (
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#eeeaff",
                          color: "#333",
                          fontWeight: 700,
                        }}
                        >
                          <Typography variant="body1" fontSize={14} fontWeight={700} color="primary">
                            +{surplus}
                          </Typography>
                        </Avatar>
                      )}
                  >
                    {selectedPostData.map((post) => (
                      <BitmapAvatar
                        sx={{ width: 40, height: 40 }}
                        key={post.id}
                        src={post.data.userAvatar}
                      />
                    ))}
                  </AvatarGroup>
                  
                  </Grid>
                 
                 {!minimizeMetrics && (
                  <Grid item xs={minimizeMetrics ? 12 : 6} sx={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
                  <Typography
                    variant="body1"
                    fontSize={16}
                    fontWeight={700}
                    color="#333"
                    whiteSpace={"nowrap"}
                  >
                    {`${numAccounts} Account${numAccounts > 1 ? "s" : ""}`}
                  </Typography>
                  </Grid>
                  )}
                  </Grid>
                )}
                {minimizeMetrics && selectedPostData.length > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={minimizeMetrics ? 10 : 12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Posts
                        </Typography>
                
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 22}
                        >
                              {selectedPostData.length} 
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  </Box>
                  :


                  <Grid container spacing={2}>
                  {numAccounts > 1 && (
                    <>
                    <Grid item xs={6}>
                                        <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "flex-start",
                                          flexGrow: 1,
                                          border: minimizeMetrics ? null : "1px solid #ddd",
                                          p: minimizeMetrics ? 1 : 2,
                                          borderRadius: 4,
                                          overflow:"hidden"
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            width: "100%",
                                            gap: .5,
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            fontSize={minimizeMetrics ? 10 : 12}
                                            fontWeight={700}
                                            align="left"
                                            textTransform={"uppercase"}
                                          >
                                            Posts
                                          </Typography>
                                  
                                        </Box>
                  
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            width: "100%",
                                            gap: 1,
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            fontWeight={700}
                                            fontSize={minimizeMetrics ? 16 : 22}
                                          >
                                                       {selectedPostData.length} 
                                          </Typography>
                                        </Box>
                                      </Box>  
                                      </Grid>
                                      <Grid item xs={6}>
                  
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                        overflow:"hidden"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontSize={minimizeMetrics ? 10 : 12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Accounts
                        </Typography>
                
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 22}
                        >
                                     {numAccounts} 
                        </Typography>
                      </Box>
                    </Box>  
                    </Grid>
                    </>                
               
                  )}
                  </Grid>
   
                  }
         
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    overflow:"hidden"
                  }}
                >
                  <StartEndDates selectedPostData={selectedPostData} minimizeMetrics={minimizeMetrics}/>
                </Box>
    
                </>}
              </Box>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={minimizeMetrics ? 2 : 12}>
          {engagementRate && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                flexGrow: 1,
                cursor: "pointer",
                gap: minimizeMetrics ? 0 : 1,
                border: minimizeMetrics ? null : "1px solid #ddd",
                p: minimizeMetrics ? 1 : 2,
                borderRadius: 4,
              }}
            >
              <Tooltip
                title="The engagement rate is the percentage of people who saw a post and engaged with it. It is calculated as the sum of likes, comments, and shares divided by the total number of views."
                placement="bottom"
              >
                <>
                  {/* <LocalActivityRounded sx={{ height: 16 }} /> */}
                  <Typography
                    variant="body1"
                    fontSize={minimizeMetrics ? 10 : 14}
                    fontWeight={700}
                    align="left"
                    textTransform={"uppercase"}
                  >
                    Engagement Rate
                  </Typography>
                  
                  {!isNaN(engagementRate) && isFinite(engagementRate) && views != 0 ? (
                    <Typography
                      variant="body1"
                      fontSize={minimizeMetrics ? 16: 26}
                      fontWeight={700}
                      align="left"
                    >
                      {engagementRate}%{selectedPostData.every((post) => post.data.platform == "linkedin" ? "*" : "")}
                      {/* {selectedPostData.some((post) => post.data.platform == "linkedin" || (post.data.platform == "instagram" && post.data.postType == "feed")) ? "*" : ""} */}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      color={"#999"}
                      fontSize={minimizeMetrics ? 16: 26}
                      fontWeight={700}
                    >
                      N/A
                    </Typography>
                  )}
                </>
              </Tooltip>
            </Box>
          )}
        </Grid>

        <Grid item xs={minimizeMetrics ? 4 : 12}>
          {selectedPostData &&
          selectedPostData.length > 0 &&
          selectedPostData[0].id ? (
            <>
              <Box>
                <Grid container spacing={2}>
                <Grid item xs={minimizeMetrics ? 3: 6}>
                <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <VisibilityOutlined
                         sx={{ height: 16, width:16, color: "#333" }} />
 {!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Views
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 22}
                        >
                          {views}

                          {selectedPostData.some(
                            (post) => post.data.engagement.views == 0
                          )
                            ? "*"
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={minimizeMetrics ? 3: 6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <FavoriteBorderOutlined
                           sx={{ height: 16, width:16, color: "#333" }} />
 {!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Likes
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 22}
                        >
                          {likes}

                          {selectedPostData.length == 1 &&
                          selectedPostData[0].data.engagement.likes == 0
                            ? "**"
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={minimizeMetrics ? 3: 6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <CommentOutlined sx={{ height: 16, width:16, color: "#333" }} />
                        {!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Comments
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 22}
                        >
                          {comments}
                     
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={minimizeMetrics ? 3: 6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <SendOutlined  sx={{ height: 16, width:16, color: "#333" }} />
                        {!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Shares
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={minimizeMetrics ? 16 : 22}
                        >
                          {shares}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {!minimizeMetrics && selectedPostData.some(
                (post) => post.data.engagement.views == 0 && post.data.platform == "linkedin"
              ) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    pt: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{ color: "#666" }}
                  >
                    <Tooltip title="Top-line view/impression count is currently not available from LinkedIn and is estimated based on engagement and historical averages." placement="top">
                    *View/Impressions estimated for LinkedIn.
                    </Tooltip>
                  </Typography>
                </Box>
              ) : null}
              {!minimizeMetrics && selectedPostData.some(
                (post) => post.data.engagement.views == 0 && post.data.platform == "instagram" && post.data.postType == "feed"
              ) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    pt: 2,
                  }}
                > 
              <Tooltip title="Top-line view/impression count is currently not available for static, in-feed Instagram posts." placement="top">
              {/* and is estimated based on engagement and historical averages." placement="top"> */}
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{ color: "#666" }}
                  >
                    *View count not available for IG static posts.
                  </Typography>
                  </Tooltip>
                </Box>
              ) : null}
              {!minimizeMetrics && selectedPostData.length == 1 &&
              selectedPostData[0].data.engagement.likes == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    pt: 1,
                  }}
                >
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{ color: "#888" }}
                  >
                    **View or like count may be hidden by creator.
                  </Typography>
                </Box>
              ) : null}
            </>
          ) : null}

        </Grid>
        {!minimizeMetrics && selectedPostData &&
        selectedPostData.length == 1 &&
        selectedPostData[0].id ? (
          <Box sx={{ display:"flex", flexDirection:"column", alignItems:"flex-start", p: 2, gap:2, width:"100%" }}>

            <Typography
              variant="body1"
              align="left"
              fontWeight={700}
            
            >
              Tags
            </Typography>
         
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, width:"100%" }}>
            {/* {selectedPostData[0].data.tags

              ? JSON.parse(selectedPostData[0].data.tags).map((tag) => (
                  <Chip
                    label={tag}
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        padding: "6px 12px",
                      },
                    }}
                  />
                ))
              : null} */}
              
              <ContentTagPicker teamData={teamData}
              refreshData={refreshData}
              data={{ groupIds: selectedPostData[0].groupIds }}
              post={selectedPostData[0]}
              softRefresh={softRefresh}
              />
            
              </Box>
          </Box>
        ) : null}

        {!minimizeMetrics && selectedPostData &&
        selectedPostData.length == 1 &&
        selectedPostData[0].id ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              p:2,
              width:"100%"
            }}
          >
            <Typography variant="body1" align="left" fontWeight={700}>
              Campaigns
            </Typography>

            <GroupPicker
              refreshData={refreshData}
              data={{ groupIds: selectedPostData[0].groupIds }}
              teamData={teamData}
              postId={selectedPostData[0].id}
              softRefresh={softRefresh}
            />
          </Box>
        ) : null}
      </Grid>
    </Box>

</>
  );
}

function BitmapAvatar(props) {
  const [bitmapData, setBitmapData] = React.useState(props.src);
  const { src } = props;
  const imgRef = React.useRef(null);

  // console.log("BitmapAvatar src: ", src);

  const fetchAndConvertToBitmap = async(src) => {
    if (!src) {
      console.log('Please enter a valid image.');
      return;
    }

    let img = new Image();
    img.crossOrigin = 'Anonymous'; // Allow cross-origin images
    img.src = src;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);
      // Get the image data in bitmap format
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const bitmapData = imageData.data;
      // Convert bitmap data to base64
      const bitmapToBase64 = (bitmapData, width, height) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(width, height);
        
        // Fill the ImageData with the bitmap data
        for (let i = 0; i < bitmapData.length; i++) {
          imageData.data[i] = bitmapData[i];
        }
      
        // Set the ImageData on the canvas
        ctx.putImageData(imageData, 0, 0);
      
        // Convert canvas to base64
        return canvas.toDataURL('image/bmp');
      };
      
      // Inside fetchAndConvertToBitmap function:
      const base64Bitmap = bitmapToBase64(bitmapData, img.width, img.height);
      if (base64Bitmap == null || base64Bitmap == "") {
        console.log('Failed to convert bitmap to base64.');
        return;
      }
      setBitmapData(base64Bitmap);
      // console.log(base64Bitmap);

    };

  };

  React.useEffect(() => {
    fetchAndConvertToBitmap(src);
  }, [src]);

  return (
    <>

        <CircularAvatar {...props} src={bitmapData}/>
   

    </>
  );
}

function StartEndDates(props) {
  const { selectedPostData, minimizeMetrics} = props;

  const sortedPosts = selectedPostData
    .filter((post) => post.data.postedAt)
    .sort((a, b) => new Date(a.data.postedAt) - new Date(b.data.postedAt));
  const startPost = sortedPosts[0];
  const endPost = sortedPosts[sortedPosts.length - 1];

  //check if start post and end post are in the same year

  const sameYear =
    new Date(startPost.data.postedAt).getFullYear() ==
    new Date(endPost.data.postedAt).getFullYear();

  if (startPost == endPost) {
    return (
      <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: minimizeMetrics ? null : "1px solid #ddd",
                        p: minimizeMetrics ? 1 : 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <CalendarTodayOutlined
                        sx={{ height: 14, width:14, color: "#333" }} />

{!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Date
                        </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                             <Typography variant="body1" fontSize={14} fontWeight={700}>
        {formatFullDate(startPost.data.postedAt, true)}
      </Typography>
                      </Box>
                    </Box>
                    

    );
  } else {
    return (
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flexGrow: 1,
        border: minimizeMetrics ? null : "1px solid #ddd",
        p: minimizeMetrics ? 1 : 2,
        gap: minimizeMetrics ? 0 : .5,
        borderRadius: 4,
     

      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          gap: 1,
        }}
      >
        <CalendarTodayOutlined
        sx={{ height: 14, width:14, color: "#333" }} />


{!minimizeMetrics && (
                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Dates
                        </Typography>
                        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          gap: 1,
        }}
      >
      <Typography variant="body1" fontSize={minimizeMetrics ? 14 : 16} fontWeight={700} sx={{textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        width:"100%"}}>
        {`${formatFullDate(startPost.data.postedAt, !sameYear)}  -  ${formatFullDate(endPost.data.postedAt, true)}`}
      </Typography>

      </Box>
    </Box>


    );
  }
}
