import { Box, Grid, Typography, Slider, Card, createTheme, ThemeProvider, ButtonBase, AppBar, Divider, Skeleton, Tabs, Tab, CardContent, AvatarGroup, Avatar } from '@mui/material';
import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { Add, AutoAwesome, Comment, ConnectWithoutContact, IndeterminateCheckBoxSharp, Person, PersonOutline, PersonPinCircle, PersonTwoTone, SwitchAccount } from '@mui/icons-material';
import axios from 'axios';
import TikTokComment from './TikTokComment';
import { siftsyTheme } from './siftsytheme';
import { useParams } from 'react-router-dom';
import { useAuth, UserButton, useUser } from "@clerk/clerk-react";
import { sifterOptions } from './constants';
import ConnectionModule from './ConnectionModule';
import { ReviewingModal } from "./ConnectionModule";
import Functions from './Functions';

const darkMode = false;

const theme = createTheme(siftsyTheme);

const StyledButton = muiStyled(ButtonBase)(({ theme }) => ({
    borderRadius: 30,
    background: `${theme.palette.primary.main}`,
    fontFamily: [
        "Gilroy",
        'sans-serif',
    ].join(','),
    fontSize: '1rem',
    fontWeight: 900,
    position: "fixed",
    bottom: 20,
    left: "calc(50% - 85%/2)",
    color: theme.palette.secondary.main,
    padding: "15px 0px",
    width:"85%",
    boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.5)'
}));

const StyledCallout = muiStyled(Card)(({ theme }) => ({
    borderRadius: 30,
    background: `${theme.palette.primary.main}`,
    fontFamily: [
        "Gilroy",
        'sans-serif',
    ].join(','),
    fontSize: '1rem',
    fontWeight: 900,
    color: theme.palette.secondary.main,
    padding: "15px 0px",
    width: "fit-content",
    elevation: 0,
}));




export default function UserPrefs(props) {

    const [sifts, setSifts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selected, setSelected] = React.useState(null);
    const [userData, setUserData] = React.useState(null);
    const [accountReview, setAccountReview] = React.useState(false);
    const [username, setUsername] = React.useState(null);

    const [ownedAccounts, setOwnedAccounts] = React.useState([]);
    const [subbedAccounts, setSubbedAccounts] = React.useState([]);

    const { isLoaded, isSignedIn, user } = useUser();

    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    window.addEventListener('resize', () => {
        setPageWidth(window.innerWidth);
    });

    React.useEffect(() => {

        async function fetchData() {

            let loadedData = await Functions.fetchUser(user.emailAddresses[0].emailAddress);

            setUserData(loadedData);

            let owned = await Functions.listOwnedByEmail(user.emailAddresses[0].emailAddress)
            owned = owned.filter((account) => !account.subscribers.includes(user.emailAddresses[0].emailAddress));
            let subs = await Functions.listSubscriptionsByEmail(user.emailAddresses[0].emailAddress);

            setSubbedAccounts(subs);
            setOwnedAccounts(owned);

            if(subs.length == 0 && owned.length == 0){
                window.location.href = "/connect";
            }

            let accounts = [...owned, ...subs];

            let review = false;

            for(let i = 0; i < accounts.length; i++){
                if(accounts[i].status == "review"){
                    review = true;
                    setUsername(accounts[i].username.replace("@", ""));
                }
                if(accounts[i].status == "active"){
                    review = false;
                }
            }

            setAccountReview(review);


            const res = await Functions.listSiftsByEmail(user.emailAddresses[0].emailAddress);

            if (res) {
                let sifts = [];
                for(let i = 0; i < res.sifts.length; i++){
                    if(new Date(res.sifts[i].createdAt) > new Date("2023-08-30T00:00:00.000Z")){
                        sifts.push(res.sifts[i]);
                    }
                }

                sifts.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                
                setSifts(sifts);
            }
            
            setLoading(false);
        }

        fetchData();

    }, []);

    return (

        <div id="popup" style={{
            margin: "0 auto", width: pageWidth > 450 ? 450 : pageWidth, height: "auto",
            overflowX: "hidden",
            overflowY:"auto"


        }}>

            <ThemeProvider theme={theme}>


                <AppBar position="static" elevation={0} sx={{ background: "transparent" }}>

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-stretch", alignItems: "center", m: 1 }}>


                    <ButtonBase onClick={() =>  window.location.href="/"}>

<img src={"/siftsy_logo.png"} alt="logo" style={{ width: 80, height: "auto", margin: "5px" }} />
    <Typography color="primary" align="center" variant={"subtitle2"}
                    sx={{borderRadius:30, background : "rgba(238,234,255, .8)", color: "#4828C5", padding:"1px 12px 1px 12px", ml:-1,
                      fontWeight: 600 }} >
                      beta </Typography>
</ButtonBase>

                        <Box sx={{ flexGrow: 1 }} />
                        

                        <Box sx={{ m: 1 }}>
                          <UserButton/>
                        </Box>
                    </Box>



                </AppBar>


                <Box sx={{m:2, mt:2, mb:1}}>

<Typography variant="h6" align="left">You are sifting:</Typography>

</Box>
                      
                           
                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", gap:"1em",   m:2
                            // scrollbarWidth:"none", msOverflowStyle:"none", "&::-webkit-scrollbar": {display:"none" }
                    }}>
                            {[...ownedAccounts, ...subbedAccounts].map((account) => 

                                <>

                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width:"100%"}}>
                            

                            <Box sx={{ display: "flex", flexDirection: "column"}}>

                                        <Typography align="left" variant="body2">
                                            <b>{account.platform == "tiktok" ? "TikTok" : "TikTok"}</b>
                                        </Typography>
                                        <Typography align="left" variant="h6">
                                            <b>{account.username}</b>
                                        </Typography>
                                        <Typography align="left" variant="subtitle1">
                                            {account.status && account.status == "active" ? "Active 🟢" : "In Review 🟠"}
                                        </Typography>
                                  
                                            </Box>



                                    <Box sx={{flexGrow:1}}/>



                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <Typography align="right" variant="body2">
                                            <b>{account.subscribers.includes(user.emailAddresses[0].emailAddress) ? "Subscribed ✔️" : "Owned"}
                                            </b>
                                        </Typography>
                                    </Box>



                                </Box>

                            <Divider sx={{width:"100%"}}/>

                            </>

                            )}

                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-stretch", alignItems: "center", m: 2 }}>

                        <Typography variant="body1" align="left" fontWeight={700}>siftsy email</Typography>
                            <Box sx={{ flexGrow: 1 }} />
                        <Typography variant="body1" align="right">{user.emailAddresses[0].emailAddress}</Typography>

                        </Box>

                        {/* <Box sx={{ display: "flex", alignItems: "center", m: 2 }}>
                            {loading ? null :
                            <SiftModeSelector userData={userData}/>
                            }
                        </Box> */}



            </ThemeProvider>
        </div>

        
    )
}


function DynamicAvatar(props) {

    const { comment } = props;

    console.log("comment", comment);

    return (
 
            <Avatar sx={{ width: 25, height: 25, zIndex: 10 }} alt={comment.username} src={comment.avatarlink} /> 
    );
}



// const SiftModeSelector = (props) => {
//     const [preferredFilter, setPreferredFilter] = useState(0);

//     const { userData } = props;

//     const savePreferredFilter = async (value) => {
      
//         await Functions.updateUserSettings(userData.email, {...JSON.parse(userData.settings), preferredFilter: value});
//         setPreferredFilter(value);
//       }
  
//     React.useEffect(() => {

//     let filter = userData.settings ? (userData.settings).preferredFilter : 1;

//     setPreferredFilter(filter);
    
//     }, [userData]);
  
//     const handleFilterChange = (value) => {
//       savePreferredFilter(value);
//     };
  
//     return (
     
//             <Box display="flex" flexDirection="column">
//                 <Box>
//                   <Typography variant="subtitle1">
//                     Your Sift Mode
//                   </Typography>
//                   <Typography variant="subtitle2">
//                     siftsy emails you in this mode. 
//                   </Typography>
//                 </Box>
                
//                 <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-stretch", gap: "1em", p:2, cursor:"pointer", width:"100%"}}>
//                 {sifterOptions.map((sifter, index) => (
                
        
//                     <Typography color="primary" align="center"  variant={"subtitle2"} onClick={() => handleFilterChange(index)}
//                       sx={{borderRadius:30, background : preferredFilter == index ? "#4828C5" : "#ffffff", color: preferredFilter == index ? "#fff" : "#4828C5", padding:"4px 10px 4px 10px", border:"2px solid #4828C5",
//                         fontWeight: preferredFilter == index ? 900 : 700 }} >
                        
//                       {sifter.label}
        
//                     </Typography>
        
                
//                 ))}
//                 </Box>
//               </Box>
        
     
//     );
//   };
  
