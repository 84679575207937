import React from 'react';
import { Box, Typography } from '@mui/material';

const version = process.env.REACT_APP_VERSION;

const Footer = () => {

    return (
      <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"flex-stretch", background: "transparent", p: 3}}>
        <Typography fontSize={14} variant="caption" align="center" color="#999">
          {`© ${new Date().getFullYear()
          } Siftsy`}
           
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography fontSize={14} fontWeight={700} variant="caption" align="center" color="#999">
         {`v${version}`}
           
        </Typography>
      </Box>
    );
  }

export default Footer;