import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
  } from "@mui/material";
  import React, { useCallback, useState } from "react";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import {
    Add,
    AutoAwesome,
    BarChart,
    Chat,
    CheckBoxOutlined,
    Close,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FolderOutlined,
    GroupOutlined,
    OpenInNew,
    VideoFileOutlined,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";

  import Functions from "./Functions";
import GroupView from "./GroupView";
import { formatData, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
import VibeCheckSection from "./VibeCheckSection";
import ContentMetrics from "./ContentMetrics";
import html2canvas from "html2canvas";


export default function ExportCommentsDialog(props){
    const { open, onClose, selectedPostData, teamData, refreshData, selectedGroups } = props;
    const metricsPanelRef = React.useRef(null);
    const [exporting, setExporting] = React.useState(false);

    const handleExportMetrics = async () => {

        //delay .5s
        setExporting(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        
    
        if (metricsPanelRef.current) {
        
            // deep copy metricsRef.current element
            let element = metricsPanelRef.current.cloneNode(true);
         
            document.body.appendChild(element);
            element.style.x = -19999;
            element.style.y = -19999;
            element.style.transform = "scale(4)";
    
            let canvas = await html2canvas(element, {useCORS:true, allowTaint:true, logging:true, backgroundColor: null
            });
          
              const link = document.createElement("a");
              link.href = canvas.toDataURL();
              link.download = `siftsy_metrics.png`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);  
              
              document.body.removeChild(element);
          
        } 
    
        setExporting(false);
    
      }

       
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={GlassMorphicPaperComponent}
        disableEnforceFocus
      >
                <Grid container spacing={2} sx={{ background:"#fff", m: 2, p:2, width:1000, borderRadius:3}} ref={metricsPanelRef}>
              <Grid item xs={4}>
            <ContentMetrics 
            
            
            screenshot selectedPostData={selectedPostData} teamData={teamData} refreshData={refreshData}/>
            </Grid>
            <Grid item xs={8}>

            {selectedGroups && selectedGroups.length > 0 ? selectedGroups.map((group, index) => (
            <Chip
                                  key={index}
                                  sx={{
                                    backgroundColor: "#fff",
                                    border: "2px solid #ddd",
                                  }}
                                  label={
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#666",
                                        fontWeight: 700,
                                        fontSize: 16,
                                      }}
                                    >
                                      {group.name}
                                    </Typography>
                                  }
                            
                                />


            )) : null}

            <VibeCheckSection 
              screenshot
              selectedPostData={selectedPostData}
            /> 
              </Grid>
                </Grid>
    <DialogActions>

    <Button onClick={handleExportMetrics} color="primary" variant="contained">
        Export
        </Button>
    </DialogActions>

      </Dialog>
    );
  };

  const GlassMorphicPaperComponent = React.forwardRef(
    function GlassMorphicPaperComponent(props, ref) {
      return (
        <Paper
          ref={ref}
          elevation={0}
          sx={{
            borderRadius: 8,
            background: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(12px)",
            // border: "1px solid rgba(255, 255, 255, 0.18)",
            // boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
            padding: "1em",
            margin: ".5em",
          }}
        >
          {props.children}
        </Paper>
      );
    }
  );