import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import "./Comment.css";
import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  IconButton,
  Skeleton,
} from "@mui/material";
import {
  Comment,
  CommentOutlined,
  ContentCopyRounded,
  CopyAll,
  Download,
  DragHandleOutlined,
  FavoriteBorder,
  OpenInNewOutlined,
  Reply,
  Send,
  SendToMobile,
} from "@mui/icons-material";

import { formatNumber } from "./Helpers";
// import { isMobile } from "react-device-detect";

const TikTokComment = React.memo((props) => {
  const {
    comment,
    selected,
    selectMode,
    selectOnHover,
    setSelectMode,
    handleCommentSelection,
    dragMode,
    previewMode,
    screenShot,
    selectable,
    goToPostComment,
    exportIndividualComment,
    setCopied,
    platform,
    isMobile,
    showAsReply
  } = props;

  const [bitmapData, setBitmapData] =  React.useState(null);
  const [exporting, setExporting] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const copyToClipboard = (comment) => {
    // take selected comment text and copy to clipboard
    setCopied(true);

    let text = `@${comment.username.replace("@", "")}: ${comment.text}`;
    // Copy data to clipboard
    navigator.clipboard
      .writeText(text)
      .catch((error) => console.error("Unable to copy:", error));
  };

  const handleImageExport = async () => {
    setExporting(true); 
    await exportIndividualComment(comment); 
    setExporting(false);
  }

  const fetchAndConvertToBitmap = async(imageUrl) => {
    if (!imageUrl) {
      console.log('Please enter a valid image URL.');
      return;
    }

    const cacheBuster = `?t=${Date.now()}`;
    const urlWithCacheBuster = `${imageUrl}${cacheBuster}`;
  
  
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Allow cross-origin images
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);
      // Get the image data in bitmap format
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const bitmapData = imageData.data;
      // Convert bitmap data to base64
      const bitmapToBase64 = (bitmapData, width, height) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(width, height);
        
        // Fill the ImageData with the bitmap data
        for (let i = 0; i < bitmapData.length; i++) {
          imageData.data[i] = bitmapData[i];
        }
      
        // Set the ImageData on the canvas
        ctx.putImageData(imageData, 0, 0);
      
        // Convert canvas to base64
        return canvas.toDataURL('image/bmp');
      };
      
      
      // Inside fetchAndConvertToBitmap function:
      const base64Bitmap = bitmapToBase64(bitmapData, img.width, img.height);
      if (base64Bitmap == null || base64Bitmap == "") {
        console.log('Failed to convert bitmap to base64.');
        return;
      }
      setBitmapData(base64Bitmap);
    };

    img.src = urlWithCacheBuster;
  };

  React.useEffect(() => {
    if (comment && comment.userAvatar && screenShot) { 
      fetchAndConvertToBitmap(comment.userAvatar);
    }
  }, []);

  const showHover = () => {
    if (selectOnHover) {
      setHovered(true);
    }
  };

  const hideHover = () => {
    if (selectOnHover) {
      setHovered(false);
    }
  };

  const CircularAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: "50%",
    height: 40,
    width: 40,
    marginRight: 14,
    background: "#ccc"
  }));
  
  let username = comment ? platform == "x" && 
  comment.creatorName ? comment.creatorName : (comment.username ? comment.username : "Unknown User") : "Unknown User";
  
  return comment ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        pt: 3,
        pb: 3,
        cursor: "pointer",
        position: "relative",
        pr:2,
      }}
      onMouseEnter={showHover}
      onMouseLeave={hideHover}
      // onClick={showSelect}
    >
      {hovered ? (
        <Box sx={{ position: "absolute", right: 0, top: 10 }}>
          <ButtonGroup
            size="small"
            sx={{
              background: "#f7f7f7",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "5px 10px",
              gap: ".5rem",
              borderRadius: 30,
            }}
          >
            {exporting ? (
              <Box
                sx={{
                  height: 22,
                  width: 22,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={16} />
              </Box>
            ) : (
              <Tooltip title="Download Image" placement="top">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleImageExport}
                >
                  <Download sx={{ height: 20, width: 20 }} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Copy Text" placement="top">
              <IconButton
                color="primary"
                size="small"
                onClick={() => copyToClipboard(comment)}
              >
                <ContentCopyRounded sx={{ height: 20, width: 20 }} />
              </IconButton>
            </Tooltip>
            {goToPostComment ? (
              <Tooltip title="Go To Post" placement="top">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => goToPostComment(comment)}
                >
                  <OpenInNewOutlined sx={{ height: 20, width: 20 }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </ButtonGroup>
        </Box>
      ) : null}

      {selectMode || hovered || selected ? (
        <Box sx={{ width: "4rem", height: "3rem", display:"flex", alignItems:"center" }}>
          <Checkbox
            color="primary"
            size="small"
            checked={selected}
            onChange={handleCommentSelection}
          />
        </Box>
      ) : dragMode ? (
        <Box sx={{ width: "4rem", height: "3rem", display:"flex", alignItems:"center" }}>
          <DragHandleOutlined sx={{ fontSize: isMobile ? 18 : 25 }} />
        </Box>
      ) : selectable ? (
        <Box sx={{ width: isMobile ? 0 : "4rem", height: "3rem", display:"flex", alignItems:"center" }}/>
      ) : null}


{(showAsReply) ? (
        <Box sx={{ width: "3rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
        </Box>
) : null}



      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "top",
          width: "100%",
        }}
      >
       
       <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "top",
              mt: 1,
            }}

          > 
          {bitmapData  ? <CircularAvatar src={bitmapData} /> : <CircularAvatar src={comment.userAvatar} />}

          </Box>
       
       

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 0.3,
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              variant="h5"
              align="left"
              style={{
                cursor: "pointer",
                fontSize: isMobile && !screenShot ? 14 : 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: isMobile ? 180 : 250,
              }}
              // onClick={
              //   comment.userlink && !dragMode
              //     ? () =>
              //         window.open(
              //           "https://tiktok.com" + comment.userlink,
              //           "_blank"
              //         )
              //     : null
              // }
            >
              {username} 
            </Typography>

            {comment.isUserVerified || comment.verifiedUser ? (
              <Box sx={{ display: "flex", alignItems: "center", ml: -0.5 }}>
                <img
                  src="/user_verified.png"
                  alt="verified user"
                  style={{ height: 16, width: 16 }}
                />
              </Box>
            ) : null}
            <Typography
              variant="h5"
              align="left"
              style={{
                cursor: "pointer",
                fontSize: isMobile && !screenShot ? 14 : 18,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "#777",
                fontWeight: 500,
                fontSize: 16,
                maxWidth: isMobile ? 180 : 250,
              }}
              // onClick={
              //   comment.userlink && !dragMode
              //     ? () =>
              //         window.open(
              //           "https://tiktok.com" + comment.userlink,
              //           "_blank"
              //         )
              //     : null
              // }
            >
              {platform == "x" && comment.username}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordSpacing: "normal",

              }}
            >
              <Typography
                variant="body1"
                align="left"
                component="p"
                sx={{ fontSize: isMobile ? 14 : 16 }}
              >
                {comment.text.split(" ").map((word, index) => {
                  if (word.startsWith("@")) {
                    // Wrap username in <strong> tag to make it bold
                    return (
                      <span key={index} style={{ color: "#4728c4" }}>
                        {word}{" "}
                      </span>
                    );
                  } else {
                    return word + " ";
                  }
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
              <Box sx={{ flexGrow: 1 }} />

              {comment.engagement &&
              comment.engagement.likes &&
              !isNaN(comment.engagement.likes) &&
              isFinite(comment.engagement.likes) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    ml: 2,
                  }}
                >
                  <FavoriteBorder
                    style={{ color: "#999", fontSize: isMobile ? 18 : 22 }}
                  />
                  <Typography variant="caption" color="textSecondary">
                    {formatNumber(comment.engagement.likes)}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ m: 3, width: 20 }} />
              )}
      </Box>
    </Box>
  ) : null;
});

export default TikTokComment;

