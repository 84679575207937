import {
  Box,
  Grid,
  Typography,
  Slider,
  Card,
  createTheme,
  ThemeProvider,
  ButtonBase,
  AppBar,
  Divider,
  Skeleton,
  Tabs,
  Tab,
  CardContent,
  AvatarGroup,
  Avatar,
  Button,
  Popper,
  ClickAwayListener,
  Paper,
  MenuItem,
  IconButton,
  MenuList,
  FormControl,
  Select,
  Chip,
  LinearProgress,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  AutoAwesome,
  ExpandLess,
  ExpandMore,
  IndeterminateCheckBoxSharp,
  Info,
  InfoOutlined,
  InfoRounded,
  LocationCity,
  Navigation,
  NavigationOutlined,
  OpenInFull,
  Person,
  PersonOutline,
  PersonPinCircle,
  PersonTwoTone,
  Pin,
  PinDrop,
  PinRounded,
  PinSharp,
  Room,
  Settings,
  ThumbDown,
  ThumbUp,
  Tune,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import axios from "axios";
import TikTokComment from "./TikTokComment";
import { siftsyTheme } from "./siftsytheme";
import { useParams } from "react-router-dom";
import SiftPreview from "./SiftPreview";
import { useUser, UserButton } from "@clerk/clerk-react";
import ProfileSettingsButton from "./ProfileSettingsButton";
import { sifterOptions } from "./constants";
import ReactGA from "react-ga4";
import Functions from "./Functions";
import { Grow } from "@mui/material";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const darkMode = false;
const theme = createTheme(siftsyTheme);

const StyledButton = muiStyled(ButtonBase)(({ theme }) => ({
  borderRadius: 30,
  background: `${theme.palette.primary.main}`,
  fontFamily: ["Gilroy", "sans-serif"].join(","),
  fontSize: "1rem",
  fontWeight: 900,
  // letterSpacing:".5px",
  color: theme.palette.secondary.main,
  padding: "15px 0px",
  width: "100%",
  boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.4)",
}));

const StyledCallout = muiStyled(Card)(({ theme }) => ({
  borderRadius: 30,
  background: `${theme.palette.primary.main}`,
  fontFamily: ["Gilroy", "sans-serif"].join(","),
  fontSize: "1rem",
  fontWeight: 900,
  color: theme.palette.secondary.main,
  padding: "15px 0px",
  width: "fit-content",
  elevation: 0,
}));

export default function BrandSiftPage(props) {
  const { isLoaded, isSignedIn, user } = useUser();

  const { videoId, creatorName } = useParams();

  const [sifted, setSifted] = React.useState(false);
  const [filter, setFilter] = React.useState(1);
  const [preferredFilter, setPreferredFilter] = React.useState(1);
  const [sift, setSift] = React.useState(false);
  const [siftData, setSiftData] = React.useState(null);
  const [posterSrc, setPosterSrc] = React.useState("");
  const [totalComments, setTotalComments] = React.useState(null);
  const [commentData, setCommentData] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
  const [userData, setUserData] = React.useState(null);
  const [empty, setEmpty] = React.useState(false);

  console.log("userData", userData);

  window.addEventListener("resize", () => {
    setPageWidth(window.innerWidth);
  });

  const savePreferredFilter = async (value) => {
    await Functions.updateUserSettings(userData.email, {
      ...JSON.parse(userData.settings),
      preferredFilter: value,
    });
    setPreferredFilter(value);
  };

  const handleSelectedFilter = async (index) => {
    setSifted(false);
    setSelectedGroup(null);
    setFilter(index);

    setTimeout(() => {
      setSifted(true);
    }, 100);
  };

  React.useEffect(() => {
    async function fetchData() {
      console.log("videoId", videoId);

      const res = await Functions.fetchSift(videoId, "tiktok");

      if (!res) {
        setSifted(false);
        setEmpty(true);
        return;
      } else {
        console.log("res", res);

        setSift(res.fetchedSift);
        setSiftData(res.fetchedData);
        setCommentData(res.fetchedCommentData);
        setTotalComments(res.totalComments);

        setPosterSrc(
          `https://siftsy-post-images.s3.amazonaws.com/tiktok/${videoId}.jpg`
        );

        console.log("user", user);

        let userEmail = user.emailAddresses[0].emailAddress;

        let loadedData = await Functions.fetchUser(userEmail);

        setUserData(loadedData);

        let preferredFilter = loadedData.settings
          ? JSON.parse(loadedData.settings).preferredFilter
          : 1;

        setPreferredFilter(preferredFilter);
        setFilter(preferredFilter);

        setSifted(true);
        setLoading(false);

        ReactGA.event({
          category: "User Interaction",
          action: "sift_view",
          label: loadedData.id,
        });
      }
    }

    if (videoId) {
      fetchData();
    }
  }, []);

  const highlightSection = React.useRef(null);
  const commentSection = React.useRef(null);

  return (
    <div
      id="popup"
      style={{
        margin: "0 auto",
        width: pageWidth > 450 ? 450 : pageWidth,
        height: "auto",
        overflowX: "hidden",
      }}
    >
      <ThemeProvider theme={theme}>
        <AppBar
          position="static"
          elevation={0}
          sx={{ background: "transparent" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-stretch",
              alignItems: "center",
              m: 1,
            }}
          >
            <ButtonBase onClick={() => window.open(`/`, "_blank")}>
              <img
                src={"/siftsy_logo.png"}
                alt="logo"
                style={{ width: 80, height: "auto", margin: "5px" }}
              />
              <Typography
                color="primary"
                align="center"
                variant={"subtitle2"}
                sx={{
                  borderRadius: 30,
                  background: "rgba(238,234,255, .8)",
                  color: "#4828C5",
                  padding: "1px 12px 1px 12px",
                  ml: -1,
                  fontWeight: 600,
                }}
              >
                beta{" "}
              </Typography>
            </ButtonBase>

            <Box sx={{ flexGrow: 1 }} />

            <Box>
              <ProfileSettingsButton
                preferredFilter={preferredFilter}
                savePreferredFilter={savePreferredFilter}
              />
            </Box>

            <Box sx={{ m: 1 }}>
              <UserButton />
            </Box>
          </Box>
        </AppBar>

        {!empty && videoId && videoId !== "" ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {siftData ? (
                <Box sx={{ m: 1 }}>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="primary"
                  >
                    {siftData.createdAt
                      ? `${
                          siftData.totalComments
                            ? siftData.totalComments + " comments sifted "
                            : "Sifted "
                        }${timeAgo(siftData.createdAt)}`
                      : ""}
                  </Typography>
                </Box>
              ) : null}

              {siftData &&
              siftData.postSummary &&
              siftData.postSummary.trim() != "" ? (
                <Box
                  sx={{
                    ml: 3,
                    mr: 3,
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    // background:"#ff0000"
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      width: 120,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: 120,
                        height: 170,
                        borderRadius: "12px",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          `https://www.tiktok.com/${siftData.username}/video/${videoId}`,
                          "_blank"
                        )
                      }
                    >
                      <img
                        src={posterSrc}
                        alt="poster"
                        id="thumbnail"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: -20,
                          width: 120,
                          height:
                            (120 *
                              16/9)
                           
                        }}
                      />

              
                    </div>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 1,
                      ml: 2,
                      width: 200,
                      minHeight: 170,
                    }}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      fontSize={14}
                      fontWeight={700}
                      sx={{ ml: 2 }}
                    >
                      About This Post
                    </Typography>

                    <Card
                      sx={{ borderRadius: "12px", background: "#EEEAFF", p: 2, minHeight:107 }}
                      elevation={0}
                    >
                      <Typography
                        variant="body2"
                        fontSize={12}
                        lineHeight={1.5}
                        align="left"
                      >
                        {siftData.postSummary}
                      </Typography>
                    </Card>
                  </Box>
                </Box>
              ) : (
                <ButtonBase
                  onClick={() =>
                    window.open(
                      `https://www.tiktok.com/${siftData.username}/video/${videoId}`,
                      "_blank"
                    )
                  }
                >
                  <div
                    style={{
                      position: "relative",
                      width: 250,
                      height: 140,
                      borderRadius: "12px",
                      overflow: "hidden",
                    }}
                  >
                    {posterSrc != "" ? (
                      <img
                        src={posterSrc}
                        alt="poster"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: 250,
                          height: 140,
                        }}
                      />
                    ) : (
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={250}
                        height={140}
                        sx={{ borderRadius: "12px" }}
                      />
                    )}

                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 250,
                        height: 140,
                        overflow: "hidden",
                        borderRadius: "12px",
                        background: "transparent",
                        backdropFilter: "blur(10px)",
                        WebkitBackdropFilter: "blur(10px)",
                      }}
                    />

                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left:
                          250 / 2 -
                          (document.getElementById("thumbnail")
                            ? document.getElementById("thumbnail").clientWidth /
                              2
                            : 40),
                        width: "fit-content",
                        height: 140,
                        overflow: "hidden",
                        borderRadius: "12px",
                      }}
                    >
                      {
                        posterSrc != "" ? (
                          <img
                            id="thumbnail"
                            src={posterSrc}
                            alt="poster"
                            style={{ width: "auto", height: 140 }}
                          />
                        ) : null
                        //  <Skeleton animation="wave" variant="rectangular" width={80} height={150} />
                      }
                    </div>
                  </div>
                </ButtonBase>
              )}
            </Box>

            {sifted && siftData && siftData.vibeCheck ? (
              <VibeCheckSection vibeCheck={JSON.parse(siftData.vibeCheck)} />
            ) : null}

            {sifted && siftData && siftData.brandCheck ? (
              <BrandCheckSection vibeCheck={JSON.parse(siftData.vibeCheck)} />
            ) : null}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "top",
                pr: 2,
                pl: 2,
                pt: 1,
              }}
            >
              {/*         
        <Tabs value={tabValue} onChange={usernameTabChange} variant="fullWidth" centered TabIndicatorProps={{ style: { display: 'none' } }}>
            <Tab label="Summary" color="primary" />
            <Tab color="primary" label={`Top Comments ${sifted && sift[Object.keys(sift)[filter]] && sift[Object.keys(sift)[filter]].comments ? `(${sift[Object.keys(sift)[filter]].comments.length})` : ""}`} />
        </Tabs> */}

              <Box sx={{ p: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-stretch",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {sifted &&
                  sift[Object.keys(sift)[filter]] &&
                  sift[Object.keys(sift)[filter]].summary &&
                  sift[Object.keys(sift)[filter]].comments.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Tooltip title="The summary is a short description of what was discussed in the comments section, filtered by sentiment.">
                        <Typography variant="h6" align="left">
                          Summary{" "}
                        </Typography>
                      </Tooltip>
                      <AutoAwesome
                        color="primary"
                        sx={{ mt: -1.5, height: 12, width: 12 }}
                      />
                    </Box>
                  ) : null}

                  <Box sx={{ flexGrow: 1 }} />
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={25}
                      sx={{ borderRadius: 5 }}
                    />
                  ) : (
                    <FilterSelectorButton
                      filter={filter}
                      setFilter={handleSelectedFilter}
                    />
                  )}
                </Box>

                {sifted &&
                sift[Object.keys(sift)[filter]] &&
                sift[Object.keys(sift)[filter]].summary &&
                sift[Object.keys(sift)[filter]].comments.length > 0 ? (
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: "12px",
                      background: "#EEEAFF",
                      p: 3,
                      mt: 1,
                    }}
                  >
                    <Typography variant="body1" align="left">
                      <MainSummary
                        text={
                          Object.keys(sift).length > 0
                            ? sift[Object.keys(sift)[filter]].summary
                            : ""
                        }
                      />
                    </Typography>
                  </Card>
                ) : null}
              </Box>

              {sifted &&
              sift[Object.keys(sift)[filter]] &&
              sift[Object.keys(sift)[filter]].comments.length == 0 ? (
                <Box sx={{ p: 5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {filter == 0 ? (
                      <Typography variant="h6" align="center">
                        Tough crowd!
                        <br />
                        No cheerleaders here.
                      </Typography>
                    ) : filter == 1 ? (
                      <Typography variant="h6" align="center">
                        No comments here!
                        <br />
                        Give your audience a chance to speak.
                      </Typography>
                    ) : (
                      <Typography variant="h6" align="center">
                        It's all love in your comments!
                        <br />
                        No haters here.
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {filter == 0 ? (
                      <img src="/frown_animation.gif" width="250px" />
                    ) : filter == 1 ? (
                      <Box sx={{ flexGrow: 1 }} />
                    ) : (
                      <img src="/confetti_animation.gif" width="250px" />
                    )}
                  </Box>
                </Box>
              ) : null}

              {sifted &&
              sift[Object.keys(sift)[filter]] &&
              sift[Object.keys(sift)[filter]].highlights &&
              Array.isArray(sift[Object.keys(sift)[filter]].highlights) &&
              sift[Object.keys(sift)[filter]].comments.length > 0 ? (
                <Box sx={{ p: 1 }} ref={highlightSection}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                      mt: 1
                    }}
                  >
                    <Typography variant="h6" align="left">
                      Highlights{" "}
                    </Typography>
                    {/* <AutoAwesome
                      color="primary"
                      sx={{ mt: -1.5, height: 12, width: 12 }}
                    /> */}
                  </Box>

                  {Object.keys(sift).length > 0 ? (
                    <Grid
                      container
                      spacing={1}
                      alignItems="left"
                      sx={{ mt: 1 }}
                    >
                      {sift[Object.keys(sift)[filter]].highlights.map(
                        (highlight) => (
                          <HighlightCard
                            highlight={highlight}
                            highlightSection={highlightSection}
                          />
                        )
                      )}
                    </Grid>
                  ) : null}
                </Box>
              ) : null}

              {sifted &&
              sift[Object.keys(sift)[filter]] &&
              sift[Object.keys(sift)[filter]].comments &&
              Array.isArray(sift[Object.keys(sift)[filter]].comments) &&
              sift[Object.keys(sift)[filter]].comments.length > 0 ? (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Typography variant="h6" align="left">
                      Selected Comments{" "}
                    </Typography>
                    {/* <AutoAwesome
                      color="primary"
                      sx={{ mt: -1.5, height: 12, width: 12 }}
                    /> */}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      mt: 1,
                      //  maxHeight:"400px", overflowX:"hidden", overflowY:"scroll", border:"2px solid #EEE", borderRadius:"12px",
                    }}
                    ref={commentSection}
                  >
                    <Grid
                      container
                      spacing={1}
                      alignItems="left"
                      sx={{ mb: 1, m: 1 }}
                    >
                      {sift[Object.keys(sift)[filter]].groupings &&
                      typeof sift[Object.keys(sift)[filter]].groupings ===
                        "object" &&
                      !Array.isArray(sift[Object.keys(sift)[filter]].groupings)
                        ? Object.keys(
                            sift[Object.keys(sift)[filter]].groupings
                          ).map((phrase, index) => (
                            <CommentGrouping
                              groupPhrase={phrase}
                              commentGroup={
                                sift[Object.keys(sift)[filter]].groupings[
                                  phrase
                                ]
                              }
                              commentSection={commentSection}
                              commentData={commentData}
                              selectedGroup={selectedGroup}
                              setSelectedGroup={setSelectedGroup}
                            />
                          ))
                        : null}
                    </Grid>

                    {(selectedGroup == null &&
                    sift[Object.keys(sift)[filter]].comments &&
                    Array.isArray(sift[Object.keys(sift)[filter]].comments)
                      ? sift[Object.keys(sift)[filter]].comments
                      : sift[Object.keys(sift)[filter]].groupings &&
                        typeof sift[Object.keys(sift)[filter]].groupings ===
                          "object" &&
                        !Array.isArray(
                          sift[Object.keys(sift)[filter]].groupings
                        )
                      ? sift[Object.keys(sift)[filter]].groupings[selectedGroup]
                      : []
                    ).map((highlighted) => (
                      <Box sx={{ m: 2 }}>
                        <TikTokComment
                          comment={commentData.find(
                            (comment) => comment.commentId === highlighted
                          )}
                        />

                        <Divider variant="middle" />
                      </Box>
                    ))}

                    <Box sx={{ m: 2 }}>
                      {sifted && siftData ? (
                        <Box sx={{ m: 1 }}>
                          <Typography
                            variant="subtitle1"
                            align="center"
                            color="primary"
                          >
                            {siftData.createdAt
                              ? `${
                                  siftData.totalComments
                                    ? siftData.totalComments +
                                      " comments sifted "
                                    : "Sifted "
                                }${timeAgo(siftData.createdAt)}`
                              : ""}
                          </Typography>
                        </Box>
                      ) : null}

                      {sifted && siftData ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            m: 2,
                          }}
                        >
                          <Box>
                            <AutoAwesome
                              color="primary"
                              sx={{ m: 1, height: 18, width: 18 }}
                            />
                          </Box>

                          <Typography
                            variant="caption"
                            align="left"
                            color="primary"
                            sx={{ fontStyle: "italic" }}
                          >
                            AI generated from your comments section
                          </Typography>
                        </Box>
                      ) : null}

                      {/* {totalComments < 50 ?

  <Box sx={{ m: 1 }}>
      <Typography variant="subtitle1" align="center">
          {`This video needs at least 50 comments to sift. There are currently ${totalComments} comments.`}
      </Typography>
  </Box>
  : null} */}
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <Typography variant="h6" align="center">
              {" "}
              No Sift Available.
            </Typography>

            <Card
              elevation={0}
              sx={{ borderRadius: "12px", background: "#EEEAFF", p: 3, m: 2 }}
            >
              <Typography variant="body1">
                Go to another video page to sift a video.
              </Typography>
            </Card>
          </Box>
        )}
      </ThemeProvider>

      {/* 

          {sifted ? 

          <SiftPreview siftData={siftData} /> : null} */}
    </div>
  );
}

function MainSummary(props) {
  // Use a regular expression to find sentence-ending punctuation and add a line break
  const stringWithLineBreaks = props.text.replace(/([.!?])\s/g, "$1\n\n");

  const [more, setMore] = React.useState(false);

  React.useEffect(() => {
    setMore(false);
  }, [props.text]);

  return (
    <>
      {stringWithLineBreaks.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          <span
            style={{
              display: index == 0 || more ? "inline" : "none",

              // fontSize: index == 0 ? "1.4rem" : null
            }}
          >
            {line}
            {index == 0 && !more ? (
              <span
                style={{
                  display:
                    stringWithLineBreaks.split("\n").length > 1
                      ? "inline"
                      : "none",
                }}
                onClick={() => setMore(!more)}
              >
                ..{" "}
                <span
                  style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}
                >
                  more
                </span>
              </span>
            ) : (
              " "
            )}
            <br />
          </span>
        </React.Fragment>
      ))}

      {more ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <span onClick={() => setMore(!more)}>
            <br />
            <span style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}>
              less
            </span>
          </span>
        </Box>
      ) : null}
    </>
  );
}

const CustomSlider = muiStyled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    height: 34,
    width: 34,
    backgroundColor: "transparent",
    // border: `3px solid ${theme.palette.primary.main}`,
    // marginTop: -8,
    // marginLeft: -12,
    // '&:focus, &:hover, &$active': {
    //   boxShadow: 'inherit',
    // },
  },

  "& .MuiSlider-valueLabel": {
    left: "calc(-50% + 6px)",
    background: "transparent",
  },

  // '& .MuiSlider-track': {
  //   height: 5,
  //   borderRadius: 5,
  // },

  "& .MuiSlider-rail": {
    height: 25,
    borderRadius: 30,
    opacity: 1,
    background: "linear-gradient(to right, #B7FF70, #FFEC3E, #FF7A7A)",
  },
}));

function timeAgo(isoString) {
  const date = new Date(isoString).getTime();
  const now = new Date().getTime();
  const timeDifference = now - date;

  if (timeDifference < 60000) {
    // Less than a minute
    //   const seconds = Math.floor(timeDifference / 1000);
    //   return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    return "just now";
  } else if (timeDifference < 3600000) {
    // Less than an hour
    const minutes = Math.floor(timeDifference / 60000);
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (timeDifference < 86400000) {
    // Less than a day
    const hours = Math.floor(timeDifference / 3600000);
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else {
    const days = Math.floor(timeDifference / 86400000);
    return `${days} day${days === 1 ? "" : "s"} ago`;
  }
}

const CardContainer = muiStyled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
});

const HighlightCard = ({ highlight, highlightSection }) => {
  const ref = React.useRef(null);
  const [cardWidth, setCardWidth] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setWidth(highlightSection.current.getBoundingClientRect().width);

    if (ref.current) {
      setCardWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref]);

  console.log("cardWidth", cardWidth);
  console.log("width", width);
  console.log("cardWidth * 12/width", Math.ceil((cardWidth * 12) / width));

  return (
    <Grid item xs={Math.ceil((cardWidth * 12) / width) + 0.4}>
      <Card
        ref={ref}
        elevation={0}
        sx={{ borderRadius: "12px", background: "#EEEAFF", p: 1 }}
      >
        <Typography align="center" variant="body1" sx={{ fontSize: ".8rem" }}>
          {highlight.trim()}
        </Typography>
      </Card>
    </Grid>
  );
};

const CommentGrouping = ({
  groupPhrase,
  commentGroup,
  commentSection,
  commentData,
  selectedGroup,
  setSelectedGroup,
}) => {
  const ref = React.useRef(null);
  const [cardWidth, setCardWidth] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setWidth(commentSection.current.getBoundingClientRect().width);

    if (ref.current) {
      setCardWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref]);

  console.log("cardWidth", cardWidth);
  console.log("width", width);
  console.log("cardWidth * 12/width", Math.ceil((cardWidth * 12) / width));

  let selected = selectedGroup === groupPhrase;

  let maxAvatars = 3;

  return (
    <Grid item xs={Math.ceil((cardWidth * 12) / width) + 1}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <ButtonBase
          onClick={() => {
            if (selected) {
              setSelectedGroup(null);
            } else {
              setSelectedGroup(groupPhrase);
            }
          }}
          sx={{ borderRadius: 30 }}
        >
          <Card
            elevation={0}
            sx={{
              borderRadius: 30,
              background: selected ? "#4828C5" : "#ffffff",
              color: selected ? "#fff" : "#4828C5",
              p: 0.5,
              border: "2px solid #eeeeee",
            }}
            ref={ref}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                p: 0.5,
              }}
            >
              <AvatarGroup
                max={maxAvatars}
                total={
                  commentGroup.length > maxAvatars
                    ? maxAvatars
                    : commentGroup.length
                }
                sx={{
                  "& .MuiAvatar-root": {
                    width: 25,
                    height: 25,
                    fontSize: 10,
                    background: "#F3F2FF",
                    color: "#4828C5",
                  },
                }}
              >
                {commentGroup
                  .slice(
                    0,
                    commentGroup.length > maxAvatars
                      ? maxAvatars
                      : commentGroup.length
                  )
                  .map((commentId) => (
                    <DynamicAvatar
                      comment={commentData.find(
                        (comment) => comment.commentId === commentId
                      )}
                    />
                  ))}
              </AvatarGroup>

              {commentGroup.length > 3 ? (
                <Avatar
                  sx={{
                    marginLeft: "-5px",
                    zIndex: 1,
                    width: 25,
                    height: 25,
                    fontSize: 10,
                    background: "#F3F2FF",
                    color: "#4828C5",
                  }}
                >
                  <b> + {commentGroup.length - maxAvatars} </b>
                </Avatar>
              ) : null}

              <Typography
                align="center"
                variant="body1"
                sx={{
                  ml: 1,
                  color: selected ? "#fff" : "#4828C5",
                  fontSize: ".8rem",
                  fontStyle: "italic",
                }}
              >
                "{groupPhrase.trim()}..."
              </Typography>
            </Box>
          </Card>
        </ButtonBase>
      </Box>
    </Grid>
  );
};

function DynamicAvatar(props) {
  const { comment } = props;

  console.log("comment", comment);

  return (
    <Avatar
      sx={{ width: 25, height: 25, zIndex: 10 }}
      alt={comment.username}
      src={comment.avatarlink}
    />
  );
}

const FilterSelectorButton = (props) => {
  const { filter, setFilter } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  return (
    <div>
      <ButtonBase onClick={handleOpen}>
        <Box  sx={{
          display:"flex",
          flexDirection:"row",
          justifyContent:"center",
          alignItems:"center",
          gap:".5em",
            borderRadius: 30,
            background: "#4828C5",
            color: "#fff",
            padding: "4px 10px 4px 10px",
            border: "2px solid #4828C5",
            fontWeight: 900,
          }}>
        <Typography
            color="white"
          align="center"
          variant={"subtitle2"}
         
        >
          {sifterOptions[filter].label}
        </Typography>

        <Tune />
        </Box>

        {/* <Grid container spacing={2} alignItems="center">
        {sifterOptions.map((sifter, index) => (
          <Grid item xs={4}>

            <Typography color="primary" align="center" onClick={() => setFilter(index)} variant={"subtitle2"}
              sx={{borderRadius:30, background : filter == index ? "#4828C5" : "#ffffff", color: filter == index ? "#fff" : "#4828C5", padding:"6px 0px 6px 0px", border:"2px solid #4828C5",
                fontWeight: filter == index ? 900 : 700 }} >
                
              {sifter.label}

            </Typography>

          </Grid>
        ))}
      </Grid> */}
      </ButtonBase>
      <Popper open={open} anchorEl={anchorEl} placement="top-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <Box display="flex" flexDirection="column" p={2}>
              <Box sx={{ alignItems: "center" }}>
                <Typography variant="subtitle1">Change Filter</Typography>
                <Typography variant="subtitle2">
                  Filter your comments by sentiment.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1em",
                  pt: 2,
                  cursor: "pointer",
                }}
              >
                {sifterOptions.map((sifter, index) => (
                  <Grid item xs={4}>
                    <Typography
                      color="primary"
                      align="center"
                      variant={"subtitle2"}
                      onClick={() => handleFilterChange(index)}
                      sx={{
                        borderRadius: 30,
                        background: filter == index ? "#4828C5" : "#ffffff",
                        color: filter == index ? "#fff" : "#4828C5",
                        padding: "4px 10px 4px 10px",
                        border: "2px solid #4828C5",
                        fontWeight: filter == index ? 900 : 700,
                      }}
                    >
                      {sifter.label}
                    </Typography>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};


function BrandCheckSection(props) {
  const { vibeCheck } = props;
  const [hidden, setHidden] = React.useState(true);

  console.log("vibeCheck", vibeCheck);

  const vibeScore = vibeCheck.vibeScore ? parseFloat(vibeCheck.vibeScore) : 0;
  const sentimentScore = vibeCheck.sentimentData.sentimentScore
    ? parseFloat(vibeCheck.sentimentData.sentimentScore).toFixed(1)
    : 0;
  const relevanceScore = vibeCheck.relevanceData.relevanceScore
    ? parseFloat(vibeCheck.relevanceData.relevanceScore).toFixed(1)
    : 0;
  const consensusScore = vibeCheck.consensusData.consensusScore
    ? parseFloat(vibeCheck.consensusData.consensusScore).toFixed(1)
    : 0;

  const vibeColor = vibeScore >= 7
  ? "#089616"
  : vibeScore >= 5
  ? "#74d52e"
  : vibeScore >= 3
  ? "#ffb93d"
  : "#fb3e3e";

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          ml: 3,
          mt: 3,
          mr: 2,
          mb: 1,
        }}
      >
        <Tooltip title="The vibe check is a score from 1-10 that measures how much the audience is vibing in the comments section.">
          <Typography variant="h6" align="center">
            Vibe Check{" "}
          </Typography>
        </Tooltip>
        <AutoAwesome color="primary" sx={{ mt: -1.5, height: 12, width: 12 }} />

        <Box sx={{ flexGrow: 1 }} />

        <ButtonBase onClick={() => setHidden(!hidden)}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 12,
              background:
                vibeScore >= 7
                  ? "#089616"
                  : vibeScore >= 5
                  ? "#74d52e"
                  : vibeScore >= 3
                  ? "#ffb93d"
                  : "#fb3e3e",
              color: "#fff",
              padding: "0px 15px 0px 15px",
              display: hidden ? "flex" : "none",
            }}
          >
            <Typography variant="body2" fontWeight={700} fontSize={16}>
              {vibeScore}
            </Typography>
          </Card>

          <IconButton onClick={() => setHidden(!hidden)}>
            {hidden ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </ButtonBase>
      </Box>

      {hidden ? null : (
        <>
          <ScoreBar score={vibeScore} color={vibeColor} />

          <Box sx={{ m: 1, mt: 5 }}>
            <Typography variant="h6" align="center">
              {vibeScore >= 7
                ? "The vibes are immaculate!"
                : vibeScore >= 5
                ? "The vibes are good!"
                : vibeScore >= 3
                ? "The vibes are alright."
                : "The vibes are off..."}
            </Typography>
            <Typography variant="body2" align="center">
              {vibeScore >= 7
                ? "You have a deeply engaged and passionate audience."
                : vibeScore >= 5
                ? "The comment section shows an engaged audience."
                : vibeScore >= 3
                ? "Solid engagement, but with some fiesty comments here!"
                : "This comment section is a rough one!"}
            </Typography>
          </Box>

          <Box
            sx={{
              flexDirection: "row",
              justifyContent: "flex-stretch",
              alignItems: "center",
              mb: 1,
              display: hidden ? "none" : "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                ml: 3,
                mr: 3,
                flexGrow: 1,
                gap: ".3rem",
              }}
            >
              <DataBar
                label="SENTIMENT"
                score={sentimentScore}
                color="#4828C5"
                description="Sentiment is a measure of how positive or negative the comments are. A higher sentiment score means that the comments are more positive. A lower sentiment score means that the comments are more negative."
              />

              <DataBar
                label="RELEVANCE"
                score={relevanceScore}
                color="#694aff"
                description="Relevance is a measure of how relevant the comments are to the video. A higher relevance score means that the comments are more relevant to the video."
              />

              <DataBar
                label="CONSENSUS"
                score={consensusScore}
                color="#b1bcff"
                description={
                  vibeCheck.consensusData && vibeCheck.consensusData.info
                    ? vibeCheck.consensusData.info
                    : `Consensus is a measure of how much the comments agree with each other. A higher consensus score means that the comments are more similar to each other. A lower consensus score means that the comments are more diverse.`
                }
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ml: 3,
                mr: 3,
                // width: "100px",
                position: "relative",
              }}
            >
              <Avatar
                sx={{
                  width: "120px",
                  height: "120px",
                  color: vibeColor,
                  background: "transparent",
                  fontSize: "40px",
                  fontWeight: 900,
                }}
              >
                {vibeScore}
              </Avatar>

              <Typography
                variant="body2"
                fontWeight={700}
                fontSize={25}
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "72%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {vibeScore >= 7 ? "✨" : vibeScore >= 3 ? "" : ""}
              </Typography>

              <Scorer score={parseFloat(vibeCheck.vibeScore)} color={vibeColor} />

            </Box>
          </Box>
        </>
      )}
    </>
  );
}

function VibeCheckSection(props) {
  const { vibeCheck } = props;
  const [hidden, setHidden] = React.useState(true);

  console.log("vibeCheck", vibeCheck);

  const vibeScore = vibeCheck.vibeScore ? parseFloat(vibeCheck.vibeScore) : 0;
  const sentimentScore = vibeCheck.sentimentData.sentimentScore
    ? parseFloat(vibeCheck.sentimentData.sentimentScore).toFixed(1)
    : 0;
  const relevanceScore = vibeCheck.relevanceData.relevanceScore
    ? parseFloat(vibeCheck.relevanceData.relevanceScore).toFixed(1)
    : 0;
  const consensusScore = vibeCheck.consensusData.consensusScore
    ? parseFloat(vibeCheck.consensusData.consensusScore).toFixed(1)
    : 0;

  const vibeColor = vibeScore >= 7
  ? "#089616"
  : vibeScore >= 5
  ? "#74d52e"
  : vibeScore >= 3
  ? "#ffb93d"
  : "#fb3e3e";

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          ml: 3,
          mt: 3,
          mr: 2,
          mb: 1,
        }}
      >
        <Tooltip title="The vibe check is a score from 1-10 that measures how much the audience is vibing in the comments section.">
          <Typography variant="h6" align="center">
            Vibe Check{" "}
          </Typography>
        </Tooltip>
        <AutoAwesome color="primary" sx={{ mt: -1.5, height: 12, width: 12 }} />

        <Box sx={{ flexGrow: 1 }} />

        <ButtonBase onClick={() => setHidden(!hidden)}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 12,
              background:
                vibeScore >= 7
                  ? "#089616"
                  : vibeScore >= 5
                  ? "#74d52e"
                  : vibeScore >= 3
                  ? "#ffb93d"
                  : "#fb3e3e",
              color: "#fff",
              padding: "0px 15px 0px 15px",
              display: hidden ? "flex" : "none",
            }}
          >
            <Typography variant="body2" fontWeight={700} fontSize={16}>
              {vibeScore}
            </Typography>
          </Card>

          <IconButton onClick={() => setHidden(!hidden)}>
            {hidden ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </ButtonBase>
      </Box>
      

      {hidden ? null : (
        <>
          <ScoreBar score={vibeScore} color={vibeColor} />

          <Box sx={{ m: 1, mt: 5 }}>
            <Typography variant="h6" align="center">
              {vibeScore >= 7
                ? "The vibes are immaculate!"
                : vibeScore >= 5
                ? "The vibes are good!"
                : vibeScore >= 3
                ? "The vibes are alright."
                : "The vibes are off..."}
            </Typography>
            <Typography variant="body2" align="center">
              {vibeScore >= 7
                ? "You have a deeply engaged and passionate audience."
                : vibeScore >= 5
                ? "The comment section shows an engaged audience."
                : vibeScore >= 3
                ? "Solid engagement, but with some fiesty comments here!"
                : "This comment section is a rough one!"}
            </Typography>
          </Box>

          <Box
            sx={{
              flexDirection: "row",
              justifyContent: "flex-stretch",
              alignItems: "center",
              mb: 1,
              display: hidden ? "none" : "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                ml: 3,
                mr: 3,
                flexGrow: 1,
                gap: ".3rem",
              }}
            >
              <DataBar
                label="SENTIMENT"
                score={sentimentScore}
                color="#4828C5"
                description="Sentiment is a measure of how positive or negative the comments are. A higher sentiment score means that the comments are more positive. A lower sentiment score means that the comments are more negative."
              />

              <DataBar
                label="RELEVANCE"
                score={relevanceScore}
                color="#694aff"
                description="Relevance is a measure of how relevant the comments are to the video. A higher relevance score means that the comments are more relevant to the video."
              />

              <DataBar
                label="CONSENSUS"
                score={consensusScore}
                color="#b1bcff"
                description={
                  vibeCheck.consensusData && vibeCheck.consensusData.info
                    ? vibeCheck.consensusData.info
                    : `Consensus is a measure of how much the comments agree with each other. A higher consensus score means that the comments are more similar to each other. A lower consensus score means that the comments are more diverse.`
                }
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ml: 3,
                mr: 3,
                // width: "100px",
                position: "relative",
              }}
            >
              <Avatar
                sx={{
                  width: "120px",
                  height: "120px",
                  color: vibeColor,
                  background: "transparent",
                  fontSize: "40px",
                  fontWeight: 900,
                }}
              >
                {vibeScore}
              </Avatar>

              <Typography
                variant="body2"
                fontWeight={700}
                fontSize={25}
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "72%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {vibeScore >= 7 ? "✨" : vibeScore >= 3 ? "" : ""}
              </Typography>

              <Scorer score={parseFloat(vibeCheck.vibeScore)} color={vibeColor} />

            </Box>
          </Box>
        </>
      )}
    </>
  );
}

function ScoreBar(props) {
  const { score, color } = props;

  React.useEffect(() => {
    let marker = document.getElementById("marker");
    let scorebar = document.getElementById("scorebar");

    if (marker && scorebar) {

      
      let factor = (score) => {
        if (score <= 3) {
          return 12;
        } else if (score > 3 && score < 5) {
          return 10.6;
        } else if (score >= 5 && score < 6) {
          return 10;
        } else if (score >= 6 && score < 7) {
          return 9.6;
        } else if (score >= 7 && score < 8) {
          return 9.3;
        } else if (score >= 8 && score < 9) {
          return 9.6;
        } else if (score >= 9) {
          return 10;
        }
      };
      

      marker.style.left = `${
        ((scorebar.getBoundingClientRect().width * score) / factor(score)) - 15
      }px`;
    }
  }, [score]);

  return (
    <>
      <Box sx={{ display: "flex", position: "relative", mr: 5, ml: 5 }}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score < 3 ? "#fb3e3e" : "#ccc"}
              fontWeight={700}
              // fontSize={score < 3 ? 14 : 12}
              fontSize={12}
            >
              OFF
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score >= 3 && score < 5 ? "#ffb93d" : "#ccc"}
              fontWeight={700}
              // fontSize={score >= 3 && score < 5 ? 14 : 12}
              fontSize={12}
            >
              ALRIGHT
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score >= 5 && score < 7 ? "#74d52e" : "#ccc"}
              fontWeight={700}
              // fontSize={score >= 5 && score < 7 ? 14 : 12}
              fontSize={12}
            >
              GOOD
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score >= 7 && score < 10 ? "#089616" : "#ccc"}
              fontWeight={700}
              fontSize={12}
              // fontSize={score >= 7 && score < 10 ? 14 : 12}
            >
              IMMACULATE
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", position: "relative", mr: 5, ml: 5, mb: 3 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            borderRadius: 30,
            overflow: "hidden",
          }}
          id="scorebar"
        >
          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#fb3e3e",
                opacity: 0.8,
              }}
            />
          </Grid>
          {/* <Box sx={{ height: 10, width: "1%", background: "#000" }} /> */}

          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#ffb93d",
                opacity: 0.8,
              }}
            />
          </Grid>
          {/* <Box sx={{ height: 10, width: "1%", background: "#000" }} /> */}
          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#74d52e",
                opacity: 0.8,
              }}
            />
          </Grid>
          {/* <Box sx={{ height: 10, width: "1%", background: "#000" }} /> */}

          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#089616",
                opacity: 0.8,
              }}
            />
          </Grid>
        </Grid>

        <Box
          alignItems="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ height: 10, width: "2px", background: "transparent" }} />

          <Box sx={{ height: 10, width: "2px", background: "#fff" }} />

          <Box sx={{ height: 10, width: "2px", background: "#fff" }} />

          <Box sx={{ height: 10, width: "2px", background: "#fff" }} />

          <Box sx={{ height: 10, width: "2px", background: "transparent" }} />
        </Box>

        <Box
          alignItems="center"
          sx={{
            position: "absolute",
            top: 12,
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "10px",
            }}
          >
            0
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "20px",
            }}
          >
            3
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "20px",
            }}
          >
            5
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "20px",
            }}
          >
            7
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "10px",
            }}
          >
            10
          </Box>
        </Box>

        <svg
      width="30"
      height="30"
      viewBox="0 0 150 149.999998"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      id="marker"
      style={{ position: 'absolute', top: 3, left: 0, filter: 'drop-shadow( 0px 0px 2px rgba(0, 0, 0, .4))'}}
    >
      <path
        fill="rgba(0,0,0,0)"
        d="M 52.957031 143.25 L 52.957031 4.238281 L 97.046875 4.238281 L 97.046875 143.25 Z M 52.957031 143.25 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill={color}
        stroke="#000"
        strokeWidth={5}
        d="M 52.957031 26.285156 L 52.957031 143.25 L 97.046875 143.25 L 97.046875 26.285156 L 75 4.238281 Z M 52.957031 26.285156 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </svg>

      </Box>
    </>
  );
}

function DataBar(props) {
  const { label, score, color, description } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        cursor: "pointer",
      }}
      onClick={() => setOpen(!open)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography fontSize={10} fontWeight={700}>
          {label}
        </Typography>

        <InfoOutlined
          sx={{
            cursor: "pointer",
            ml: 0.5,
            mb: 0.4,
            width: 15,
            height: 15,
            color: "#666",
          }}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{width:"85%"}}>
        <LinearProgress
          variant="determinate"
          value={score * 10}
          sx={{
            width: "100%",
            borderRadius: 30,
            height: 20,
            background: "#EEE",
            border: "transparent",
            "& .MuiLinearProgress-barColorPrimary": { background: color },
          }}
        />
        </Box>

        <Box sx={{ ml: 1 }}>
          <Typography variant="body2"  fontWeight={700} fontSize={14}>
            {score}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ m: 1, display: open ? "flex" : "none" }}>
        <Grow in={open}>
          <Typography variant="body2" align="left" sx={{ fontStyle: "italic" }}>
            {description}
          </Typography>
        </Grow>
      </Box>
    </Box>
  );
}



const Scorer = ({score, color}) => {



  const svgStyle = {
    height: '140px',
    width: '140px',
    position:"absolute",
    top:0,
    left:0,
    transform: 'rotate(-85deg)',
    fill:"transparent"
  };

  const circleStyle = {
    fill: 'transparent',
  };

  const progressStyle = {
    stroke: 'hsl(161, 100%, 43%)',
    strokeWidth: '14px',
    strokeLinecap: 'round',
    strokeDasharray: '440',
    // Change number value to shift the progress bar // 130 = 0, 440 = 100
    strokeDashoffset: score ? 440 - (score * 440) / 100 : 440,
  };

  const progressBorderStyle = {
    stroke: color,
    strokeWidth: '20px',
    strokeLinecap: 'round',
    strokeDasharray: '440',
     // Change number value to shift the progress bar // 130 = 100, 440 = 0
     strokeDashoffset: score == 10 ? 130 : (440 - (310/10 * score)) + 8
  
     
     
  };

  const trackStyle = {
    stroke: color,
    opacity:.5,
    strokeWidth: '20px',
    strokeLinecap: 'round',
    strokeDasharray: '440',
    strokeDashoffset: '130', // Change number value to shift the progress bar
  };

  const borderTrackStyle = {
    stroke: 'hsl(0, 0%, 93%)',
    strokeWidth: '12px',
  };

  return (
    <svg style={svgStyle}>
      {/* <circle id="border-track" cx="75" cy="75" r="65" style={circleStyle} /> */}
      <circle id="track" cx="80" cy="60" r="50" style={trackStyle} />
      {/* <circle id="progress" cx="75" cy="75" r="65" style={{ ...circleStyle, ...progressStyle }} /> */}
      <circle
        id="progress-border"
        cx="80"
        cy="60"
        r="50"
        style={{ ...circleStyle, ...progressBorderStyle }}
      />
    </svg>
  );
};