import React from 'react';
import { LineChart, Line, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, ScatterChart, Scatter } from 'recharts';
import { Typography } from '@mui/material';
import { format, parseISO, subMonths } from 'date-fns';


const formatDate = (dateString) => {
    // const parsedDate = parseISO(dateString);
    return format(dateString, 'MM-dd'); // Customize the date format as needed
  };

  const startDate = subMonths(new Date(), 1);

  const color = (score) => {
    return score >= 7
    ? "#089616"
    : score >= 5
    ? "#74d52e"
    : score >= 3
    ? "#ffb93d"
    : "#fb3e3e"
    }

const AccountsChart = ({ accounts }) => {

  return (
    <div>
      <Typography variant="h6">TikTok Account Scores - Past Month</Typography>
      <ResponsiveContainer width="100%" height={250}>
        
        <ScatterChart
                 >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="createdAt" tickFormatter={formatDate} name="Date" allowDataOverflow/>
          <YAxis type="number" domain={[3, 10]} display="none" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {/* Example Line for Account 1 */}

          {accounts.map((account, index) => 
            <Scatter 
            line={{stroke: "#999", strokeWidth: 2, fill: 'none'}}
              key={account.username}
              type="monotone"
              dataKey="vibeScore"
              data={account.sifts.filter((sift) => sift.vibeCheck).map((sift) => 
                {return {"username": account.username, "createdAt" : new Date(sift.createdAt), "vibeScore" : JSON.parse(sift.vibeCheck).vibeScore}})
                .sort((a, b) => {
                return a.createdAt - b.createdAt;
              }).filter((entry) => entry.createdAt >= startDate)}
              name={account.username}
              //random color (all rgb values between 0 and 100)               
            dot={{ stroke: `#ff0000`, fill: '#fff', r: 8 }}
            fill='#4828c5'
            />
          )}
    
        </ScatterChart>

       

      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = ({ active, payload }) => {
    console.log("payload", payload);
    
    
 
    if (active) {
     
    
      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          {/* <p>{`Date: ${label}`}</p> */}
          {payload.map((entry, index) => (
          <p style={{ color: "#4828c5", width:200 }}>{`${ entry.name}: ${entry.value}`}</p>
            ))}
        </div>
      );
    }
  
    return null;
  };

export default AccountsChart;