import {
  Box,
  Grid,
  Typography,
  Slider,
  Card,
  createTheme,
  ThemeProvider,
  ButtonBase,
  AppBar,
  Divider,
  Skeleton,
  Tabs,
  Tab,
  CardContent,
  AvatarGroup,
  Avatar,
  Grow,
  CircularProgress,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  Add,
  AutoAwesome,
  Comment,
  ConnectWithoutContact,
  IndeterminateCheckBoxSharp,
  Person,
  PersonOutline,
  PersonPinCircle,
  PersonTwoTone,
  Settings,
  SwitchAccount,
} from "@mui/icons-material";
import axios from "axios";
import TikTokComment from "./TikTokComment";
import { siftsyTheme } from "./siftsytheme";
import { useParams } from "react-router-dom";
import { useAuth, UserButton, useUser } from "@clerk/clerk-react";
import { sifterOptions } from "./constants";
import ConnectionModule from "./ConnectionModule";
import { ReviewingModal } from "./ConnectionModule";
import Functions from "./Functions";
import AccountsChart from "./AccountChart";

const darkMode = false;

const theme = createTheme(siftsyTheme);

const StyledButton = muiStyled(ButtonBase)(({ theme }) => ({
  borderRadius: 30,
  background: `${theme.palette.primary.main}`,
  fontFamily: ["Gilroy", "sans-serif"].join(","),
  fontSize: "1rem",
  fontWeight: 900,
  position: "fixed",
  bottom: 20,
  left: "calc(50% - 85%/2)",
  color: theme.palette.secondary.main,
  padding: "15px 0px",
  width: "85%",
  boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.5)",
}));

const StyledCallout = muiStyled(Card)(({ theme }) => ({
  borderRadius: 30,
  background: `${theme.palette.primary.main}`,
  fontFamily: ["Gilroy", "sans-serif"].join(","),
  fontSize: "1rem",
  fontWeight: 900,
  color: theme.palette.secondary.main,
  padding: "15px 0px",
  width: "fit-content",
  elevation: 0,
}));

export default function SiftHome(props) {
  const [sifts, setSifts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState(null);
  const [userData, setUserData] = React.useState(null);
  const [accountReview, setAccountReview] = React.useState(false);
  const [username, setUsername] = React.useState(null);

  const [ownedAccounts, setOwnedAccounts] = React.useState([]);
  const [subbedAccounts, setSubbedAccounts] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);

  const { isLoaded, isSignedIn, user } = useUser();

  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setPageWidth(window.innerWidth);
  });

  React.useEffect(() => {
    async function fetchData() {
      let loadedData = await Functions.fetchUser(
        user.emailAddresses[0].emailAddress
      );

      setUserData(loadedData);

      let owned = await Functions.listOwnedByEmail(
        user.emailAddresses[0].emailAddress
      );
      owned = owned.filter(
        (account) =>
          !account.subscribers.includes(user.emailAddresses[0].emailAddress)
      );
      let subs = await Functions.listSubscriptionsByEmail(
        user.emailAddresses[0].emailAddress
      );

      setSubbedAccounts(subs);
      setOwnedAccounts(owned);

      if (subs.length == 0 && owned.length == 0) {
        window.location.href = "/connect";
      }

      let accounts = [...owned, ...subs];

      let review = false;

      for (let i = 0; i < accounts.length; i++) {
        if (accounts[i].status == "review") {
          review = true;
          setUsername(accounts[i].username.replace("@", ""));
        }
        if (accounts[i].status == "active") {
          review = false;
        }
      }

      setAccountReview(review);

      const res = await Functions.listSiftsByEmail(
        user.emailAddresses[0].emailAddress
      );

      if (res) {
        let sifts = [];
        // for(let i = 0; i < res.sifts.length; i++){
        //     if(new Date(res.sifts[i].createdAt) > new Date("2023-08-30T00:00:00.000Z")){
        //         sifts.push(res.sifts[i]);
        //     }
        // }

        sifts = res.sifts.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });


        accounts.forEach((account) => {
            account.sifts = sifts.filter((sift) => 
               sift.accountId == account.id
            );
        });    

        accounts = accounts.filter((account) => account.sifts.length > 0);

        console.log("accounts", accounts);

        setAccounts(accounts);
        setSifts(sifts);

          }

      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div
      id="popup"
      style={{
        margin: "0 auto",
        width: pageWidth > 450 ? 450 : pageWidth,
        height: "auto",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <ThemeProvider theme={theme}>
        <AppBar
          position="static"
          elevation={0}
          sx={{ background: "transparent" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-stretch",
              alignItems: "center",
              m: 1,
            }}
          >
            <img
              src={"/siftsy_logo.png"}
              alt="logo"
              style={{ width: 80, height: "auto", margin: "5px" }}
            />
            <Typography
              color="primary"
              align="center"
              variant={"subtitle2"}
              sx={{
                borderRadius: 30,
                background: "rgba(238,234,255, .8)",
                color: "#4828C5",
                padding: "1px 12px 1px 12px",
                ml: -1,
                fontWeight: 600,
              }}
            >
              beta{" "}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ m: 1 }}>
              <ButtonBase onClick={() => (window.location.href = "/prefs")}>
                <Settings sx={{ fill: "#4828C5", fontSize: "1.3rem" }} />
              </ButtonBase>
            </Box>

            <Box sx={{ m: 1 }}>
              <UserButton />
            </Box>
          </Box>
        </AppBar>

        <ReviewingModal
          reviewing={accountReview}
          connectAccount={() => (window.location.href = "/connect")}
          username={username}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            m: 2,
          }}
        >
          <Card
            elevation={0}
            sx={{
              borderRadius: "12px",
              background: "#EEEAFF",
              p: 2,
              width: "100%",
            }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={25}
                sx={{ borderRadius: 5 }}
              />
            ) : (
                <Grow in={true} timeout={1500}>
              <Typography align="center" variant="body1">
                Welcome back
                {userData && userData.name
                  ? `, ${userData.name.split(" ")[0]}!`
                  : "!"}
                <br />
                {/* <b>This is a demo account with all of the sifts.</b> */}
              </Typography>
                </Grow>
            )}
          </Card>
        </Box>

        {loading ? null : 

<AccountsChart accounts={accounts} />

}



        {loading ? 

        <Box sx={{ display:"flex", justifyContent:"center", alignItems:"center", height:"300px"}}>

            <Box sx={{position:"relative", display:"Flex", justifyContent:"center", alignItems:"center", width:100, height:100 }}>
                <Box sx={{position:"absolute", top:0, left:0, width:100, height:100}}>
            <Grow appear in={true} timeout={1500} easing={"ease-in-out"}>
            <CircularProgress color="primary" size={100} />
            </Grow>
                </Box>
                <Box sx={{position:"absolute", top:0, left:0, justifyContent:"center", display:"flex", alignItems:"center", width:100, height:100}}>
                    <Grow in={true} timeout={1500}>
            <img src="/siftsy_y.png" alt="logo" style={{ width: 60, height: 60, margin: "5px" }} />
                    </Grow>
            </Box>
            </Box>

        </Box>

                : null}


        {!loading && sifts.length == 0 ? (
          <Box sx={{ m: 4 }}>
            <Typography variant="h6" align="center">
              No sifts here... yet!
            </Typography>

            <Typography variant="body1" align="center">
              <b>siftsy</b> will email you 24 hours after your next upload.
            </Typography>
          </Box>
        ) : (
          <>

    
          
            <Box sx={{ m: 2, mt: 2, mb: 1 }}>
              {loading ? (
                    // <Skeleton
                    // variant="rectangular"
                    // width="40%"
                    // height={20}
                    // sx={{ borderRadius: 5 }}
                    // />
                    null
              ) : (
                <Typography variant="h6" align="left">
                  Latest
                </Typography>
              )}
            </Box>

            <Grid container spacing={2} alignItems="center" sx={{ p: 2 }}>
              {sifts.slice(0, 2).map((sift, index) => (
                <Grid item xs={6}>
                  <ButtonBase onClick={() => setSelected(index)}>
                    <PostCard sift={sift} selected={index == selected} />
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ m: 2, mt: 2, mb: 1 }}>
              {loading > 0 ? (
                // <Skeleton
                //   variant="rectangular"
                //   width="20%"
                //   height={20}
                //   sx={{ borderRadius: 5 }}
                // />
                null
              ) : (
                <Typography variant="h6" align="left">
                  All
                </Typography>
              )}
            </Box>

            <Grid container spacing={2} alignItems="center" sx={{ p: 1 }}>
              {sifts.slice(2, sifts.length - 1).map((sift, index) => (
                <Grid item xs={4}>
                  <ButtonBase onClick={() => setSelected(index + 2)}>
                    <PostCard sift={sift} selected={index + 2 == selected} />
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {sifts.length > 0 && !loading ? (
          <StyledButton
            disabled={selected == null}
            sx={{
              width: pageWidth > 450 ? 400 : pageWidth * 0.8,
              left:
                pageWidth > 450
                  ? "calc(50% - 200px)"
                  : `calc(50% - ${(pageWidth * 0.8) / 2}px)`,
              opacity: selected == null ? 0.3 : 1,
            }}
            onClick={() => {
              if (
                sifts[selected].accountId &&
                subbedAccounts.filter(
                  (account) => account.id == sifts[selected].accountId
                ).length > 0
              ) {
                window.open(
                  `/${
                    subbedAccounts.filter(
                      (account) => account.id == sifts[selected].accountId
                    )[0].username
                  }/${sifts[selected].postId}`,
                  "_blank"
                );

                // window.location.href = `/${sifts[selected].creatorusername}/${sifts[selected].id}`
              }
            }}
          >
            <Typography
              align="center"
              color="secondary"
              variant="h6"
              sx={{ fontSize: "1rem" }}
            >
              {selected != null ? "View Sift" : "Select a Sift"}
            </Typography>
          </StyledButton>
        ) : null}
      </ThemeProvider>
    </div>
  );
}

function PostCard(props) {
  const { sift, selected } = props;

  const postId = sift.postId;

  const posterSrc = `https://siftsy-post-images.s3.amazonaws.com/tiktok/${postId}.jpg`;

  const [loaded, setLoaded] = React.useState(false);

  const id = sift.id;

  const highlightSection = React.useRef(null);

  const siftData = JSON.parse(sift.sift);

  const vibeCheck = sift.vibeCheck ? JSON.parse(sift.vibeCheck) : null;
  const vibeScore = vibeCheck ? parseFloat(vibeCheck.vibeScore) : null;

  const filter = "SUPPORTIVE";

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Grow in={true} timeout={1500}>
    <Card
      elevation={0}
      sx={{ borderRadius: "12px", background: "#EEEAFF", overflow: "hidden" }}
    >
      {loaded ? (
        <img
          src={posterSrc}
          alt="poster"
          style={{
            width: selected ? "96%" : "100%",
            height: "auto",
            borderRadius: "12px",
            border: loaded && selected ? "3px solid #4828C5" : null,
          }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          animation="wave"
          sx={{ borderRadius: "12px" }}
        />
      )}

      {loaded ? (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            height: "99%",
            width: "100%",
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .3))",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              top: -7,
              right: -7,
            }}
          >
            <AutoAwesome color="primary" sx={{ fontSize: "1.8rem" }} />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              bottom: 8,
              right: 1,
              mr: 1,
            }}
          >
            {vibeCheck && vibeScore ? (
              <Card
                elevation={0}
                sx={{
                  borderRadius: 2,
                  background:
                    vibeScore >= 7
                      ? "#089616"
                      : vibeScore >= 5
                      ? "#74d52e"
                      : vibeScore >= 3
                      ? "#ffb93d"
                      : "#fb3e3e",
                  color: "#fff",
                  padding: "0px 15px 0px 15px",
                }}
              >
                <Typography variant="body2" fontWeight={700} fontSize={16}>
                  {vibeCheck.vibeScore}
                </Typography>
              </Card>
            ) : null}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              bottom: 8,
              left: 1,
            }}
          >
            <Card
              elevation={0}
              sx={{
                borderRadius: "12px",
                background: "transparent",
                pl: 1,
                pr: 1,
                width: "fit-content",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-stretch",
                  alignItems: "center",
                }}
              >
                <Comment sx={{ fill: "white", fontSize: "1rem", mr: 0.5 }} />

                <Typography variant="subtitle1" color="white">
                  {sift.totalComments}
                </Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      ) : null}
    </Card>
    </Grow>
  );
}

function MainSummary(props) {
  // Use a regular expression to find sentence-ending punctuation and add a line break
  const stringWithLineBreaks = props.text.replace(/([.!?])\s/g, "$1\n\n");

  const [more, setMore] = React.useState(false);

  React.useEffect(() => {
    setMore(false);
  }, [props.text]);

  return (
    <>
      {stringWithLineBreaks.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          <span
            style={{
              display: index == 0 || more ? "inline" : "none",

              // fontSize: index == 0 ? "1.4rem" : null
            }}
          >
            {line}
            {index == 0 && !more ? (
              <span onClick={() => setMore(!more)}>
                ..{" "}
                <span
                  style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}
                >
                  more
                </span>
              </span>
            ) : (
              " "
            )}
            <br />
          </span>
        </React.Fragment>
      ))}

      {more ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <span onClick={() => setMore(!more)}>
            <br />
            <span style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}>
              less
            </span>
          </span>
        </Box>
      ) : null}
    </>
  );
}

const SifterPicker = (props) => {
  const { filter, setFilter } = props;

  return (
    <div style={{ width: "90%", margin: "0 auto", cursor: "pointer" }}>
      <Grid container spacing={2} alignItems="center">
        {sifterOptions.map((sifter, index) => (
          <Grid item xs={4}>
            <Typography
              color="primary"
              align="center"
              onClick={() => setFilter(index)}
              variant={"subtitle2"}
              sx={{
                borderRadius: 30,
                background: filter == index ? "#4828C5" : "#ffffff",
                color: filter == index ? "#fff" : "#4828C5",
                padding: "3px 0px",
                border: "1px solid #4828C5",
                fontWeight: filter == index ? 900 : 700,
              }}
            >
              {sifter.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const CustomSlider = muiStyled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    height: 34,
    width: 34,
    backgroundColor: "transparent",
    // border: `3px solid ${theme.palette.primary.main}`,
    // marginTop: -8,
    // marginLeft: -12,
    // '&:focus, &:hover, &$active': {
    //   boxShadow: 'inherit',
    // },
  },

  "& .MuiSlider-valueLabel": {
    left: "calc(-50% + 6px)",
    background: "transparent",
  },

  // '& .MuiSlider-track': {
  //   height: 5,
  //   borderRadius: 5,
  // },

  "& .MuiSlider-rail": {
    height: 25,
    borderRadius: 30,
    opacity: 1,
    background: "linear-gradient(to right, #B7FF70, #FFEC3E, #FF7A7A)",
  },
}));

function timeAgo(isoString) {
  const date = new Date(isoString).getTime();
  const now = new Date().getTime();
  const timeDifference = now - date;

  if (timeDifference < 60000) {
    // Less than a minute
    //   const seconds = Math.floor(timeDifference / 1000);
    //   return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    return "just now";
  } else if (timeDifference < 3600000) {
    // Less than an hour
    const minutes = Math.floor(timeDifference / 60000);
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (timeDifference < 86400000) {
    // Less than a day
    const hours = Math.floor(timeDifference / 3600000);
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else {
    const days = Math.floor(timeDifference / 86400000);
    return `${days} day${days === 1 ? "" : "s"} ago`;
  }
}

const CardContainer = muiStyled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
});

const HighlightCard = ({ highlight, highlightSection }) => {
  const ref = React.useRef(null);
  const [cardWidth, setCardWidth] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setWidth(highlightSection.current.getBoundingClientRect().width);

    if (ref.current) {
      setCardWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref]);

  console.log("cardWidth", cardWidth);
  console.log("width", width);
  console.log("cardWidth * 12/width", Math.ceil((cardWidth * 12) / width));

  return (
    <Grid item xs={Math.ceil((cardWidth * 12) / width) + 0.5}>
      <Card
        ref={ref}
        elevation={0}
        sx={{ borderRadius: "12px", background: "#EEEAFF", p: 1 }}
      >
        <Typography align="center" variant="body1" sx={{ fontSize: ".8rem" }}>
          {highlight.trim()}
        </Typography>
      </Card>
    </Grid>
  );
};

const CommentGrouping = ({
  groupPhrase,
  commentGroup,
  commentSection,
  commentData,
  selectedGroup,
  setSelectedGroup,
}) => {
  const ref = React.useRef(null);
  const [cardWidth, setCardWidth] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setWidth(commentSection.current.getBoundingClientRect().width);
    if (ref.current) {
      setCardWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref]);

  console.log("cardWidth", cardWidth);
  console.log("width", width);
  console.log("cardWidth * 12/width", Math.ceil((cardWidth * 12) / width));

  let selected = selectedGroup === groupPhrase;

  return (
    <Grid item xs={Math.ceil((cardWidth * 12) / width) + 1}>
      <ButtonBase
        onClick={() => {
          if (selected) {
            setSelectedGroup(null);
          } else {
            setSelectedGroup(groupPhrase);
          }
        }}
      >
        <Card
          elevation={0}
          sx={{
            borderRadius: 30,
            background: selected ? "#4828C5" : "#ffffff",
            color: selected ? "#fff" : "#4828C5",
            p: 0.5,
            border: "2px solid #eeeeee",
          }}
          ref={ref}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              p: 0.5,
            }}
          >
            <AvatarGroup
              max={4}
              total={commentGroup.length > 4 ? 4 : commentGroup.length}
              sx={{
                "& .MuiAvatar-root": {
                  width: 25,
                  height: 25,
                  fontSize: 10,
                  background: "#F3F2FF",
                  color: "#4828C5",
                },
              }}
            >
              {commentGroup
                .slice(0, commentGroup.length > 4 ? 4 : commentGroup.length)
                .map((commentId) => (
                  <DynamicAvatar
                    comment={commentData.find(
                      (comment) => comment.commentId === commentId
                    )}
                  />
                ))}
            </AvatarGroup>

            {commentGroup.length > 4 ? (
              <Avatar
                sx={{
                  marginLeft: "-5px",
                  zIndex: 1,
                  width: 25,
                  height: 25,
                  fontSize: 10,
                  background: "#F3F2FF",
                  color: "#4828C5",
                }}
              >
                <b> + {commentGroup.length - 4} </b>
              </Avatar>
            ) : null}

            <Typography
              align="center"
              variant="body1"
              sx={{
                ml: 1,
                color: selected ? "#fff" : "#4828C5",
                fontSize: ".8rem",
                fontStyle: "italic",
              }}
            >
              "{groupPhrase.trim()}"
            </Typography>
          </Box>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

function DynamicAvatar(props) {
  const { comment } = props;

  console.log("comment", comment);

  return (
    <Avatar
      sx={{ width: 25, height: 25, zIndex: 10 }}
      alt={comment.username}
      src={comment.avatarlink}
    />
  );
}
