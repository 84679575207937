import {
  Box,
  Grid,
  Typography,
  Slider,
  Card,
  createTheme,
  ThemeProvider,
  ButtonBase,
  AppBar,
  Divider,
  Skeleton,
  Tabs,
  Tab,
  CardContent,
  AvatarGroup,
  Avatar,
  Button,
  Popper,
  ClickAwayListener,
  Paper,
  MenuItem,
  IconButton,
  MenuList,
  FormControl,
  Select,
  Chip,
  LinearProgress,
  CircularProgress,
  Tooltip,
  Badge,
  TextField,
  SpeedDialIcon,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Snackbar,
  Alert,
  List,
} from "@mui/material";
import React, { useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  AutoAwesome,
  BarChart,
  Chat,
  CheckBoxOutlined,
  Close,
  Comment,
  CommentOutlined,
  ContentCopyOutlined,
  CopyAllOutlined,
  DescriptionOutlined,
  DoneAllOutlined,
  DoneAllRounded,
  DragHandle,
  DragHandleOutlined,
  ExpandLess,
  ExpandMore,
  FavoriteBorder,
  FavoriteBorderRounded,
  FileCopyOutlined,
  FormatListBulletedOutlined,
  IndeterminateCheckBoxSharp,
  Info,
  InfoOutlined,
  InfoRounded,
  Insights,
  IosShare,
  IosShareOutlined,
  LocationCity,
  Mood,
  Mouse,
  Navigation,
  NavigationOutlined,
  NoteAltOutlined,
  OpenInBrowserOutlined,
  OpenInFull,
  OpenInNew,
  Paid,
  Person,
  PersonOutline,
  PersonPinCircle,
  PersonTwoTone,
  Pin,
  PinDrop,
  PinRounded,
  PinSharp,
  QuestionAnswer,
  QuestionMarkSharp,
  Room,
  Screenshot,
  Search,
  SearchOutlined,
  Send,
  SendOutlined,
  Settings,
  Share,
  ShareOutlined,
  ShortTextOutlined,
  Speed,
  SpeedOutlined,
  SportsScore,
  ThumbDown,
  ThumbUp,
  Tune,
  Visibility,
  VisibilityOff,
  VisibilityOutlined,
} from "@mui/icons-material";
import axios from "axios";
import TikTokComment from "./TikTokComment";
import { siftsyTheme } from "./siftsytheme";
import { useParams } from "react-router-dom";
import SiftPreview from "./SiftPreview";
import { useUser, UserButton } from "@clerk/clerk-react";
import ProfileSettingsButton from "./ProfileSettingsButton";
import { sifterOptions } from "./constants";
import ReactGA from "react-ga4";
import Functions from "./Functions";
import { Grow } from "@mui/material";
import html2canvas from "html2canvas";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { clear } from "@testing-library/user-event/dist/clear";
import './SiftPage.css';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const darkMode = false;
const theme = createTheme(siftsyTheme);


export default function ReportView(props) {
  const { isLoaded, isSignedIn, user } = useUser();
  const { teamData, selectedAssets, setSelectedAssets, setDisplayData, viewportHeight, viewportWidth } = props;
    
  const [postData, setPostData] = React.useState(null);

  const [reportData, setReportData] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {

      let postData = teamData[selectedAssets.team].groups[selectedAssets.group].posts[selectedAssets.post];

      setPostData(postData);

      console.log("postData", postData);

      let reportData = postData.reports[selectedAssets.report];

      setReportData(reportData);

      let commentData = await Functions.fetchCommentData(postData.groupId, postData.id);

      console.log("commentData", commentData);

      let listedComments = ListComments(commentData);
      setCommentData(listedComments);

      setSearchedComments(listedComments.map((entry) => ({ postId: entry.postId, commentId: entry.comment.id })));


      console.log(ListComments(commentData));


    }

    if (teamData && selectedAssets) {
      fetchData();
      setEmpty(false);
      setLoading(false);
    }
  }, [selectedAssets]);

  const [commentData, setCommentData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
  const [empty, setEmpty] = React.useState(false);
  const [searchedComments, setSearchedComments] = React.useState([]);
  const [openExport, setOpenExport] = React.useState(false);
  const [searchTimer, setSearchTimer] = React.useState(null);
  const [selectMode, setSelectMode] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [copied, setCopied] = React.useState(false);
  const [selectedComments, setSelectedComments] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [screenshotSettings, setScreenshotSettings] = React.useState({includeScreenshot: true, includeWatermark: true, includeAvatars: false});

  const CircularAvatar = muiStyled(Avatar)(({ theme }) => ({
    borderRadius: "50%",
    height: 50,
    width: 50,
    marginRight: 14,
    background: theme.palette.primary.main,
  }));

  const searchComments = async () => {
    clearTimeout(searchTimer);
    
    if (search.length > 0) {

      setSearching(true);

      let data = await Functions.searchCommentsByTopic(postData.groupId, search, postData.id);
      let results = data.results;
      console.log("results", results);

      if (results) {

      let postIds = Object.keys(results);

      let searchedByTopic = [];

      postIds.forEach((postId) => {
        let commentIds = results[postId];
        commentIds.forEach((commentId) => {
          searchedByTopic.push({postId: postId, commentId: commentId});
        });
      });

      ///

          let searchedByDirectMatch = commentData.filter((entry) =>
          entry.comment.text
            .toLowerCase()
            .includes(search.toLowerCase())
    || entry.comment.username.toLowerCase().includes(search.toLowerCase())

        ).map((entry) => ({ postId: entry.postId, commentId: entry.comment.id }));

    let searched = searchedByTopic.concat(searchedByDirectMatch);

    //remove duplicates
    searched = searched.filter(
      (entry, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.postId === entry.postId && t.commentId === entry.commentId
        )
    );

      setSearchedComments(searched);
      setSearching(false);

    }
    else{
      setSearchedComments([]);
      setSearching(false);
    }


    } else {
      setSearchedComments(commentData.map((entry) => ({ postId: entry.postId, commentId: entry.comment.id })));
    }
  };


  React.useEffect(() => {
    if (search.length > 0) {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }

      setSearchTimer(
        setTimeout(() => {
          searchComments();
        }, 1000)
      );
    }
    else{
      setSearchedComments(commentData.map((entry) => ({ postId: entry.postId, commentId: entry.comment.id })));
      clearTimeout(searchTimer);
    }
  }, [search]);
  
  const handleExportText = () => {
    // take selected comment text and copy to clipboard

    setCopied(true);

    let dataToExport = selectedComments.map((comment) => 
      `${commentData.find((c) => c.commentId === comment).username}: ${commentData.find((c) => c.commentId === comment).text}`
    );

    let text = dataToExport.join("\n");

    // Copy data to clipboard
    navigator.clipboard.writeText(text) 
      .catch(error => console.error('Unable to copy:', error));
  };

  const handleExportImage = () => {
    handleDownload(screenShotRef, watermarkRef, postData.data.username);
  };

  // console.log("userData", userData);

  window.addEventListener("resize", () => {
    setPageWidth(window.innerWidth);
  });

  const exportVibeScoreSection = async () => {
    // take selected comment text and copy to clipboard
    let canvas = await html2canvas(document.getElementById("vibeScoreSection"), {useCORS:true, allowTaint:true, logging:true});

    let ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = "/siftsy_logo.png";

    img.onload = () => {
      ctx.drawImage(canvas, 0, 0);
      ctx.drawImage(img, 10, 10);
    }
    
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `${username}'s_vibeScore.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

  };

  const copySummary = () => {

    navigator.clipboard.writeText(reportData.summary).then(() => {
      setCopied(true); 
    })
      .catch(error => console.error('Unable to copy:', error));
  };

  React.useEffect(() => {
    if(selectedComments.length == 0){
      setSelectMode(false);
      setOpenExport(false);
    }
  }, [selectedComments]);

  const selectComment = (entry) => {

    if (!selectedComments.some(selected => selected.commentId === entry.commentId && selected.postId === entry.postId)) {
    setSelectedComments([
      ...selectedComments,
      entry,
    ]);
  }
  };

  const removeComment = (entry) => {
        let newSelected = [...selectedComments];
        newSelected.splice(selectedComments.indexOf(entry), 1);
      setSelectedComments(newSelected);
  };

  const selectAllComments = () => {
    let newSelected = searchedComments.map((entry) => ({postId: entry.postId, commentId: entry.id}));
    setSelectedComments(newSelected);
  };

  const deselectAllComments = () => {
    setSelectedComments([]);
  };

  const commentSection = React.useRef(null);
  const watermarkRef = React.useRef(null);
  const screenShotRef = React.useRef(null);

  const allSelected = selectedComments.length === searchedComments.length;

  return (
    <div
      style={{
        margin: "0 auto",
        width: pageWidth > 1000 ? 1000 : pageWidth,
        overflow: viewportWidth < 800 || viewportHeight < 1000 ? "auto" : "hidden",
        height: "100vh",
        paddingTop: 10,
      }}
    
   
    >
      <div>
      <ThemeProvider theme={theme}>

        <Snackbar open={copied} autoHideDuration={3000} onClose={() => setCopied(false)}>
        <Alert onClose={() => setCopied(false)} severity="success">
          <Typography variant="body1" align="center">
          Copied!
          </Typography>
        </Alert>
      </Snackbar>

        <Grid container spacing={4} sx={{ p: viewportWidth < 500 ? 0: 3, }}>
          {!empty ? (
            <>
              <Grid item md={6} lg={4}>
                <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {postData ? (
                    <Box
                      sx={{
                        ml: 2,
                        mr: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: "1em",
                        // alignItems: "center",
                        // background:"#ff0000"
                      }}
                    >

                      <Box>
                        
                        </Box>
                
                  <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "top",
                        width: "100%",
                        mb:1,
                        justifyContent: "center",
                        alignContent: "center",
                        
                      }}
                    >
                      {postData.data.userAvatar ? 
                      <Box sx={{ display: "flex",  alignContent: "center", mt:2 }}>
                        <CircularAvatar src={postData.data.userAvatar} />
                      </Box> : null}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >   
                      <Box>
                      <Typography variant="h6" align="left" sx={{fontSize:12}}>
                                        {postData.data.platform === "tiktok" ? "TikTok" : postData.data.platform === "youtube" ? "YouTube" : postData.data.platform === "instagram" ? "Instagram" : ""}
                                      
                                        
                                      </Typography>
                      </Box>

                        <Box sx={{ display: "flex", flexDirection: "row" }}>

                        <Typography variant="h5" align="left">
                                        {postData.data.creatorName}
                                      
                                        
                                      </Typography>

                        </Box>

                        <Box
                          sx={{ display: "flex", flexDirection: "row", alignItems: "top" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography variant="body1" align="left" component="p" sx={{fontSize: 16}}>
                            {postData.data.username}
                            </Typography>
                          </Box>
                          </Box>
                          </Box>
            

                      </Box>

                      { postData.data.platform === "tiktok" ? (
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          width: 150,
                          height: (150 * 16) / 9,
                          margin: "0 auto",
                        }}
                      >
                      
                        <div
                          style={{
                            position: "absolute",
                            width: 150,
                            height: (150 * 16) / 9,
                            borderRadius: "12px",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(
                              postData.link,
                              "_blank"
                            )
                          }
                        >
                          <img
                            src={postData.data.thumbnail}
                            alt="poster"
                            id="thumbnail"
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              width: 150,
                              height: (150 * 16) / 9,
                            }}
                          />
                        </div>
                        

                      </Box>

                      )
                      : postData.data.platform === "youtube" ? (
                        <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          width: "80%",
                          height: "auto",
                          margin: "0 auto",
                          borderRadius: 12,
                        }}
                      >
                        <img src={postData.data.thumbnail} alt="poster" style={{width:"100%", height:"auto", borderRadius:12}}/>
                      </Box>
                        
                      ) : null}

                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: 12,
                          position: "relative",
                          width: "fit-content",
                          margin: "auto",
                        }}
                        endIcon={<OpenInNew />}
                        color="primary"
                        // sx={{borderRadius:12, position:"relative"}}
                        onClick={() =>
                          window.open(
                            postData.link,
                            "_blank"
                          )
                        }
                      >
                        View Post
                      </Button>
                    </Box>
                  ) : (
                 null
                  )}
                  </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                  {postData && postData.data.engagement ? (
                    <>
                      <Box sx={{ p: 2}}>
                        <Typography variant="h6" align="left" sx={{pb:1}}> 
                        {/* // align={viewportWidth < 600 ? "center" : "left"}> */}

                          At A Glance
                        </Typography>

                        <Grid container spacing={2} alignItems="left" sx={{pl:1}}>
                          <Grid item xs={3} sm={3}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: ".3em",
                                flexGrow:1
                              }}
                            >
                              <VisibilityOutlined sx={{ height: 16 }} />
                              <Typography variant="body1">{formatNumber(postData.data.engagement.views)}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sm={3}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: ".3em",
                                flexGrow:1
                              }}
                            >
                              <FavoriteBorder sx={{ height: 16 }} />
                              <Typography variant="body1">{formatNumber(postData.data.engagement.likes)}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sm={3}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: ".3em",
                                flexGrow:1
                              }}
                            >
                              <CommentOutlined sx={{ height: 16 }} />
                              <Typography variant="body1">
                                {formatNumber(postData.data.engagement.comments)}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sm={3}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: ".3em",
                                flexGrow:1
                              }}
                            >
                              <SendOutlined sx={{ height: 16 }} />
                              <Typography variant="body1">{formatNumber(postData.data.engagement.shares)}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                   
                    </>
                  ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {postData && postData.data.description ? 
                  <Box sx={{ pl: 2, pr:2 }}>
                        <Typography variant="h6" align="left" sx={{pb:1}}> 
                          About This Video
                        </Typography>

                        {/* <Box
sx={{
  p: 1,
  display: "flex",
  flexDirection: "row",
  gap: ".3rem",
  width: 200,
}}
> */}
                        {/* <InfoOutlined sx={{ height: 16 }} /> */}
                        <Typography variant="body2" align="left">
                          <MainSummary text={postData.data.description} />
                        </Typography>
                      </Box> : null}
                  </Grid>
       {/* {sifted && siftData && siftData.postContentTags ? 
                  <Grid item xs={12}>
              */}
                    {/* <Box sx={{ pl: 2, pr:2, display:"flex", flexWrap:"wrap", gap:".5rem" }}>
                    {JSON.parse(siftData.postContentTag).tags.map((tag) => 
                      <Chip label={tag}   sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                          padding: '8px 10px',
                        },
                      }}/>

                    )}
                    </Box> */}
                    {/* </Grid> */}

                  {/* </Grid>
                  : null} */}
{/* 
                  <Grid item xs={12}>
                    <Box sx={{ pl: 2, pr:2, display:"flex", flexWrap:"wrap", gap:".5rem" }}>
                    {["Lifestyle", "Vlog", "Food", "Cooking", "Review"].map((tag) => 
                      <Chip label={tag}   sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                          padding: '4px 12px',
                        },
                      }}/>

                    )}
                    </Box> 

                  </Grid> */}
             
                  
                  </Grid>
              </Grid>

              <Grid item md={6} lg={8} >
                <Box id="comment-section" sx={{height:"100%"}}>
                <Box sx={{height: viewportWidth < 800 || viewportHeight < 800 ? "auto" : viewportHeight - 110, overflowY:"auto", overflowX:"hidden", pl:2, pr:2,
                 
                }} id="scrolling-section">
                
       
                  {/* <Typography variant="h6" align="left">
              Audience Response{" "}
            </Typography> */}

                  {
                  reportData &&
                  reportData.vibeCheck ? (
                    <VibeCheckSection
                      vibeCheck={reportData.vibeCheck}
                      exportVibeScoreSection={exportVibeScoreSection}
                    />
                  ) : null}

                  {reportData ? (
                    <>
                      <Box sx={{pt:2, pb:1, display: "flex", alignItems: "center" }}>
                        <DescriptionOutlined
                          sx={{ fontSize: 20, color: "#888", mr: 0.5 }}
                        />

                        <Typography
                          variant="body2"
                          align="left"
                          sx={{ color: "#888", fontWeight: 700 }}
                        >
                          Summary
                        </Typography>
                        <Box sx={{flexGrow:1}}/>
                        <IconButton onClick={copySummary} size="small">
                          <FileCopyOutlined sx={{fontSize:16, color:"#888"}}/>
                        </IconButton>
                      </Box>
                    </>
                  ) : null}

                  {reportData ? (
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: "12px",
                       
                        pt:1, pb:1,
                      }}
                    >
                      <Typography variant="body2" align="left">

                        <MainSummary
                          text={reportData.summary
                          }
                        />
                      </Typography>
                    </Card>
                  ) : null}

                  {reportData ? (
                    <>
                      <Box
                        sx={{
                          pt:1, pb:3, pt:3,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FormatListBulletedOutlined
                          sx={{ fontSize: 20, color: "#888", mr: 0.5 }}
                        />

                        <Typography
                          variant="body2"
                          align="left"
                          sx={{ color: "#888", fontWeight: 700 }}
                        >
                          Topics
                        </Typography>
                      </Box>
                    </>
                  ) : null}
                  {reportData ?
                           <Box sx={{display:"flex", flexWrap:"wrap", gap:".6rem" }}>
                          {reportData.highlights.sort((a, b) => a.length - b.length).
                          map(
                            (highlight) => (
                              <HighlightCard
                                search={search}
                                setSearch={setSearch}
                                highlight={highlight}
                                // highlightSection={highlightSection}
                                viewportWidth={viewportWidth}
                              />
                            )
                          )}
                          </Box>
                   
               
              
                   : null}

                  {/* {sifted && siftData && siftData.isPaidAd && siftData.brandSuccess ? (
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: "12px",
                      background: "#EEEAFF",
                      p: 3,
                      mt: 1,
                    }}
                  >
                    <Typography variant="body1" align="left">
                      <MainSummary
                        text={
                            JSON.parse(siftData.brandSuccess).summary
                           
                        }
                      />
                    </Typography>
                  </Card>
                ) : null} */}

                  {/* {sifted &&
                  siftData &&
                  !siftData.isPaidAd &&
                  sift[Object.keys(sift)[filter]] &&
                  sift[Object.keys(sift)[filter]].comments.length == 0 ? (
                    <Box sx={{ p: 5 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {filter == 0 ? (
                          <Typography variant="h6" align="center">
                            Tough crowd!
                            <br />
                            No cheerleaders here.
                          </Typography>
                        ) : filter == 1 ? (
                          <Typography variant="h6" align="center">
                            No comments here!
                            <br />
                            Give your audience a chance to speak.
                          </Typography>
                        ) : (
                          <Typography variant="h6" align="center">
                            It's all love in your comments!
                            <br />
                            No haters here.
                          </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {filter == 0 ? (
                          <img src="/frown_animation.gif" width="250px" />
                        ) : filter == 1 ? (
                          <Box sx={{ flexGrow: 1 }} />
                        ) : (
                          <img src="/confetti_animation.gif" width="250px" />
                        )}
                      </Box>
                    </Box>
                  ) : null} */}

                  {reportData &&
                  postData ?
                  (
                    <Box>
                      <Box
                    
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-stretch",
                          alignItems: "center",
                          flexGrow: 1,
                          width: "100%",
                          mt: 2,
                          pt:2
                        }}
                      >
                        <CommentOutlined sx={{ height: 16, color: "#999" }} />
                        <Typography
                          variant={"h6"}
                          align="left"
                          sx={{ color: "#999", fontSize: 16 }}
                        >
                          {searchedComments
                            ? `${
                                searchedComments.length == commentData.length
                                  ? formatNumber(postData.data.totalComments)
                                  : searchedComments.length
                              } ${viewportWidth > 500 ? `comment${searchedComments.length == 1 ? "" : "s"}` : ""}`
                            : "Comment Section"}
                        </Typography>
                        {/* <AutoAwesome
                      color="primary"
                      sx={{ mt: -1.5, height: 12, width: 12 }}
                    /> */}

                        {postData && reportData ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              flexGrow: 1,
                              pl: 2,
                              pr: 2,
                            }}
                          >
                            {loading ? (
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={25}
                                sx={{ borderRadius: 5 }}
                              />
                            ) : (
                              // <FilterSelectorButton
                              //   filter={filter}
                              //   setFilter={handleSelectedFilter}
                              // />
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexGrow: 1,
                                }}
                              >
                                <TextField
                                  label="Search"
                                  variant="outlined"
                                  fullWidth
                                  
                                  // InputProps={{
                                  //   startAdornment: (
                                  //     <InputAdornment position="start">
                                  //       <SearchOutlined />
                                  //     </InputAdornment>
                                  //   ),
                                  // }}
                                  size="small"
                                  value={search}
                                  sx={{
                                    borderRadius: 5,
                                    border: "2px solid #EEE",
                                  }}
                                  color={search.length > 99 ? "error" : "primary"}
                                  onChange={(e) => {
                                    let search = e.target.value;
                                    if (search.length > 0 && search.length < 100) {
                                    setSearch(search);
                                    }
                                  }}
                                />
                                <IconButton onClick={searchComments}>
                                  <Search />
                                </IconButton>
                              </Box>
                            )}

                          </Box>
                        ) : null}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          width: "100%",
                          mt: 1,
                          position:"relative",
                         
                          //  maxHeight:"400px", overflowX:"hidden", overflowY:"scroll", border:"2px solid #EEE", borderRadius:"12px",
                        }}
                        ref={commentSection}
                        // id="commentSection"
                      >

                                  {searching ?
                          <LinearProgress sx={{width:"absolute", top:0, left:0, height:5, borderRadius:0, border:"transparent"}}/>
                          : <Box sx={{height:5}}/>}

                 
                        {searchedComments
                          ? searchedComments.map((entry) => (
                         
                              <Box >
                                <TikTokComment
                                selectable
                                isMobile={viewportWidth < 600}
                                  comment={commentData.find(
                                    (c) => (c.postId == entry.postId && c.comment.id == entry.commentId)
                                  ).comment}
                                  selected={selectedComments.some(selected => selected.commentId == entry.commentId && selected.postId == entry.postId
                                  )}
              
                                  selectMode={selectMode}
                                  selectOnHover
                                  setSelectMode={(mode) => setSelectMode(mode)}
                                  
                                  handleCommentSelection={(event) => {

                                    event.preventDefault();
                                
                                    if(event.target.checked){
                                      selectComment(entry)
                                      if(!selectMode){
                                        setSelectMode(true);
                                      }  
                                    }
                                    else{

                                      if(selectedComments.length < 2){
                                        setSelectMode(false);
                                      }

                                      removeComment(entry)

                                      
                                    }
                                  }}
                                />

                             
                                <Divider variant="middle" sx={{marginLeft:"60px"}}/>
                             

                              
                              </Box>
                            ))
                          : null}

                            <SelectedCommentsMenu 
                           open={selectedComments.length > 0}
                           deselectAllComments={deselectAllComments}
                           selectAllComments={selectAllComments}
                           numSelected={selectedComments.length}
                           goToExport={() => setOpenExport(true)}
                           allSelected={allSelected}
                           search={search}
                           viewportWidth={viewportWidth}
                            />

                        <ExportCommentsDialog
                          selectedComments={selectedComments}
                          open={openExport}
                          onClose={() => setOpenExport(false)}
                          isMobile={viewportWidth < 600}
                          screenshotSettings={screenshotSettings}
                         
                        >
                          <>
                            <Box
                              sx={{
                                p: 2,
                                border: "1px solid #999",
                                borderRadius: 8,
                                background: "#fefefe",
                              }}
                            >
                              <Box sx={{ p: 1 }}>
                                <ExportCommentsList
                                  isMobile={viewportWidth < 600}
                                  selectedComments={selectedComments}
                                  setSelectedComments={setSelectedComments}
                                  commentData={commentData}
                                  previewMode  
                                  removeComment={removeComment}
                                />
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                gap: "1em",
                              }}
                            >
                              <Button
                              variant="outlined"
                              color="secondary"
                              sx={{borderRadius:12}}
                                endIcon={<CopyAllOutlined />}
                                onClick={handleExportText}
                              >
                                Copy
                              </Button>
                              <Button
                               variant="contained"
                               color="primary"
                               sx={{borderRadius:12}}
                                endIcon={<ContentCopyOutlined />}
                                onClick={handleExportImage}
                              >
                                Export Image
                              </Button>
                            </Box>
                          </>
                        </ExportCommentsDialog>

                        <Box
                          ref={screenShotRef}
                          sx={{
                            pl: 1, pr:1,
                            position: "absolute",
                            top: "-9999px",
                            left: "-9999px",
                          }}
                        >
                          {selectedComments
                            ? selectedComments.map((entry, index) => (
                                <Box sx={{ mr: 2, ml: 2, width: "500px" }}>

                                  
                                  <TikTokComment
                                  screenShot
                                  comment={commentData.find(
                                    (c) => (c.postId == entry.postId && c.comment.id == entry.commentId)
                                  ).comment}
                               
                                
                                  />

                                  {selectedComments.length > 1 &&
                                  index < selectedComments.length - 1 ? (
                                    <Divider variant="middle" />
                                  ) : null}
                                </Box>
                              ))
                            : null}
                        </Box>

                        {searchedComments ? (
                          searchedComments.length == 0 ? (
                            <Box sx={{ m: 4 }}>
                              <Typography variant="h6" align="center">
                                {`No comments found.`}
                              </Typography>
                            </Box>
                          ) : null
                        ) : null}

                        {/* 
                    {(selectedGroup == null &&
                    sift[Object.keys(sift)[filter]].comments &&
                    Array.isArray(sift[Object.keys(sift)[filter]].comments)
                      ? sift[Object.keys(sift)[filter]].comments
                      : sift[Object.keys(sift)[filter]].groupings &&
                        typeof sift[Object.keys(sift)[filter]].groupings ===
                          "object" &&
                        !Array.isArray(
                          sift[Object.keys(sift)[filter]].groupings
                        )
                      ? sift[Object.keys(sift)[filter]].groupings[selectedGroup]
                      : []
                    )
                    .slice(0,4)
                    .map((highlighted) => (
                      <Box sx={{ m: 2 }}>
                        <TikTokComment
                          comment={commentData.find(
                            (comment) => comment.commentId === highlighted
                          )}
                        />

                        <Divider variant="middle" />
                      </Box>
                    ))} */}
                      </Box>
                    </Box>
                  ) : null}
                </Box>
                </Box>
              </Grid>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                mt: 10,
              }}
            >
              <Typography variant="h6" align="center">
                {" "}
                This page is not available. 
              </Typography>

              <Card
                elevation={0}
                sx={{ borderRadius: "12px", background: "#EEEAFF", p: 3, m: 2 }}
              >
                <Typography variant="body1">
                 Please make sure you are signed the correct account and have access to this page.
                </Typography>
              </Card>
            </Box>
          )}
        </Grid>
      </ThemeProvider>

      {/* 

          {sifted ? 

          <SiftPreview siftData={siftData} /> : null} */}
    </div>
    </div>
  );
}

function BrandSuccessSummary(props) {
  // Use a regular expression to find sentence-ending punctuation and add a line break
  const stringWithLineBreaks = props.text.replace(/([.!?])\s/g, "$1\n\n");

  const [more, setMore] = React.useState(false);

  React.useEffect(() => {
    setMore(false);
  }, [props.text]);

  return (
    <>
      {stringWithLineBreaks.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          <span
            style={{
              display: index == 0 || more ? "inline" : "none",

              // fontSize: index == 0 ? "1.4rem" : null
            }}
          >
            {line}
            {index == 0 && !more ? (
              <span
                style={{
                  display:
                    stringWithLineBreaks.split("\n").length > 1
                      ? "inline"
                      : "none",
                }}
                onClick={() => setMore(!more)}
              >
                ..{" "}
                <span
                  style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}
                >
                  more
                </span>
              </span>
            ) : (
              " "
            )}
            <br />
          </span>
        </React.Fragment>
      ))}

      {more ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <span onClick={() => setMore(!more)}>
            <br />
            <span style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}>
              less
            </span>
          </span>
        </Box>
      ) : null}
    </>
  );
}

function MainSummary(props) {
  // Use a regular expression to find sentence-ending punctuation and add a line break
  const stringWithLineBreaks = props.text.replace(/([.!?])\s/g, "$1\n\n");

  const [more, setMore] = React.useState(false);

  React.useEffect(() => {
    // stringWithLineBreaks.split("\n").length > 2 ? setMore(true) :
    setMore(false);
  }, [props.text]);

  return (
    <>
      {stringWithLineBreaks.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          <span
            style={{
              display: index == 0 || more ? "inline" : "none",

              // fontSize: index == 0 ? "1.4rem" : null
            }}
          >
            {line}
            {index == 0 && !more ? (
              <span
                style={{
                  display:
                    stringWithLineBreaks.split("\n").length > 1
                      ? "inline"
                      : "none",
                }}
                onClick={() => setMore(!more)}
              >
                ..{" "}
                <span
                  style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}
                >
                  more
                </span>
              </span>
            ) : (
              " "
            )}
            <br />
          </span>
        </React.Fragment>
      ))}

      {more ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <span onClick={() => setMore(!more)}>
            <br />
            <span style={{ fontWeight: 900, color: "#111", cursor: "pointer" }}>
              less
            </span>
          </span>
        </Box>
      ) : null}
    </>
  );
}

const CustomSlider = muiStyled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    height: 34,
    width: 34,
    backgroundColor: "transparent",
    // border: `3px solid ${theme.palette.primary.main}`,
    // marginTop: -8,
    // marginLeft: -12,
    // '&:focus, &:hover, &$active': {
    //   boxShadow: 'inherit',
    // },
  },

  "& .MuiSlider-valueLabel": {
    left: "calc(-50% + 6px)",
    background: "transparent",
  },

  // '& .MuiSlider-track': {
  //   height: 5,
  //   borderRadius: 5,
  // },

  "& .MuiSlider-rail": {
    height: 25,
    borderRadius: 30,
    opacity: 1,
    background: "linear-gradient(to right, #B7FF70, #FFEC3E, #FF7A7A)",
  },
}));

function timeAgo(isoString) {
  const date = new Date(isoString).getTime();
  const now = new Date().getTime();
  const timeDifference = now - date;

  if (timeDifference < 60000) {
    // Less than a minute
    //   const seconds = Math.floor(timeDifference / 1000);
    //   return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    return "just now";
  } else if (timeDifference < 3600000) {
    // Less than an hour
    const minutes = Math.floor(timeDifference / 60000);
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (timeDifference < 86400000) {
    // Less than a day
    const hours = Math.floor(timeDifference / 3600000);
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else {
    const days = Math.floor(timeDifference / 86400000);
    return `${days} day${days === 1 ? "" : "s"} ago`;
  }
}

const CardContainer = muiStyled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
});

const HighlightCard = (props) => {
  const { highlight, setSearch, search, viewportWidth } = props;

  // React.useEffect(() => {
  //   setWidth(highlightSection.current.getBoundingClientRect().width);

  //   if (ref.current) {
  //     setCardWidth(ref.current.getBoundingClientRect().width);
  //   }
  // }, [ref, viewportWidth]);

  const searched = search.trim() == highlight.trim();

  // console.log("cardWidth", cardWidth);
  // console.log("width", width);
  // console.log("cardWidth * 12/width", Math.ceil((cardWidth * 12) / width));

  return (
    // <Grid item xs={Math.ceil((((cardWidth+10) * 12)/width))}>

<ButtonBase
          onClick={() => { searched ? setSearch("") : setSearch(highlight.trim())}}
          sx={{ borderRadius: 30 }}
        >
      
      {/* <Card
        ref={ref}
        elevation={0}
        sx={{ borderRadius: 30,
          background: searched ? "#4828C5" : "#ffffff",
         
          // background: "#ffffff",
          // color: "#4828C5",
         
          border: "2px solid #eeeeee"}}
      >
      
        <Typography align="center" variant="body1" sx={{ fontSize: ".8rem",  color: searched ? "#fff" : "#000",  p: 1,
          pl:2,
          pr:2,}}>
          {highlight.trim()}
        </Typography>
      </Card> */}
      <Chip label={highlight.trim()} variant="outlined"  sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                          padding: '8px 12px',
                        
                        },
                        background: searched ? "#4828C5" : "#ffffff",
                        color: searched ? "#fff" : "#000",
                        borderRadius: 20,
                      }}/>
      </ButtonBase>
    // </Grid>
  );
};


const TagCard = (props) => {
  const ref = React.useRef(null);
  const gridRef = React.useRef(null);
  const [cardWidth, setCardWidth] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const { tag, viewportWidth } = props;

  React.useEffect(() => {
    setWidth(gridRef.current.parentNode.getBoundingClientRect().width);
    console.log("parentNode", gridRef.current.parentNode);

    if (ref.current) {
      setCardWidth(ref.current.getBoundingClientRect().width);
      console.log("cardWidth", ref.current.getBoundingClientRect());
    }
  }, [ref, viewportWidth]);

  // console.log("cardWidth", cardWidth);
  // console.log("width", width);
  // console.log("cardWidth * 12/width", Math.ceil((cardWidth * 12) / width));

  return (
    <Grid item xs={Math.ceil((cardWidth * 12) / width)} ref={gridRef}>
      {/* <Card
        elevation={0}
        ref={ref}
        sx={{
          borderRadius: 30,
          background: "#ffffff",
          border: "2px solid #eeeeee",
        }}
      >
        <Typography
          align="center"
          variant="h6"
          sx={{
            fontSize: ".8rem",
            color: "#666",
            fontWeight: 700,
            p: 1,
          }}
        >
          {tag.trim()}
        </Typography>
      </Card> */}

      <Chip label={tag} ref={ref}/>
    </Grid>
  );
};

const CommentGrouping = ({
  groupPhrase,
  commentGroup,
  commentSection,
  commentData,
  selectedGroup,
  setSelectedGroup,
}) => {
  const ref = React.useRef(null);
  const [cardWidth, setCardWidth] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setWidth(commentSection.current.getBoundingClientRect().width);

    if (ref.current) {
      setCardWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref]);

  console.log("cardWidth", cardWidth);
  console.log("width", width);
  console.log("cardWidth * 12/width", Math.ceil((cardWidth * 12) / width));

  let selected = selectedGroup === groupPhrase;

  let maxAvatars = 3;

  return (
    <Grid item xs={Math.ceil((cardWidth * 12) / width) + 1}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <ButtonBase
          onClick={() => {
            if (selected) {
              setSelectedGroup(null);
            } else {
              setSelectedGroup(groupPhrase);
            }
          }}
          sx={{ borderRadius: 30 }}
        >
          <Card
            elevation={0}
            sx={{
              borderRadius: 30,
              background: selected ? "#4828C5" : "#ffffff",
              color: selected ? "#fff" : "#4828C5",
              p: 0.5,
              border: "2px solid #eeeeee",
            }}
            ref={ref}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                p: 0.5,
              }}
            >
              <AvatarGroup
                max={maxAvatars}
                total={
                  commentGroup.length > maxAvatars
                    ? maxAvatars
                    : commentGroup.length
                }
                sx={{
                  "& .MuiAvatar-root": {
                    width: 25,
                    height: 25,
                    fontSize: 10,
                    background: "#F3F2FF",
                    color: "#4828C5",
                  },
                }}
              >
                {commentGroup
                  .slice(
                    0,
                    commentGroup.length > maxAvatars
                      ? maxAvatars
                      : commentGroup.length
                  )
                  .map((commentId) => (
                    <DynamicAvatar
                      comment={commentData.find(
                        (comment) => comment.commentId === commentId
                      )}
                    />
                  ))}
              </AvatarGroup>

              {commentGroup.length > 3 ? (
                <Avatar
                  sx={{
                    marginLeft: "-5px",
                    zIndex: 1,
                    width: 25,
                    height: 25,
                    fontSize: 10,
                    background: "#F3F2FF",
                    color: "#4828C5",
                  }}
                >
                  <b> + {commentGroup.length - maxAvatars} </b>
                </Avatar>
              ) : null}

              <Typography
                align="center"
                variant="body1"
                sx={{
                  ml: 1,
                  color: selected ? "#fff" : "#4828C5",
                  fontSize: ".8rem",
                  fontStyle: "italic",
                }}
              >
                "{groupPhrase.trim()}..."
              </Typography>
            </Box>
          </Card>
        </ButtonBase>
      </Box>
    </Grid>
  );
};

const ExportCommentsList = (props) => {
  const { selectedComments, setSelectedComments } = props; 

  const onDragEnd = (result) => {
    console.log("result", result);
    if (!result.destination) return; // Dragged outside the droppable area

    const items = Array.from(selectedComments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedComments(items);
  };


  return (
    <div>
      <CommentsList selectedComments={selectedComments} onDragEnd={onDragEnd} {...props}/>
    </div>
  );
};


const CommentsList = ({ commentData, selectedComments, onDragEnd, isMobile, removeComment }) => {
  
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="comments" direction="vertical">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            {selectedComments
              ? selectedComments.map((entry, index) => (
                  <Draggable key={entry.commentId.toString()} draggableId={entry.commentId.toString()} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...(selectedComments.length < 2 ? {} : provided.draggableProps)}
                        {...provided.dragHandleProps}

                        sx={{p:isMobile ? 1: 2, background:"#fefefe", borderRadius:4, top:"auto !important", left:"auto !important", right:"auto !important", bottom:"auto !important"}}
                      >
                        <DeselectPopover removeComment={() => removeComment(entry)}>
                          
                        <Box sx={{display:"flex", position:"relative", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width: "100%", minWidth:"300px", maxWidth:"600px", }} >
                  
                                          <Box sx={{flexGrow:1}}>

                        <TikTokComment
                        isMobile={isMobile}
                        dragMode={selectedComments.length > 1}
                        comment={commentData.find(
                          (c) => (c.postId == entry.postId && c.comment.id == entry.commentId)
                        ).comment}
                       
                          removeComment={removeComment}
                        
                        />

                        {/* {hover ? 
                        <div style={{position:"absolute", top:0, right:0, zIndex:10}}>
                          <IconButton onClick={() => removeComment(selectedId)}>
                            <Close />
                          </IconButton>
                        </div>
                        : null} */}


                        </Box>
                        </Box></DeselectPopover>
             
                        {selectedComments.length > 1 && index < selectedComments.length - 1 ? (
                          <Divider variant="middle" sx={{marginLeft:isMobile ? "40px" : "60px"}}/>
                        ) : null}
                      </Box>
                      
                    )}
                  </Draggable>
                ))
              : null}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

function DynamicAvatar(props) {
  const { comment } = props;

  console.log("comment", comment);

  return (
    <Avatar
      sx={{ width: 25, height: 25, zIndex: 10 }}
      alt={comment.username}
      src={comment.avatarlink}
    />
  );
}

const FilterSelectorButton = (props) => {
  const { filter, setFilter } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  return (
    <div>
      <ButtonBase onClick={handleOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: ".5em",
            borderRadius: 30,
            background: "#4828C5",
            color: "#fff",
            padding: "4px 10px 4px 10px",
            border: "2px solid #4828C5",
            fontWeight: 900,
          }}
        >
          <Typography color="white" align="center" variant={"subtitle2"}>
            {sifterOptions[filter].label}
          </Typography>

          <Tune />
        </Box>

        {/* <Grid container spacing={2} alignItems="center">
        {sifterOptions.map((sifter, index) => (
          <Grid item xs={4}>

            <Typography color="primary" align="center" onClick={() => setFilter(index)} variant={"subtitle2"}
              sx={{borderRadius:30, background : filter == index ? "#4828C5" : "#ffffff", color: filter == index ? "#fff" : "#4828C5", padding:"6px 0px 6px 0px", border:"2px solid #4828C5",
                fontWeight: filter == index ? 900 : 700 }} >
                
              {sifter.label}

            </Typography>

          </Grid>
        ))}
      </Grid> */}
      </ButtonBase>
      <Popper open={open} anchorEl={anchorEl} placement="top-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <Box display="flex" flexDirection="column" p={2}>
              <Box sx={{ alignItems: "center" }}>
                <Typography variant="subtitle1">Change Filter</Typography>
                <Typography variant="subtitle2">
                  Filter your comments by sentiment.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1em",
                  pt: 2,
                  cursor: "pointer",
                }}
              >
                {sifterOptions.map((sifter, index) => (
                  <Grid item xs={4}>
                    <Typography
                      color="primary"
                      align="center"
                      variant={"subtitle2"}
                      onClick={() => handleFilterChange(index)}
                      sx={{
                        borderRadius: 30,
                        background: filter == index ? "#4828C5" : "#ffffff",
                        color: filter == index ? "#fff" : "#4828C5",
                        padding: "4px 10px 4px 10px",
                        border: "2px solid #4828C5",
                        fontWeight: filter == index ? 900 : 700,
                      }}
                    >
                      {sifter.label}
                    </Typography>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const GlassMorphicPaperComponent = React.forwardRef(function GlassMorphicPaperComponent(
  props,
  ref
) {
  return (
    <Paper
      ref={ref}
      elevation={0}
      sx={{
        borderRadius: 8,
        background: "rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(12px)",
        // border: "1px solid rgba(255, 255, 255, 0.18)",
        // boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
        padding: "1em",
        margin: ".5em",
      }}
    >
      {props.children}
    </Paper>
  );
});

const ExportCommentsDialog = (props) => {

  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}
    PaperComponent={GlassMorphicPaperComponent}
    disableEnforceFocus
    >

      {props.children}

    </Dialog>
  );
};

function BrandSuccessCheckSection(props) {
  const { brandSuccess } = props;
  const [hidden, setHidden] = React.useState(false);

  console.log("brandSuccess", brandSuccess);

  const vibeScore = brandSuccess.vibeScore
    ? parseFloat(brandSuccess.vibeScore)
    : 0;
  const sentimentScore = brandSuccess.sentimentData.sentimentScore
    ? parseFloat(brandSuccess.sentimentData.sentimentScore).toFixed(1)
    : 0;
  const relevanceScore = brandSuccess.relevanceData.relevanceScore
    ? parseFloat(brandSuccess.relevanceData.relevanceScore).toFixed(1)
    : 0;
  const consensusScore = brandSuccess.brandConsensusData.consensusScore
    ? parseFloat(brandSuccess.brandConsensusData.consensusScore).toFixed(1)
    : 0;

  const vibeColor =
    vibeScore >= 7
      ? "#089616"
      : vibeScore >= 5
      ? "#74d52e"
      : vibeScore >= 3
      ? "#ffb93d"
      : "#fb3e3e";

  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          ml: 3,
          mt: 3,
          mr: 2,
          mb: 1,
        }}
      >
        <Tooltip title="The brand success score determines the attitude of the audience in the comment section in relation to the sponsor of this post.">
          <Typography variant="h6" align="center">
            Brand Success{" "}
          </Typography>
        </Tooltip>
        <AutoAwesome color="primary" sx={{ mt: -1.5, height: 12, width: 12 }} />

        <Box sx={{ flexGrow: 1 }} />

        <ButtonBase onClick={() => setHidden(!hidden)}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 12,
              background:
                vibeScore >= 7
                  ? "#089616"
                  : vibeScore >= 5
                  ? "#74d52e"
                  : vibeScore >= 3
                  ? "#ffb93d"
                  : "#fb3e3e",
              color: "#fff",
              padding: "0px 15px 0px 15px",
              display: hidden ? "flex" : "none",
            }}
          >
            <Typography variant="body2" fontWeight={700} fontSize={16}>
              {vibeScore}
            </Typography>
          </Card>

          <IconButton onClick={() => setHidden(!hidden)}>
            {hidden ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </ButtonBase>
      </Box> */}

      {hidden ? null : (
        <>
          {/* <ScoreBar score={vibeScore} color={vibeColor} />

          <Box sx={{ m: 1, mt: 5 }}>
            <Typography variant="h6" align="center">
              {vibeScore >= 7
                ? "The vibes are immaculate!"
                : vibeScore >= 5
                ? "The vibes are good!"
                : vibeScore >= 3
                ? "The vibes are alright."
                : "The vibes are off..."}
            </Typography>
            <Typography variant="body2" align="center">
              {vibeScore >= 7
                ? "You have a deeply engaged and passionate audience."
                : vibeScore >= 5
                ? "The comment section shows an engaged audience."
                : vibeScore >= 3
                ? "Solid engagement, but with some fiesty comments here!"
                : "This comment section is a rough one!"}
            </Typography>
          </Box> */}

          <br/>

          <Box
            sx={{
              flexDirection: "row",
              justifyContent: "flex-stretch",
              alignItems: "center",
              mb: 1,
              display: hidden ? "none" : "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                ml: 3,
                mr: 3,
                flexGrow: 1,
                gap: ".3rem",
              }}
            >
              <DataBar
                label="SENTIMENT"
                score={sentimentScore}
                color="#4828C5"
                description="Sentiment is a measure of how positive or negative the comments are. A higher sentiment score means that the comments are more positive. A lower sentiment score means that the comments are more negative."
              />

              <DataBar
                label="RELEVANCE"
                score={relevanceScore}
                color="#694aff"
                description="Relevance is a measure of how relevant the comments are to the video. A higher relevance score means that the comments are more relevant to the video."
              />

              <DataBar
                label="CONSENSUS"
                score={consensusScore}
                color="#b1bcff"
                description={
                  brandSuccess.consensusData && brandSuccess.consensusData.info
                    ? brandSuccess.consensusData.info
                    : `Consensus is a measure of how much the comments agree with each other. A higher consensus score means that the comments are more similar to each other. A lower consensus score means that the comments are more diverse.`
                }
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ml: 3,
                mr: 3,
                // width: "100px",
                position: "relative",
              }}
            >
              <Avatar
                sx={{
                  width: "120px",
                  height: "120px",
                  color: vibeColor,
                  background: "transparent",
                  fontSize: "40px",
                  fontWeight: 900,
                }}
              >
                {vibeScore}
              </Avatar>

              {/* <Typography
                variant="body2"
                fontWeight={700}
                fontSize={25}
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "72%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {vibeScore >= 7 ? "✨" : vibeScore >= 3 ? "" : ""}
              </Typography> */}

              <Scorer score={parseFloat(vibeScore)} color={vibeColor} />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

function VibeCheckSection(props) {
  const { vibeCheck, exportVibeScoreSection } = props;
  const [hidden, setHidden] = React.useState(false);

  console.log("vibeCheck", vibeCheck);

  const vibeScore = vibeCheck.vibeScore ? parseFloat(vibeCheck.vibeScore) : 0;
  const sentimentScore = vibeCheck.sentimentData.sentimentScore
    ? parseFloat(vibeCheck.sentimentData.sentimentScore).toFixed(1)
    : 0;
  const relevanceScore = vibeCheck.relevanceData.relevanceScore
    ? parseFloat(vibeCheck.relevanceData.relevanceScore).toFixed(1)
    : 0;
  const consensusScore = vibeCheck.consensusData.consensusScore
    ? parseFloat(vibeCheck.consensusData.consensusScore).toFixed(1)
    : 0;

  const vibeColor =
    vibeScore >= 7
      ? "#089616"
      : vibeScore >= 5
      ? "#74d52e"
      : vibeScore >= 3
      ? "#ffb93d"
      : "#fb3e3e";

  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          ml: 3,
          mt: 3,
          mr: 2,
          mb: 1,
        }}
      >
        <Tooltip title="The vibe check is a score from 1-10 that measures how much the audience is vibing in the comments section.">
          <Typography variant="h6" align="center">
            Vibe Check{" "}
          </Typography>
        </Tooltip>
        <AutoAwesome color="primary" sx={{ mt: -1.5, height: 12, width: 12 }} />

        <Box sx={{ flexGrow: 1 }} />

        <ButtonBase onClick={() => setHidden(!hidden)}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 12,
              background:
                vibeScore >= 7
                  ? "#089616"
                  : vibeScore >= 5
                  ? "#74d52e"
                  : vibeScore >= 3
                  ? "#ffb93d"
                  : "#fb3e3e",
              color: "#fff",
              padding: "0px 15px 0px 15px",
              display: hidden ? "flex" : "none",
            }}
          >
            <Typography variant="body2" fontWeight={700} fontSize={16}>
              {vibeScore}
            </Typography>
          </Card>

          <IconButton onClick={() => setHidden(!hidden)}>
            {hidden ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </ButtonBase>
      </Box>
       */}

              <Box >
        <Typography variant="h6" align="left">
        Comment Section Overview
        </Typography>
       </Box>



      {hidden ? null : (
        <div id="vibeScoreSection">



       <Box sx={{pt:2, pb: 1, display:"flex", flexDirection:"row", alignItems:"center"}} >
       <SpeedOutlined sx={{fontSize: 20, color:"#888", mr: .5 }}/>

        <Typography variant="body2" align="left" sx={{color:"#888", fontWeight:700}}>
      Vibe Score
        </Typography>
        <Box sx={{flexGrow:1}}/>
                        {/* <IconButton onClick={exportVibeScoreSection} size="small">
                          <FileCopyOutlined sx={{fontSize:16, color:"#888"}}/>
                        </IconButton> */}
       </Box>

          {/* <ScoreBar score={vibeScore} color={vibeColor} /> */}

          <Box sx={{ pt:1, pb:1 }}>
            <Typography variant="body2" align="left">
              <b>
              {vibeScore >= 7
                ? "The vibes are immaculate! "
                : vibeScore >= 5
                ? "The vibes are good! "
                : vibeScore >= 3
                ? "The vibes are alright. "
                : "The vibes are off... "}
           </b>
              {vibeScore >= 7
                ? "You have a deeply engaged and passionate audience."
                : vibeScore >= 5
                ? "The comment section shows an engaged audience."
                : vibeScore >= 3
                ? "Solid engagement, but with some fiesty comments here!"
                : "This comment section is a rough one!"}
            </Typography>
          </Box>

          <Box
            sx={{
              flexDirection: "row",
              justifyContent: "flex-stretch",
              alignItems: "center",
              mb: 1,
              display: hidden ? "none" : "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                // ml: 3,
                // mr: 3,
                pt:1, pb:1,
                flexGrow: 1,
                gap: ".3rem",
              }}
            >
              <DataBar
                label="SENTIMENT"
                score={sentimentScore}
                color="#4828C5"
                description="Sentiment is a measure of how positive or negative the comments are. A higher sentiment score means that the comments are more positive. A lower sentiment score means that the comments are more negative."
              />

              <DataBar
                label="RELEVANCE"
                score={relevanceScore}
                color="#4828C5"
                description="Relevance is a measure of how relevant the comments are to the video. A higher relevance score means that the comments are more relevant to the video."
              />

              <DataBar
                label="CONSENSUS"
                score={consensusScore}
                color="#4828C5"
                description={
                  vibeCheck.consensusData && vibeCheck.consensusData.info
                    ? vibeCheck.consensusData.info
                    : `Consensus is a measure of how much the comments agree with each other. A higher consensus score means that the comments are more similar to each other. A lower consensus score means that the comments are more diverse.`
                }
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                ml: 3,
                mr: 3,
                // width: "100px",
                position: "relative",
              }}
            >
              <Avatar
                sx={{
                  width: "120px",
                  height: "120px",
                  color: vibeColor,
                  background: "transparent",
                  fontSize: "40px",
                  fontWeight: 900,
                }}
              >
                {vibeScore}
              </Avatar>

              {/* <Typography
                variant="body2"
                fontWeight={700}
                fontSize={25}
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "72%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {vibeScore >= 7 ? "✨" : vibeScore >= 3 ? "" : ""}
              </Typography> */}

              <Scorer
                score={parseFloat(vibeCheck.vibeScore)}
              />
            </Box>
          </Box>
        </div>
      )}
    </>
  );
}

function ScoreBar(props) {
  const { score, color } = props;

  React.useEffect(() => {
    let marker = document.getElementById("marker");
    let scorebar = document.getElementById("scorebar");

    if (marker && scorebar) {
      let factor = (score) => {
        if (score <= 3) {
          return 12;
        } else if (score > 3 && score < 5) {
          return 10.6;
        } else if (score >= 5 && score < 6) {
          return 10;
        } else if (score >= 6 && score < 7) {
          return 9.6;
        } else if (score >= 7 && score < 8) {
          return 9.3;
        } else if (score >= 8 && score < 9) {
          return 9.6;
        } else if (score >= 9) {
          return 10;
        }
      };

      marker.style.left = `${
        (scorebar.getBoundingClientRect().width * score) / factor(score) - 15
      }px`;
    }
  }, [score]);

  return (
    <>
      <Box sx={{ display: "flex", position: "relative", mr: 5, ml: 5 }}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score < 3 ? "#fb3e3e" : "#ccc"}
              fontWeight={700}
              // fontSize={score < 3 ? 14 : 12}
              fontSize={12}
            >
              OFF
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score >= 3 && score < 5 ? "#ffb93d" : "#ccc"}
              fontWeight={700}
              // fontSize={score >= 3 && score < 5 ? 14 : 12}
              fontSize={12}
            >
              ALRIGHT
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score >= 5 && score < 7 ? "#74d52e" : "#ccc"}
              fontWeight={700}
              // fontSize={score >= 5 && score < 7 ? 14 : 12}
              fontSize={12}
            >
              GOOD
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              align="center"
              color={score >= 7 && score < 10 ? "#089616" : "#ccc"}
              fontWeight={700}
              fontSize={12}
              // fontSize={score >= 7 && score < 10 ? 14 : 12}
            >
              IMMACULATE
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", position: "relative", mr: 5, ml: 5, mb: 3 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            borderRadius: 30,
            overflow: "hidden",
          }}
          id="scorebar"
        >
          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#fb3e3e",
                opacity: 0.8,
              }}
            />
          </Grid>
          {/* <Box sx={{ height: 10, width: "1%", background: "#000" }} /> */}

          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#ffb93d",
                opacity: 0.8,
              }}
            />
          </Grid>
          {/* <Box sx={{ height: 10, width: "1%", background: "#000" }} /> */}
          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#74d52e",
                opacity: 0.8,
              }}
            />
          </Grid>
          {/* <Box sx={{ height: 10, width: "1%", background: "#000" }} /> */}

          <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                height: 10,
                width: "100%",
                background: "#089616",
                opacity: 0.8,
              }}
            />
          </Grid>
        </Grid>

        <Box
          alignItems="center"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ height: 10, width: "2px", background: "transparent" }} />

          <Box sx={{ height: 10, width: "2px", background: "#fff" }} />

          <Box sx={{ height: 10, width: "2px", background: "#fff" }} />

          <Box sx={{ height: 10, width: "2px", background: "#fff" }} />

          <Box sx={{ height: 10, width: "2px", background: "transparent" }} />
        </Box>

        <Box
          alignItems="center"
          sx={{
            position: "absolute",
            top: 12,
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "10px",
            }}
          >
            0
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "20px",
            }}
          >
            3
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "20px",
            }}
          >
            5
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "20px",
            }}
          >
            7
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: 700,
              width: "10px",
            }}
          >
            10
          </Box>
        </Box>

        <svg
          width="30"
          height="30"
          viewBox="0 0 150 149.999998"
          preserveAspectRatio="xMidYMid meet"
          version="1.0"
          id="marker"
          style={{
            position: "absolute",
            top: 3,
            left: 0,
            filter: "drop-shadow( 0px 0px 2px rgba(0, 0, 0, .4))",
          }}
        >
          <path
            fill="rgba(0,0,0,0)"
            d="M 52.957031 143.25 L 52.957031 4.238281 L 97.046875 4.238281 L 97.046875 143.25 Z M 52.957031 143.25 "
            fillOpacity="1"
            fillRule="nonzero"
          />
          <path
            fill={color}
            stroke="#000"
            strokeWidth={5}
            d="M 52.957031 26.285156 L 52.957031 143.25 L 97.046875 143.25 L 97.046875 26.285156 L 75 4.238281 Z M 52.957031 26.285156 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </svg>
      </Box>
    </>
  );
}

function DataBar(props) {
  const { label, score, color, description } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        cursor: "pointer",
      }}
      onClick={() => setOpen(!open)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography fontSize={10} fontWeight={700}>
          {label}
        </Typography>

        <InfoOutlined
          sx={{
            cursor: "pointer",
            ml: 0.5,
            mb: 0.4,
            width: 15,
            height: 15,
            color: "#666",
          }}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{ width: "85%" }}>
          <LinearProgress
            variant="determinate"
            value={score * 10}
            sx={{
              width: "100%",
              borderRadius: 30,
              height: 20,
              background: "#EEE",
              border: "transparent",
              "& .MuiLinearProgress-barColorPrimary": { background: color },
            }}
          />
        </Box>

        <Box sx={{ ml: 1 }}>
          <Typography variant="body2" fontWeight={700} fontSize={14}>
            {score}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ m: 1, display: open ? "flex" : "none" }}>
        <Grow in={open}>
          <Typography variant="body2" align="left" sx={{ fontStyle: "italic" }}>
            {description}
          </Typography>
        </Grow>
      </Box>
    </Box>
  );
}

const Scorer = ({ score }) => {

  const color =
  score >= 7
    ? "#089616"
    : score >= 5
    ? "#74d52e"
    : score >= 3
    ? "#ffb93d"
    : "#fb3e3e";

  const svgStyle = {
    height: "140px",
    width: "140px",
    position: "absolute",
    top: 0,
    left: 0,
    transform: "rotate(-85deg)",
    fill: "transparent",
  };

  const circleStyle = {
    fill: "transparent",
  };

  const progressStyle = {
    stroke: "hsl(161, 100%, 43%)",
    strokeWidth: "14px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    // Change number value to shift the progress bar // 130 = 0, 440 = 100
    strokeDashoffset: score ? 440 - (score * 440) / 100 : 440,
  };

  const progressBorderStyle = {
    stroke: color,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    // Change number value to shift the progress bar // 130 = 100, 440 = 0
    strokeDashoffset: score == 10 ? 130 : 440 - (310 / 10) * score + 8,
  };

  const trackStyle = {
    stroke: color,
    opacity: 0.5,
    strokeWidth: "20px",
    strokeLinecap: "round",
    strokeDasharray: "440",
    strokeDashoffset: "130", // Change number value to shift the progress bar
  };

  const borderTrackStyle = {
    stroke: "hsl(0, 0%, 93%)",
    strokeWidth: "12px",
  };

  return (
    <svg style={svgStyle}>
      {/* <circle id="border-track" cx="75" cy="75" r="65" style={circleStyle} /> */}
      <circle id="track" cx="80" cy="60" r="50" style={trackStyle} />
      {/* <circle id="progress" cx="75" cy="75" r="65" style={{ ...circleStyle, ...progressStyle }} /> */}
      <circle
        id="progress-border"
        cx="80"
        cy="60"
        r="50"
        style={{ ...circleStyle, ...progressBorderStyle }}
      />
    </svg>
  );
};

const TikTokEmbed = () => {
  const tikTokEmbedCode = `
    <!-- Paste your TikTok embed code here -->
    <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@scout2015/video/6718335390845095173" data-video-id="6718335390845095173" style="max-width: 605px;min-width: 325px;">
      <section>
        <a target="_blank" title="@scout2015" href="https://www.tiktok.com/@scout2015?refer=embed">@scout2015</a> Scramble up ur name &#38; I’ll try to guess it😍❤️
        <a title="foryoupage" target="_blank" href="https://www.tiktok.com/tag/foryoupage?refer=embed">#foryoupage</a>
        <a title="petsoftiktok" target="_blank" href="https://www.tiktok.com/tag/petsoftiktok?refer=embed">#petsoftiktok</a>
        <a title="aesthetic" target="_blank" href="https://www.tiktok.com/tag/aesthetic?refer=embed">#aesthetic</a>
        <a target="_blank" title="♬ original sound - tiff" href="https://www.tiktok.com/music/original-sound-6689804660171082501?refer=embed">♬ original sound - tiff</a>
      </section>
    </blockquote>
    <script async src="https://www.tiktok.com/embed.js"></script>
  `;

  return <div dangerouslySetInnerHTML={{ __html: tikTokEmbedCode }} />;
};


function formatNumber(number) {
  if (isNaN(number)) {
    return "Invalid number";
  }
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1).replace(/\.0$/, '') + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1).replace(/\.0$/, '') + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1).replace(/\.0$/, '') + "K";
  } else {
    return number.toString();
  }
}


function ExportAsButton(props) {

  const { selectedComments, imageRef, watermarkRef, setOpenExport, openExport} = props;

  console.log("selectedComments", selectedComments);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState('');

  const handleExportText = () => {
    // Select the data to export
    const dataToExport = "Selected data here";

    // Copy data to clipboard
    navigator.clipboard.writeText(dataToExport)
      .then(() => alert('Data copied to clipboard'))
      .catch(error => console.error('Unable to copy:', error));
  };

  const handleExportImage = () => {
    handleDownload(imageRef, watermarkRef);
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  return (
   
    //  <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
    //   <ClickAwayListener onClickAway={handleClosePopper}>

    //     <Box sx={{p:2, display:"flex", flexDirection:"column", background:"#fefefe"}}>
        <Button 
      disabled={!(selectedComments.length > 0)}
      color="primary"
        sx={{fontWeight: 700, borderRadius:12}}
      onClick={() => setOpenExport(!openExport)}>Export</Button> 
      //   </Box>
      //   </ClickAwayListener>

      // </Popper> 

  );
}

async function handleDownload(ref, watermarkRef, username){

  //delay 500ms
  
  
  console.log("ref", ref);
  if (ref.current) {
    // await new Promise(resolve => setTimeout(resolve, 1000));

  //   const images = ref.current.querySelectorAll('img');

  //   images.forEach(async img => {
  //     if(img.getAttribute('src') !== null){
  //       console.log("img", img.getAttribute('src'));
  //     await fetch(img.getAttribute('src'), {mode: 'cors'});
  //  }});

    // const images = ref.current.querySelectorAll("img");
    // const imagePromises = Array.from(images).map((image) => {
    //   return new Promise((resolve, reject) => {
    //     image.onload = resolve;
    //     image.onerror = reject;
    //   });
    // });

    // console.log("imagePromises", imagePromises);

    // await Promise.all(imagePromises);

    const images = ref.current.getElementsByTagName('img');
    console.log("images", images);
    const allImagesLoaded = Array.from(images).every(img => img.complete);


    if (!allImagesLoaded) {
      console.log("Images not loaded");
      return;
    }
    else{
      console.log("Images loaded");
    
      // ref.current.style.position = "relative";
      // ref.current.style.height = "200px";
      // ref.current.style.width = "200px";
      


    // const watermarkImage = new Image();
    // watermarkImage.src = "/siftsy_logo.png"; // Replace with the path to your local watermark image
        
      let canvas = await html2canvas(ref.current, {useCORS:true, allowTaint:true, logging:true});


      let ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = "/siftsy_logo.png";

      img.onload = () => {
        ctx.drawImage(canvas, 0, 0);
        ctx.drawImage(img, 10, 10);
      }
      

      // const canvasWithWatermark = addWatermark(canvas, watermarkRef);


        const link = document.createElement("a");
        link.href = canvas.toDataURL();
        link.download = `${username}'s_comments.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
          
    
  }  }
};

const addWatermark = (canvas, ref) => {
  const ctx = canvas.getContext("2d");
  


  // Draw the main content on the canvas

  // Add watermark image
    //draw the image on the top left corner 
    // ctx.drawImage(canvas, 0, 0);
  
    // ctx.drawImage(ref.current, 10, 10);

    

  

  return canvas;
};


function SelectedCommentsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { open, deselectAllComments, numSelected, goToExport, allSelected, selectAllComments, search, viewportWidth } = props;

  React.useEffect(() => {
    setAnchorEl(null);
    setAnchorEl(document.getElementById('comment-section'));
  }, [search]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverPosition = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };


  return (
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {props.children}
      </div>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableEnforceFocus
        PaperProps={{
          sx: { borderRadius: 10, padding: 1, background:"#111", ...popoverPosition},
        }}
       
      >
        <Box sx={{
          pointerEvents: 'auto',
          display: 'flex',
          flexDirection:"row",
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}>
          <IconButton onClick={deselectAllComments}><Close sx={{color:"#fff"}}/></IconButton>
          <Typography variant="body1" sx={{fontWeight:700, color:"#fff"}}>{`${numSelected} selected`}</Typography>

                            <Box sx={{ml:viewportWidth < 500 ? 1 : 3}}/>

          {!allSelected ?
                            <Button
                            variant="outlined"
                              color="secondary"
                              size="small"
                              sx={{borderRadius:12, position:"relative"}}
                              onClick={selectAllComments}
                              
                            >
                               Select all
                         
                            </Button> : null}

                            {viewportWidth < 500 ? <IconButton onClick={goToExport} sx={{color:"#fff", pl:2, pr:2}}><IosShareOutlined/></IconButton> :
          <Button onClick={goToExport} sx={{color:"#fff", pl:2, pr:2}} endIcon={<IosShareOutlined/>}>Export </Button>
          }
          </Box>
      </Popover>
    </div>
  );
}

function DeselectPopover(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handlePopoverClose = () => {
    console.log("handlePopoverClose");
    setAnchorEl(null);
  }

  const handleMouseLeave = () => {
    console.log("handleMouseLeave");
    setAnchorEl(null);
  }

  const handleDoubleClick = (event) => {
    if(anchorEl){
      setAnchorEl(null);
    }
    else{
      setAnchorEl(event.currentTarget);
    }
  }

  return (
    <div onDoubleClick={handleDoubleClick} onClick={handlePopoverClose} onMouseLeave={handleMouseLeave}>
    
      {props.children}
      
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onMouseLeave={handleMouseLeave}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { borderRadius: 10, padding: "0px 5px 0px 5px", background:"#fefefe"},
        }}
      >
        <Button color="error" size="small" onClick={props.removeComment} endIcon={<Close/>}>Remove </Button>
      </Popover>
    </div>
  );
}

function ListComments(commentData) {
  return Object.entries(commentData).flatMap(([postId, comments]) => {
      return comments.map(comment => ({ postId, comment }));
  });
}