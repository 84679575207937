import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
  } from "@mui/material";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import React, { useState } from "react";
  import {
    Add,
    Analytics,
    ArrowDownwardOutlined,
    ArrowDropDownOutlined,
    ArrowDropUpOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    AutoAwesome,
    BarChart,
    BarChartOutlined,
    Chat,
    CheckBoxOutlined,
    Close,
    CloseOutlined,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FolderOutlined,
    Group,
    GroupOutlined,
    GroupsOutlined,
    GroupsRounded,
    NextPlan,
    OpenInNew,
    ScoreOutlined,
    Settings,
    SpeedOutlined,
    TableView,
    UploadFileOutlined,
    VideoFileOutlined,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
  import Functions from "./Functions";
import GroupView from "./GroupView";
import { formatData, formatFullDate, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
import dayjs, { Dayjs } from 'dayjs';
import { format } from "url";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useUser } from "@clerk/clerk-react";
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const ENDPOINT = process.env.REACT_APP_ENDPOINT;

export default function TeamSettingsModal({ open, isMobile, handleClose, type, name, teamData, selectedAssets, selectedGroups, setSelectedGroups, softRefresh}) {
  
    const [teamName, setTeamName] = useState(teamData ? teamData.name : "");
    const [groupName, setGroupName] = useState(teamData && teamData.groups && teamData.groups.length > 0 ? teamData.groups[0].name : "");
    const [groupType, setGroupType] = useState(teamData && teamData.groups ? teamData.groups.length > 0 ? teamData.groups[0].type : "campaign" : "campaign");
    const [links, setLinks] = useState([]);
    const [invitees, setInvitees] = useState([]);
    const [separator, setSeparator] = useState("\n");
    const [linksValid, setLinksValid] = useState(true);
    const [newGroupName, setNewGroupName] = useState("");
    const [teammembers, setTeammembers] = useState([]);
    const [usageData, setUsageData] = useState({
      totalComments: 0,
      plan: {
        type: "",
        comments: 0,
        postsUploaded: 0,
        postsCompleted: 0,
      }
    });
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()));
    const [endDate, setEndDate] = useState(new Date());
    const { user } = useUser();
    const [verifyingRemoval, setVerifyingRemoval] = useState(null);

    const navigate = useNavigate();

    const handleOpenStripePortal = () => {
      //open in new tab
      window.open(`https://billing.stripe.com/p/login/6oE16iauBg0jfwQ144?teamIds=${teamData.id}`, '_blank');
    };
  

    React.useEffect(() => {
      async function fetchData() {
        let data = await Functions.getTeammateData(teamData.id);
        setTeammembers(data);

        let planStart = teamData.plan && teamData.plan.startDate ? new Date(teamData.plan.startDate) :  new Date(new Date().getFullYear(), new Date().getMonth(), 1);

        //let start be the date of this month that the plan started
        let start = new Date(new Date().getFullYear(), 
        
        planStart.getDate() > new Date().getDate() ? new Date().getMonth() - 1 : new Date().getMonth(), 
        
        planStart.getDate()).toISOString();
        
        //let end be today
        let end = new Date( new Date().getFullYear(),  planStart.getDate() > new Date().getDate() ? new Date().getMonth() : new Date().getMonth() + 1 , planStart.getDate() - 1).toISOString();

        setStartDate(start);
        setEndDate(end);

      }
      if(open){
      fetchData();
      }
    }, [open]);

    React.useEffect(() => {
      async function fetchData() {
        let data = await Functions.getTeamUsageData(teamData.id, startDate, endDate);
        setUsageData(data);
      }
      fetchData();
    },
    [startDate, endDate]);

    const goToPreviousPeriod = () => {
      const start = new Date(startDate);
      setEndDate(new Date(start.getFullYear(), start.getMonth(), start.getDate() - 1));
      setStartDate(new Date(start.getFullYear(), start.getMonth() - 1, start.getDate()));
    }

    const goToNextPeriod = () => {
      const end = new Date(endDate);
      setStartDate(new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1));
      setEndDate(new Date(end.getFullYear(), end.getMonth() + 1, end.getDate()));
    }
  
  
    const updateInvitees = (value) => {
      let list = value.split(",");
      setInvitees(list);
    }
  
    const updateLinks = (value) => {
      //find separator
      let newSeparator = value.includes("\n") ? "\n" : ",";
      setSeparator(newSeparator);
  
      let list = value.split(newSeparator);
      //remove url params for link in list
    if (list.length > 0){
            list = list.map((link, index) => {
                //check if link is a valid url
            let isUrl = isValidSocialMediaLink(link.trim());
            console.log("isUrl", isUrl, link);
            let url = isUrl ? new URL(link) : "";
            console.log("url", url);
            if (isUrl && link.includes("watch?v=")) {
                const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
                const match = link.trim().match(regExp);
                if (match && match[2].length === 11) {
                return `https://www.youtube.com/watch?v=${match[2]}`;
                }
              }
            else{
            return isUrl ? url.origin + url.pathname : link.trim();
            }
            });
        }

      setLinks(list);
  
      let valid = list.every((link) => isValidSocialMediaLink(link));
      setLinksValid(valid);
    }
    
    const isValidEmail = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const [addEmail, setAddEmail] = useState("");
  
    const handleRemoveTeammate = async (memberId, memberEmail) => {
      if (memberEmail === user.primaryEmailAddress.emailAddress) {
        // Don't allow removing your own email
        return;
      }
      await Functions.removeMemberFromTeam(teamData.id, memberId);
      setVerifyingRemoval(null);
      let data = await Functions.getTeammateData(teamData.id);
      setTeammembers(data);
    };
  
  
    const handleAddTeammate = async () => {
      if (addEmail && teammembers.length < teamData.plan.teamMembers) {
        // Implement the logic to invite a new teammate
        // This is a placeholder function - you'll need to implement the actual API call
        await Functions.addMemberToTeam(teamData.id, addEmail);
        setAddEmail("");
        let data = await Functions.getTeammateData(teamData.id);
        setTeammembers(data);
      }
    };
  
    const modalStyle = {
      width: "100%",
      borderRadius: 0, // Adjust the border radius as needed
      backgroundColor: 'white',
      // padding: '20px',
      outline: 'none', // Remove the default outline
      position:"fixed",
      left: "50%",
      bottom:0,
      maxHeight: '80%',
      overflowY: 'auto',
      overflowX: 'hidden',
      transform: "translate(-50%, 0)",
    };
  
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            // gap: ".5rem",
            display:"flex",
            // flexDirection:"column",
            // justifyContent:"center",          
          }}
        >
  
          <Box sx={{width:"100%", maxWidth:1200, margin:"auto",
            
            display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:2, p:isMobile ? 1 : 2}}>
          <Accordion elevation={0} defaultExpanded={type == "team"}>
          <AccordionSummary expandIcon={<ArrowDropDownOutlined />}>

               <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-stretch",
                  flexGrow: 1,
                  pr: 2,
                  gap:2,
                }}
              >
  
              <GroupsOutlined sx={{height:20, width:20, fill:"#999"}}/>
  
  
  

                <Typography variant="h6" fontWeight={500}>
                    {teamData.name}
                </Typography>

          </Box>
        
            </AccordionSummary>
            <AccordionDetails>
  

  
  
            </AccordionDetails>
          </Accordion>
  
          <Accordion elevation={0} defaultExpanded={type == "group"}>
          <AccordionSummary expandIcon={<ArrowDropDownOutlined />}>
          <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-stretch",
                  flexGrow: 1,
                  pr: 2,
                  pt:2,
                  gap: 2
                }}
              > 
  
                <Group sx={{height:20, width:20, fill:"#999"}}/>
                <Typography variant="body1" fontWeight={500}>
                    Teammates ({teammembers.length})
                </Typography>
                {/* <Select
                  
                  value={groupType}
                  disabled={type == "post"}
                  size="small"
                  onChange={handleGroupTypeChange}
                  variant="outlined"
                >
                  <MenuItem value={"campaign"}>Campaign</MenuItem>
                  <MenuItem value={"case"}>Case Study</MenuItem>
                  <MenuItem value={"research"}>Research Project</MenuItem>
                </Select> */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 2 }}>
              {teammembers.map((member, index) => (
                <Box key={index} sx={{
                  display: "flex", 
                  flexDirection: isMobile ? "column" : "row", 
                  gap: isMobile ? 1 : 2, 
                  alignItems: isMobile ? "flex-start" : "center", 
                  justifyContent: "space-between", 
                  mt: 2
                }}>
                  <Box sx={{display:"flex", flexDirection:"column"}}>
                    <Typography variant="body1" fontWeight={600} fontSize={18}>
                      {member.name}
                    </Typography>
                    <Typography variant="body1" fontWeight={600} fontSize={14}>
                      {member.email}
                    </Typography>
                  </Box>
                  {member.email !== user.primaryEmailAddress.emailAddress && (
          <>
          <Box sx={{flexGrow:1}}/>
            {verifyingRemoval === member.id ? (
              <>
                <Button 
                  variant="contained" 
                  color="error" 
                  size="small"
                  onClick={() => handleRemoveTeammate(member.id, member.email)}
                >
                  Confirm Remove
                </Button>
                <Button 
                  variant="outlined" 
                  size="small"
                  onClick={() => setVerifyingRemoval(null)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button 
                variant="outlined" 
                color="error" 
                size="small"
                onClick={() => setVerifyingRemoval(member.id)}
              >
                Remove
              </Button>
            )}
          </>
        )}
                </Box>
              ))}

{teamData.plan && teamData.plan.teamMembers && (<>
<Typography variant="body2" color="textSecondary" mt={2}>
                {teamData.plan.teamMembers - teammembers.length} spot{teamData.plan.teamMembers - teammembers.length !== 1 ? 's' : ''} remaining
              </Typography>
              
              {teammembers.length < teamData.plan.teamMembers && (
         <Box
         sx={{
           display: "flex", 
           flexDirection: isMobile ? "column" : "row", 
           gap: 2, 
           alignItems: isMobile ? "stretch" : "center", 
           justifyContent: "flex-end", 
           mt: 2,
         }}
       >
            <form onSubmit={(e) => { e.preventDefault(); handleAddTeammate(); }} style={{ width: '100%' }}>
              <TextField
                label="Add email"
                value={addEmail}
                variant="standard"
                onChange={(e) => setAddEmail(e.target.value)}
                fullWidth
                InputProps={{
                  style: { fontSize: 16 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ mb: 2 }}
                        type="submit" // Change to submit type
                        disabled={
                          !isValidEmail(addEmail) ||
                          teammembers.some((member) => member.email === addEmail) ||
                          !addEmail || teammembers.length >= teamData.plan.teamMembers}
                      >
                        Add
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            </form>
                  {/* <Button 
                    variant="contained" 
                    color="primary" 
                    size="small"
                    onClick={handleAddTeammate}
                    disabled={!addEmail || teammembers.length >= teamData.plan.teamMembers}
                    fullWidth={isMobile}
                  >
                    Add Member
                  </Button> */}
                </Box>
              )}


</>)}
              
              
     
            </Box>
          </AccordionDetails>
          </Accordion>
  
      
          
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ArrowDropDownOutlined />}>
            <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-stretch",
              flexGrow: 1,
            }}
          >  
          <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-stretch",
                  flexGrow: 1,
                  gap:2,
                  
                }}
              >
                    <TableView sx={{height:20, width:20, fill:"#999"}}/>
  
                    <Typography variant="body1" fontWeight={500}>
                    Plan
                </Typography>
  
        </Box>
        <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center"}}>
                 <Box sx={{width:20}}/> 

            <Typography variant="body2" fontWeight={600}>
              {teamData.plan && teamData.plan.term ? teamData.plan.term.toUpperCase() + " - " : ""
              } {teamData.plan && teamData.plan.name ? teamData.plan.name : "Trial"}
            </Typography>
            </Box>
            </Box>
  
            {/* {teamData.plan && !teamData.plan.name.toLowerCase().includes("demo") && !teamData.plan.name.toLowerCase().includes("trial") &&
            (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenStripePortal}
              >
                      Manage
              </Button>
            </Box>
            )} */}

            </AccordionSummary>
            <AccordionDetails>

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={dayjs(startDate)}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                    <DatePicker
                    label="End Date"
                    value={dayjs(endDate)}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
              </LocalizationProvider> */}

{teamData.plan ? 
<Box sx={{
  display: "flex", 
  flexDirection: isMobile ? "column" : "row",
  justifyContent: "space-between",
  alignItems: isMobile ? "flex-start" : "center",
  gap: 2, 
  p: 2
}}>
  {teamData.plan && teamData.plan.startDate ? 
    <Typography variant="body2" fontWeight={600}>
      Plan Start: {formatFullDate(teamData.plan.startDate)}
    </Typography> 
  : null}

  <Box sx={{ 
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center", 
    justifyContent: "flex-end",
    gap: 1
  }}>
    <Tooltip title="Go to last period">
      <ControlledIconButton action={goToPreviousPeriod} icon={<ArrowLeftOutlined/>} />
    </Tooltip>
    <Typography variant="body2" fontWeight={600}>
      {`${formatFullDate(startDate)} - ${formatFullDate(endDate)}`}
    </Typography>
    <Tooltip title="Go to next period">
      <ControlledIconButton action={goToNextPeriod} icon={<ArrowRightOutlined/>} />
    </Tooltip>
  </Box>
</Box>
: null}

              {teamData.plan ? 

              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap:2, p:2}}>
              <Typography variant="body1" fontWeight={600} ml={2} mr={2}>
                Comments
              </Typography>

 <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <CloseOutlined
                         sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Used
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                          color={((usageData.totalComments) < (teamData.plan.credits * .5)) ? "green" : ((usageData.totalComments) < (teamData.plan.credits * .75)) ? "#fec76f" : "error"}

                        >
                          {formatNumber(usageData.totalComments)}
                          </Typography>
                        <Typography fontSize={20}>
                          /
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                         {formatNumber(teamData.plan.credits)}
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                          comments
                        </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <CheckBoxOutlined
                           sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Remaining
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                          color={((teamData.plan.credits - usageData.totalComments) > (teamData.plan.credits * .5)) ? "green" : ((teamData.plan.credits - usageData.totalComments) > (teamData.plan.credits * .75)) ? "#fec76f" : "error"}
                        >
                         {formatNumber(teamData.plan.credits - usageData.totalComments)}
                        </Typography>
                        <Typography fontSize={20}>
                          /
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                         {formatNumber(teamData.plan.credits)}
                        </Typography>
                      
                      </Box>
                      <Typography variant="caption">
                          comments
                        </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <SpeedOutlined sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Overage
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                          +{usageData.totalComments > teamData.plan.credits ? formatNumber(usageData.totalComments - teamData.plan.credits) : 0}
                     
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                          comments
                        </Typography>
                    </Box>
                  </Grid>
                </Grid>

              </Box>
               : null}

{teamData.plan && usageData ? 

<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap:2, p:2}}>
<Typography variant="body1" fontWeight={600} ml={2} mr={2}>
  Posts
</Typography>

<Grid container spacing={2}>
    <Grid item xs={6} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          <UploadFileOutlined
           sx={{ height: 16, width:16, color: "#333" }} />

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            Uploaded
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
            {formatNumber(usageData.postsCompleted)} {teamData.plan && teamData.plan.postCredits ? `/ ${teamData.plan.postCredits}` : ""}
          </Typography>
    
        </Box>
        <Typography variant="caption">
            Posts
          </Typography>
      </Box>
    </Grid>
    <Grid item xs={6} sm={4}>
      {teamData.plan && teamData.plan.postCredits && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <CheckBoxOutlined
                           sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Remaining
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                          color={((teamData.plan.postCredits - usageData.postsCompleted) > (teamData.plan.postCredits * .5)) ? "green" : ((teamData.plan.postCredits - usageData.postsCompleted) > (teamData.plan.postCredits * .75)) ? "#fec76f" : "error"}
                        >
                         {formatNumber(teamData.plan.postCredits - usageData.postsCompleted)}
                        </Typography>
                        <Typography fontSize={20}>
                          /
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                         {formatNumber(teamData.plan.postCredits)}
                        </Typography>
                      
                      </Box>
                      <Typography variant="caption">
                          posts
                        </Typography>
                    </Box>
                  )}
                  </Grid>
    <Grid item xs={12} sm={4}>
    {teamData.plan && teamData.plan.postCredits && (

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <SpeedOutlined sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Overage
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                          +{usageData.postsCompleted > teamData.plan.postCredits ? formatNumber(usageData.postsCompleted - teamData.plan.postCredits) : 0}
                     
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                          posts
                        </Typography>
                    </Box>
                  )}
                  </Grid>
    {/* <Grid item xs={6} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          <CheckBoxOutlined
             sx={{ height: 16, width:16, color: "#333" }} />

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            Remaining
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
            color="green"
          >
           {formatNumber(teamData.plan.credits - usageData.totalComments)}
          </Typography>
          <Typography fontSize={20}>
            /
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
           {formatNumber(teamData.plan.credits)}
          </Typography>
        
        </Box>
        <Typography variant="caption">
            comments
          </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          <SpeedOutlined sx={{ height: 16, width:16, color: "#333" }} />

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            Overage
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
            +{usageData.totalComments > teamData.plan.credits ? formatNumber(usageData.totalComments - teamData.plan.credits) : 0}
       
          </Typography>
        </Box>
        <Typography variant="caption">
            comments
          </Typography>
      </Box>
    </Grid> */}
  </Grid>

</Box>
 : null}
          
            </AccordionDetails>
        </Accordion>
   


    {/* {(!teamData.plan || teamData.plan.name === "Demo" || teamData.plan.name === "Trial") ? (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, p: 2 }}>
        <Typography variant="body1" align="center">
          You are currently on a {teamData.plan ? teamData.plan.name : "free"} plan. 
          Upgrade to access more features and increase your usage limits.
        </Typography>
      <Box sx={{ width: "100%", borderRadius: 4, overflow: "hidden" }}>
        <StripePricingTable 
          pricingTableId={STRIPE_PRICING_TABLE_ID}
          publishableKey={STRIPE_KEY}
          clientReferenceId={user.id}
          customerEmail={user.primaryEmailAddress.emailAddress}
          customerMetadata={JSON.stringify({
            teamId: teamData.id,
            teamName: teamData.name,
          })}
        />
      </Box>
      </Box>
    ) : (
      null
    )} */}


         
          
        </Box>
        
        </Box>
    
      </Modal>
    );
  }


  function isValidSocialMediaLink(link) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]{11}/;
    const youtubeShortRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/shorts\/|youtu\.be\/)[\w-]{11}/;
    const instagramPostRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/p\/[\w-]+/;
    const instagramReelRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/reel\/[\w-]+/;
    const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w.-]+\/video\/[\w-]+/;
    // Handle short tiktok urls like so: https://www.tiktok.com/t/ZT8h3Nsh9/
    const tiktokShortRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/t\/[\w.-]+/;
  
    return (
      youtubeRegex.test(link) ||
      youtubeShortRegex.test(link) ||
      instagramPostRegex.test(link) ||
      instagramReelRegex.test(link) ||
      tiktokRegex.test(link) ||
      tiktokShortRegex.test(link)
    );
  }




const ControlledIconButton = ({ action, icon }) => {

  const [clicked, setClicked] = useState(false);


  const handleClick = () => {
    setClicked(true);
    action();
    setTimeout(()=>{
      setClicked(false);
    }, 1000)
  };

  return (
<IconButton size="small" disabled={clicked} variant="outlined" color="primary" onClick={handleClick}>
              {icon}
              </IconButton>
  );
}


const StripePricingTable = ({ pricingTableId, publishableKey, clientReferenceId, customerEmail, customerMetadata }) => {
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);

  React.useEffect(() => {
    // Load the Stripe.js script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Create Checkout Session
    const createCheckoutSession = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clientReferenceId,
            customerEmail,
            customerMetadata
          }),
        });
        const data = await response.json();
        setCheckoutSessionId(data.sessionId);
      } catch (error) {
        console.error('Error creating Checkout Session:', error);
      }
    };

    createCheckoutSession();

    return () => {
      document.body.removeChild(script);
    };
  }, [clientReferenceId, customerEmail, customerMetadata]);

  if (!checkoutSessionId) {
    return <div>Loading pricing table...</div>;
  }

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      client-reference-id={clientReferenceId}
      customer-email={customerEmail}
      checkout-session-id={checkoutSessionId}
    />
  );
};
